import React, { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { useOutletContext } from 'react-router-dom';
import { SimCardDownloadOutlined } from '@mui/icons-material';
import HighlightOffIcon from '@mui/icons-material/HighlightOff';
import {
    DialogContentText,
    FormControl,
    IconButton,
    MenuItem,
    Select,
    SelectChangeEvent,
    TextField,
} from '@mui/material';
import { RestrictAccess, useUserContext } from 'components';
import AccordionGroup from 'components/AccordionGroup/AccordionGroup';
import HDPButton from 'components/Button/HDPButton';
import ContinueBackUploadNew from 'components/ContinueBackUploadNew/ContinueBackUploadNew';
import { ifStatusIdIsUpToQualityProcessingComplete } from 'constants/FileStatus';
import { SubmissionOutletContextValue } from 'pages/Collections/Submission/types';
import useAllQualityResultsFileExists from 'queries/files/useAllQualityResultsFileExists';
import { getValidations, getValidationsCsv } from 'services/api/submissions';
import Accordion from 'src/components/Accordion/Accordion';
import ConfirmationPopup from 'src/components/ConfirmationPopup/ConfirmationPopup';
import { ACCORDION_IDS } from 'src/constants/constants';
import SearchButton from 'src/patterns/SearchButton/SearchButton';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';
import { PagingMetadata } from 'types/api';
import { Collection } from 'types/collection';
import { Submission, SubmissionValidation } from 'types/submission';

import {
    requestAllQualityRuleFailuresDownload,
    requestBlobDownload,
} from '../../utils';

import QualityRules from './QualityRules';

import styles from '../../../Submission/Steps/Reports/results.module.scss';

interface ResultsProps {
    data: Submission;
    collection?: Collection;
    submissionId: string;
}

const Results = ({ data, collection, submissionId }: ResultsProps) => {
    const { instId, collectionId } =
        useOutletContext<SubmissionOutletContextValue>();

    const { reference = '' } = useParams();
    const [pagingData, setPagingData] = useState<PagingMetadata>({
        totalResultsCount: 0,
        limit: 10,
        offset: 0,
    });

    const { activeOrganisation } = useUserContext() || {};
    const [validationRows, setValidationRows] = useState<
        (SubmissionValidation & {
            specialistRegulators?: SubmissionValidation[];
        })[]
    >([]);

    const [selectedStatus, setSelectedStatus] = useState<0 | 1>(1);
    const [validationSortBy, setValidationSortBy] = useState<
        'FAILURE' | 'POPULATION' | 'TOLERANCE' | 'STATUS' | 'ID'
    >('FAILURE');
    const [validationSortOrder, setValidationSortOrder] = useState<
        'desc' | 'asc'
    >('desc');
    const [
        showAllQualityRuleFailuresDownloadDialog,
        setShowAllQualityRuleFailuresDownloadDialog,
    ] = useState(false);
    const [offset, setOffset] = useState(0);
    const [limit, setLimit] = useState(10);

    const [searchTerm, setSearchTerm] = useState('');
    const [hasErroredFetching, setHasErroredFetching] = useState(false);

    // This state controls whether we poll for the download status of the quality rule failures file. When this is true, we stop polling.
    const [
        isQualityRuleFailuresFileDownloadStatusReady,
        setIsQualityRuleFailuresFileDownloadStatusReady,
    ] = useState(false);

    const [compare] = useState(false);

    const [noResultsFound, setNoResultsFound] = useState(false);

    const collectionReference = collection?.reference || reference;

    useEffect(() => {
        if (hasErroredFetching) {
            throw new Error('Could not fetch submission validations');
        }
    }, [hasErroredFetching]);

    const { data: qualityResultsFileExists } = useAllQualityResultsFileExists({
        submissionUuid: data.uuid,
        isOvt: true,
        enabled: !isQualityRuleFailuresFileDownloadStatusReady,
    });

    useEffect(() => {
        if (qualityResultsFileExists) {
            // Prevent any further refetching to check for the file existing
            setIsQualityRuleFailuresFileDownloadStatusReady(true);
        }
    }, [qualityResultsFileExists]);

    const handleChangeStatus = (event: SelectChangeEvent<string>) => {
        const newStatus = Number(event.target.value) as 0 | 1;
        setOffset(0);
        setSelectedStatus(newStatus);

        setValidationRows([]);
        loadValidationData(
            data.uuid,
            newStatus,
            validationSortBy,
            validationSortOrder,
            0,
            limit,
            searchTerm,
        );
    };

    const handleSearchSubmit = (updatedSearchTerm: string | null = null) => {
        setOffset(0);

        setValidationRows([]);
        loadValidationData(
            data.uuid,
            selectedStatus,
            validationSortBy,
            validationSortOrder,
            0,
            limit,
            updatedSearchTerm ?? searchTerm,
        );
    };

    const handleClearSearch = () => {
        setSearchTerm('');
        handleSearchSubmit('');
    };

    const handleUpdateSearchTerm = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => setSearchTerm(event.target.value);

    const handleSortByClicked = (
        sortByColumn: 'FAILURE' | 'POPULATION' | 'TOLERANCE' | 'STATUS' | 'ID',
        sortDirection: 'desc' | 'asc',
        page: number,
    ): void => {
        setValidationSortBy(sortByColumn);
        setValidationSortOrder(sortDirection);
        setOffset(page);
        setValidationRows([]);
        loadValidationData(
            data.uuid,
            selectedStatus,
            sortByColumn,
            sortDirection,
            page,
            limit,
            searchTerm,
        );
    };

    const handlePageClicked = (page: number, rowsPage: number) => {
        setOffset(page);
        setLimit(rowsPage);
        setValidationRows([]);
        loadValidationData(
            data.uuid,
            selectedStatus,
            validationSortBy,
            validationSortOrder,
            page,
            rowsPage,
            searchTerm,
        );
    };

    const loadValidationData = async (
        _uuid: string,
        _selectedStatus: 0 | 1,
        _validationSortBy:
            | 'FAILURE'
            | 'POPULATION'
            | 'TOLERANCE'
            | 'STATUS'
            | 'ID',
        _validationSortOrder: 'desc' | 'asc',
        _offset: number,
        _limit: number,
        _searchTerm = '',
    ) => {
        try {
            setNoResultsFound(false);
            const result = await getValidations({
                submissionId: _uuid,
                instId: data.provider?.instId || activeOrganisation.id,
                status: _selectedStatus,
                searchTerm: _searchTerm,
                sortBy: _validationSortBy,
                sortOrder: _validationSortOrder,
                offset: _offset,
                limit: _limit,
                ovt: true,
            });

            if (result) {
                const primaryResults = result.records?.filter(
                    x => x.IsPrimary === true,
                );
                let specialistResults = result.records
                    ?.filter(x => x.IsPrimary === false)
                    .sort((a, b) =>
                        a.RegulatorCode > b.RegulatorCode ? 1 : -1,
                    );

                const resultsWithSpecialistRegulators = primaryResults?.map(
                    result => {
                        const primarySpecialistResult =
                            specialistResults?.filter(
                                special => special.Code === result.Code,
                            ) || [];
                        specialistResults = specialistResults?.filter(
                            special => special.Code !== result.Code,
                        );

                        return {
                            ...result,
                            specialistRegulators: primarySpecialistResult,
                        };
                    },
                );

                setValidationRows(resultsWithSpecialistRegulators || []);
                setPagingData(
                    result.pagingMetadata || {
                        totalResultsCount: 0,
                        limit: 10,
                        offset: 0,
                    },
                );
                if (!result.records?.length) {
                    setNoResultsFound(true);
                }
            }
        } catch {
            setHasErroredFetching(true);
        }
    };

    const handleClickDownloadCsv = async () => {
        if (data.uuid) {
            const csv = await getValidationsCsv({
                submissionId: data.uuid,
                instId: data.provider?.instId || activeOrganisation.id,
                status: selectedStatus,
                sortBy: validationSortBy,
                sortOrder: validationSortOrder,
                ovt: true,
            });

            await requestBlobDownload(
                csv,
                `QualityRuleReport.${data.uuid.toLowerCase()}.${
                    selectedStatus === 0 ? 'inside' : 'outside'
                }.${new Date().toISOString()}.csv`,
            );
        }
    };

    const handleClickAllQualityRuleFailuresDownloadDialogOpen = () => {
        setShowAllQualityRuleFailuresDownloadDialog(true);
    };

    const handleClickAllQualityRuleFailuresDownloadDialogClose = () => {
        setShowAllQualityRuleFailuresDownloadDialog(false);
    };

    const handleClickAllQualityRuleFailuresDownloadDialogContinue =
        async () => {
            setShowAllQualityRuleFailuresDownloadDialog(false);
            if (submissionId) {
                await requestAllQualityRuleFailuresDownload(submissionId);
            }
        };

    const loadQualityRules = () => {
        loadValidationData(
            data.uuid,
            selectedStatus,
            validationSortBy,
            validationSortOrder,
            offset,
            limit,
        );
    };

    useEffect(() => {
        loadQualityRules();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [
        data.uuid,
        selectedStatus,
        validationSortBy,
        validationSortOrder,
        offset,
        limit,
    ]);

    const checkIfResubmissionAllowed = () =>
        data?.status.code === 'RESUBMISSION_REQUIRED' ||
        ifStatusIdIsUpToQualityProcessingComplete(data?.status.id);

    if (!data || !instId || !submissionId || !collectionId) {
        return null;
    }

    return (
        <React.Fragment>
            <ConfirmationPopup
                isOpen={showAllQualityRuleFailuresDownloadDialog}
                onCancel={handleClickAllQualityRuleFailuresDownloadDialogClose}
                onConfirm={
                    handleClickAllQualityRuleFailuresDownloadDialogContinue
                }
                title="Confirmation required!"
                customConfirmButtonText="Download"
            >
                <DialogContentText>
                    <p>
                        Please note that by using the ‘download all’ function,
                        you will extract a data file including any personal data
                        contained within your provider’s latest reports.
                    </p>
                    <p>
                        It is important that when downloading this data, your
                        provider must have in place appropriate processes to
                        ensure that personal data is processed securely, in
                        accordance with Data Protection Legislation (including
                        the provision of the Student Collection Notice) and your
                        agreement(s) with HESA.
                    </p>
                </DialogContentText>
            </ConfirmationPopup>
            <AccordionGroup>
                <Accordion
                    id={ACCORDION_IDS.SUBMISSION.QUALITY_REPORT}
                    title="Quality report"
                    description="Quality report failures in data submission"
                    dataTestId={`open accordion ${ACCORDION_IDS.SUBMISSION.QUALITY_REPORT}`}
                >
                    <div className={styles.qualityReportButtonWrapper}>
                        <HDPButton
                            isDisabled={compare}
                            variant="link"
                            onClick={handleClickDownloadCsv}
                            data-test-id={formatTestId(
                                'download',
                                'quality report results',
                            )}
                            icon={<SimCardDownloadOutlined />}
                        >
                            Download results
                        </HDPButton>
                        <RestrictAccess
                            allowPermissions={[
                                'collections.reports-validation-details-ovt',
                            ]}
                        >
                            <HDPButton
                                isDisabled={!qualityResultsFileExists}
                                onClick={
                                    handleClickAllQualityRuleFailuresDownloadDialogOpen
                                }
                                data-test-id={formatTestId(
                                    'downloadDetailedResults',
                                )}
                                icon={<SimCardDownloadOutlined />}
                                variant="link"
                            >
                                Download detailed results
                            </HDPButton>
                        </RestrictAccess>
                    </div>

                    <div className={styles.actionsBarWrapper}>
                        <div className={styles.actionsBar}>
                            <form
                                className={styles.searchInputWrapper}
                                onSubmit={event => {
                                    event.preventDefault();
                                    handleSearchSubmit();
                                }}
                            >
                                <TextField
                                    className={styles.searchInput}
                                    id="search-term"
                                    size="small"
                                    value={searchTerm}
                                    variant="outlined"
                                    onChange={handleUpdateSearchTerm}
                                    placeholder="Search"
                                    inputProps={{
                                        'aria-label': 'Search bar',
                                        'data-test-id': formatTestId(
                                            'search input',
                                            'quality rules',
                                        ),
                                    }}
                                    InputProps={{
                                        endAdornment: (
                                            <IconButton
                                                onClick={handleClearSearch}
                                                aria-label="Clear search"
                                                data-test-id={formatTestId(
                                                    'clear search',
                                                    'quality rules',
                                                )}
                                            >
                                                <HighlightOffIcon
                                                    className={
                                                        styles.clearSearchIcon
                                                    }
                                                />
                                            </IconButton>
                                        ),
                                    }}
                                />
                                <SearchButton
                                    aria-label="search quality rules"
                                    type="submit"
                                    className={styles.searchButton}
                                    data-test-id={formatTestId(
                                        'search button',
                                        'quality rules',
                                    )}
                                />
                            </form>
                            <FormControl
                                className={styles.columnSelectListWrapper}
                            >
                                <Select
                                    className={styles.columnSelectListInner}
                                    value={selectedStatus.toString()}
                                    onChange={handleChangeStatus}
                                    data-test-id={formatTestId(
                                        'open menu',
                                        'tolerance',
                                    )}
                                >
                                    <MenuItem
                                        value="1"
                                        data-test-id={formatTestId(
                                            'option',
                                            'outside tolerance',
                                        )}
                                    >
                                        Outside tolerance
                                    </MenuItem>
                                    <MenuItem
                                        value="0"
                                        data-test-id={formatTestId(
                                            'open',
                                            'inside tolerance',
                                        )}
                                    >
                                        Inside tolerance
                                    </MenuItem>
                                </Select>
                            </FormControl>
                        </div>
                    </div>

                    <div className={styles.qualityRulesContainer}>
                        <QualityRules
                            collection={collection}
                            collectionId={String(data.collection.id)}
                            instId={data?.provider?.instId || ''}
                            submissionId={data.uuid}
                            data={validationRows}
                            pagingData={pagingData}
                            sortHandler={handleSortByClicked}
                            pageHandler={handlePageClicked}
                            activeSort={validationSortBy}
                            noResultsFound={noResultsFound}
                            activeSortDirection={validationSortOrder}
                            reference={collection?.reference || reference}
                        />
                    </div>
                </Accordion>
            </AccordionGroup>
            <div className={styles.spacer} />
            <ContinueBackUploadNew
                isThereAnotherStep={false} // In OVT this is the last step
                isContinuePossible={false} // Continue button wont appear because isThereAnotherStep is false. But if it did appear, it would be disabled
                collectionId={collectionId}
                collectionReference={collectionReference}
                instId={instId}
                isOvt={true}
                nextStepName="Submit"
                previousStepName="Processing"
                uploadPermissionsList={['online-validation.online-validation']}
                isResubmissionAllowed={checkIfResubmissionAllowed()}
            />
        </React.Fragment>
    );
};

export default Results;
