import { Typography } from '@mui/material';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import AddConjunction from './AddConjunction';
import NumberInput from './NumberInput';

import styles from './editTolerancesField.module.scss';

const toleranceTypes = {
    COUNT: 1,
    PERCENTAGE: 2,
    RANGE: 3,
};

interface Tolerance {
    id: number;
    type: number;
    value: number;
    from: number;
    to: number | null;
}

interface EditToleranceFieldProps {
    name: string;
    conjunction: 'O' | 'A';
    tolerances: Tolerance[];
}

const EditTolerancesField = ({
    name,
    conjunction,
    tolerances,
}: EditToleranceFieldProps) => {
    const renderTolerance = (tolerance: Tolerance) => {
        switch (tolerance.type) {
            case toleranceTypes.COUNT:
                return (
                    <>
                        <label
                            htmlFor={tolerance.id.toString()}
                            className={styles.scrOnly}
                        >
                            Tolerance count
                        </label>
                        <NumberInput
                            id={`${tolerance.id}`}
                            helperText={'0 to 99999'}
                            testId={formatTestId('input', 'toleranceCount')}
                        />
                    </>
                );

            case toleranceTypes.PERCENTAGE:
                return (
                    <>
                        <label
                            className={styles.scrOnly}
                            htmlFor={tolerance.id.toString()}
                        >
                            Tolerance percentage
                        </label>
                        <NumberInput
                            id={`${tolerance.id}`}
                            suffix={'%'}
                            inputType={'text'}
                            helperText={'0 to 999.99'}
                            testId={formatTestId(
                                'input',
                                'tolerancePercentage',
                            )}
                        />
                    </>
                );

            case toleranceTypes.RANGE:
                return (
                    <>
                        <label
                            className={styles.scrOnly}
                            htmlFor={`F${tolerance.id}`}
                        >
                            Tolerance range start
                        </label>
                        <NumberInput
                            id={`F${tolerance.id}`}
                            key={'from'}
                            helperText={'0 to 99999'}
                            testId={formatTestId('input', 'toleranceRangeFrom')}
                        />
                        <span className={styles.separatorTo} key={'dash'}>
                            to
                        </span>
                        <label
                            className={styles.scrOnly}
                            htmlFor={`T${tolerance.id}`}
                        >
                            Tolerance range end
                        </label>
                        <NumberInput
                            id={`T${tolerance.id}`}
                            key={'to'}
                            helperText={'0 to 99999'}
                            testId={formatTestId('input', 'toleranceRangeTo')}
                        />
                    </>
                );

            default:
                return null;
        }
    };

    return (
        <div className={styles.field}>
            <div className={styles.name}>
                <Typography variant={'body1'}>{name}</Typography>
            </div>
            <div className={styles.tolerances}>
                {tolerances
                    .map(renderTolerance)
                    .reduce(AddConjunction(conjunction))}
            </div>
        </div>
    );
};

export default EditTolerancesField;
