import { Params, RouteObject } from 'react-router-dom';
import AuthRoute from 'components/AuthRoute/AuthRoute';
import Processing from 'pages/Collections/OnlineValidation/Steps/Processing/Processing';
import OvtQualityRule from 'pages/Collections/OnlineValidation/Steps/Reports/OvtQualityRule';
import Reports from 'pages/Collections/OnlineValidation/Steps/Reports/Reports';
import Upload from 'pages/Collections/Submission/Steps/Upload/Upload';
import Submission from 'pages/Collections/Submission/Submission';
import Submissions from 'pages/Collections/Submission/Submissions';

const ovtRoutes: RouteObject[] = [
    {
        element: (
            <AuthRoute
                allowProviders={[
                    'ovt-submitter',
                    'software-supplier-ovt-submitter',
                ]}
            />
        ),
        children: [
            {
                path: 'online-validation',
                handle: {
                    crumb: 'Online Validation',
                },
                children: [
                    {
                        index: true,
                        element: <Submissions isOvt />,
                    },
                    {
                        path: ':collectionId/:submissionId',
                        element: <Submission isOvt />,
                        handle: {
                            crumb: (params: Params<'submissionId'>) => {
                                if (params?.submissionId !== undefined) {
                                    return params.submissionId.slice(-8);
                                }
                                return 'Submission';
                            },
                            withoutLink: true,
                        },
                        children: [
                            {
                                path: 'upload',
                                handle: {
                                    crumb: 'Upload',
                                },
                                element: <Upload isOvt />,
                            },
                            {
                                path: 'processing',
                                handle: {
                                    crumb: 'Processing',
                                },
                                element: <Processing />,
                            },
                            {
                                path: 'reports',
                                handle: {
                                    crumb: 'Quality Assurance',
                                },
                                children: [
                                    {
                                        index: true,
                                        element: <Reports />,
                                    },
                                    {
                                        path: 'quality/:rule/:regulatorCode',
                                        handle: {
                                            crumb: 'Quality Rules',
                                            withoutLink: true,
                                        },
                                        children: [
                                            {
                                                index: true,
                                                element: <OvtQualityRule />,
                                                handle: {
                                                    crumb: 'Rule Failure',
                                                    withoutLink: true,
                                                },
                                            },
                                        ],
                                    },
                                ],
                            },
                        ],
                    },
                ],
            },
        ],
    },
];

export default ovtRoutes;
