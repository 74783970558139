import { Fragment } from 'react';
import TablePagination from '@mui/material/TablePagination';
import HDPTableHeader from 'components/HDPTableHeader/HDPTableHeader';
import { formatDateTime } from 'pages/Collections/Submission/utils';
import useRunDpsTask from 'queries/submissions/useRunDpsTask';
import {
    DpsSubmission,
    GetDpsSubmissionsResponse,
} from 'services/api/submissions/types';

import ProviderButton from './ProviderButton';
import SubmissionActionForm from './SubmissionActionForm';

import styles from './dpaTable.module.scss';

// Define table headers
const headers = [
    {
        label: 'Provider',
        id: 'provider',
        isSortable: false,
    },
    {
        label: 'Submission',
        id: 'submission-id',
        isSortable: false,
    },
    {
        label: 'Submission date',
        id: 'date',
        isSortable: false,
    },
    {
        label: 'Status',
        id: 'status',
        isSortable: false,
    },
    {
        label: 'Action',
        id: 'action',
        isSortable: false,
    },
];

interface DPATableProps {
    dataFromApi: GetDpsSubmissionsResponse;
    tableParams: {
        expandedInstIds: string[];
        offset: number;
        limit: number;
    };
    tableParamsMethods: {
        setExpandedInstIds: (newExpandedInstIds: string[]) => void;
        setOffset: (offset: number) => void;
        setLimit: (limit: number) => void;
    };
}

const toggleIsExpandedForProvider = (
    id: string,
    expandedInstIds: string[],
    setExpandedInstIds: (newExpandedInstIds: string[]) => void,
) => {
    const updatedExpandedInstIds = expandedInstIds.includes(id)
        ? expandedInstIds.filter(instId => instId !== id)
        : [...expandedInstIds, id];
    setExpandedInstIds(updatedExpandedInstIds);
};

const getStatusLabel = (status: string | null | undefined) => {
    switch (status) {
        case 'Success':
            return 'Success';
        case 'Pending':
            return (
                <span className={styles.anonymisingStatus}>Anonymising...</span>
            );
        case 'Error':
            return <span className={styles.failedStatus}>Failed</span>;
        case 'Failed':
            return <span className={styles.failedStatus}>Failed</span>;
        case null:
            return '';
        case undefined:
            return <span className={styles.failedStatus}>Failed</span>;
        default:
            return status;
    }
};

const DPATable = ({
    dataFromApi,
    tableParams,
    tableParamsMethods,
}: DPATableProps) => {
    const { runDpsTask } = useRunDpsTask();

    return (
        <div>
            <div className={styles.tableContainer}>
                <HDPTableHeader headers={headers} colour="light">
                    <tbody>
                        {dataFromApi.results.map(
                            (
                                {
                                    instId: providerId,
                                    provider: name,
                                    submissions,
                                },
                                dataIndex,
                            ) =>
                                tableParams.expandedInstIds.includes(
                                    providerId,
                                ) ? (
                                    <Fragment
                                        key={`provider-row-${providerId}`}
                                    >
                                        {submissions.map(
                                            (
                                                submission: DpsSubmission,
                                                index: number,
                                            ) => (
                                                <tr
                                                    key={`data-row-${submission.submissionId}`}
                                                    className={
                                                        styles[
                                                            `tableRow-${
                                                                dataIndex % 2
                                                            }`
                                                        ]
                                                    }
                                                >
                                                    {index === 0 && (
                                                        <td
                                                            className={
                                                                styles.cell
                                                            }
                                                            rowSpan={
                                                                submissions.length
                                                            }
                                                        >
                                                            <ProviderButton
                                                                name={name}
                                                                handleClick={() =>
                                                                    toggleIsExpandedForProvider(
                                                                        providerId,
                                                                        tableParams.expandedInstIds,
                                                                        tableParamsMethods.setExpandedInstIds,
                                                                    )
                                                                }
                                                                isExpandable={
                                                                    submissions.length >
                                                                    1
                                                                }
                                                                isExpanded={
                                                                    true
                                                                }
                                                            />
                                                        </td>
                                                    )}
                                                    <td className={styles.cell}>
                                                        {submission.uuid}
                                                    </td>
                                                    <td className={styles.cell}>
                                                        {formatDateTime(
                                                            submission.uploaded,
                                                        )}
                                                    </td>
                                                    <td className={styles.cell}>
                                                        {getStatusLabel(
                                                            submission.dpsFileStatus,
                                                        )}
                                                    </td>

                                                    <td className={styles.cell}>
                                                        <SubmissionActionForm
                                                            submission={
                                                                submission
                                                            }
                                                            runDpsTask={
                                                                runDpsTask
                                                            }
                                                        />
                                                    </td>
                                                </tr>
                                            ),
                                        )}
                                    </Fragment>
                                ) : (
                                    <tr
                                        key={`provider-row-${providerId}`}
                                        className={
                                            styles[`tableRow-${dataIndex % 2}`]
                                        }
                                    >
                                        <td className={styles.cell}>
                                            <ProviderButton
                                                name={name}
                                                handleClick={() =>
                                                    toggleIsExpandedForProvider(
                                                        providerId,
                                                        tableParams.expandedInstIds,
                                                        tableParamsMethods.setExpandedInstIds,
                                                    )
                                                }
                                                isExpandable={
                                                    submissions.length > 1
                                                }
                                                isExpanded={false}
                                            />
                                        </td>
                                        <td className={styles.cell}>
                                            {submissions[0].uuid}
                                        </td>
                                        <td className={styles.cell}>
                                            {formatDateTime(
                                                submissions[0].uploaded,
                                            )}
                                        </td>
                                        <td className={styles.cell}>
                                            {getStatusLabel(
                                                submissions[0].dpsFileStatus,
                                            )}
                                        </td>
                                        <td className={styles.cell}>
                                            <SubmissionActionForm
                                                submission={submissions[0]}
                                                runDpsTask={runDpsTask}
                                            />
                                        </td>
                                    </tr>
                                ),
                        )}
                    </tbody>
                </HDPTableHeader>
            </div>
            <div>
                <TablePagination
                    rowsPerPageOptions={[
                        { label: '10', value: 10 },
                        { label: '25', value: 25 },
                        { label: '50', value: 50 },
                        { label: '100', value: 100 },
                    ]}
                    component="div"
                    count={dataFromApi.metadata.resultsCount}
                    rowsPerPage={tableParams.limit}
                    page={tableParams.offset - 1}
                    onPageChange={(_, page) =>
                        tableParamsMethods.setOffset(page)
                    }
                    onRowsPerPageChange={event => {
                        tableParamsMethods.setLimit(
                            parseInt(event.target.value),
                        );
                    }}
                    SelectProps={{
                        inputProps: {
                            'aria-label': 'rows per page',
                            'aria-labelledby': 'rowsPage',
                        },
                        id: 'rowsPage',
                    }}
                />
            </div>
        </div>
    );
};
export default DPATable;
