import { HTMLAttributes, memo } from 'react';
import { useAutocomplete, UseAutocompleteRenderedOption } from '@mui/material';
import classNames from 'classnames';
import { FuzzyMatch } from 'hooks/useFuzzySearch/useFuzzySearch';
import isEqual from 'lodash/isEqual';

import { AutocompleteOption } from '../AutocompleteDropdown';
import AutocompleteDropdownItem from '../AutocompleteDropdownItem/AutocompleteDropdownItem';

import styles from './optionsList.module.scss';

interface OptionsListProps {
    options: FuzzyMatch<AutocompleteOption>[];
    id: string;
    getOptionProps: (
        renderedOption: UseAutocompleteRenderedOption<string>,
    ) => HTMLAttributes<HTMLLIElement> & {
        key: string | number;
    };
    selectedOptions: (string | number)[];
    getListboxProps: ReturnType<typeof useAutocomplete>['getListboxProps'];
    isEmptySearch: boolean;
}

const OptionsList = ({
    options,
    id,
    getOptionProps,
    selectedOptions,
    getListboxProps,
    isEmptySearch,
}: OptionsListProps) => {
    return options.length > 0 ? (
        <ul {...getListboxProps()} className={styles.listBox}>
            {options.map((option, index) => {
                return (
                    <AutocompleteDropdownItem
                        key={`${id}-option-${index}`}
                        option={option}
                        index={index}
                        getOptionProps={getOptionProps}
                        selectedOptions={selectedOptions}
                        isEmptySearch={isEmptySearch}
                    />
                );
            })}
        </ul>
    ) : (
        <p className={classNames(styles.listBox, styles.noResultsFound)}>
            No results found
        </p>
    );
};

const arePropsEqual = (
    prevProps: OptionsListProps,
    nextProps: OptionsListProps,
) => {
    return (
        isEqual(prevProps.options, nextProps.options) &&
        isEqual(prevProps.selectedOptions, nextProps.selectedOptions)
    );
};

export default memo(OptionsList, arePropsEqual);
