import React, { createRef, useState } from 'react';
import { TextField } from '@mui/material';
import {
    SpecificationInterface,
    ValidationInterface,
} from 'queries/specifications/types';
import useUpdateValidation from 'queries/specifications/useUpdateValidation';
import { getRuleMetadata } from 'queries/specifications/utils';

import EditForm from '../EditForm/EditForm';

const editStates = {
    DISPLAY: 1,
    SAVING: 2,
    ERROR: 3,
};

const useRefs = (refs: string[]) => {
    return refs.reduce((acc, ref) => {
        acc[ref] = createRef<HTMLInputElement>();
        return acc;
    }, {} as Record<string, React.RefObject<HTMLInputElement>>);
};

interface EditRuleMetadataProps {
    spec: SpecificationInterface;
    validation: ValidationInterface;
    id: number;
    onCancel: () => void;
    onSaved: ({
        specId,
        validation,
    }: {
        specId: number;
        validation: ValidationInterface;
    }) => void;
}

export const EditRuleMetadata = ({
    spec,
    validation,
    id,
    onCancel,
    onSaved,
}: EditRuleMetadataProps) => {
    const [state, setState] = useState(editStates.DISPLAY);

    const { description, guidanceUrl } = getRuleMetadata(validation);

    const fields = useRefs(['description', 'guidanceUrl']);

    const handleCancel = () => onCancel();

    const { mutate: updateValidation } = useUpdateValidation();

    const handleSave = async () => {
        setState(editStates.SAVING);
        if (!fields.description.current || !fields.guidanceUrl.current) {
            setState(editStates.ERROR);
            return;
        }

        updateValidation(
            {
                specId: spec.id,
                validationId: id,
                metadata: {
                    description: fields.description.current.value,
                    guidanceUrl: fields.guidanceUrl.current.value,
                },
            },
            {
                onSuccess: mutationResponse => {
                    setState(editStates.DISPLAY);
                    onSaved({
                        specId: spec.id,
                        validation: mutationResponse.validation,
                    });
                },
                onError: () => {
                    setState(editStates.ERROR);
                },
            },
        );
    };

    const isSaving = state === editStates.SAVING;
    const isError = state === editStates.ERROR;

    return (
        <EditForm
            title={'Edit rule metadata'}
            subtitle={validation.code}
            error={
                isError
                    ? 'There has been a problem saving your changes. Please try again.'
                    : undefined
            }
            onCancel={handleCancel}
            onSave={handleSave}
            isSaving={isSaving}
            buttons={{
                save: 'Save changes',
                saving: 'Saving changes...',
            }}
        >
            <TextField
                inputRef={fields.description}
                fullWidth
                variant={'outlined'}
                margin={'normal'}
                label={'Description'}
                defaultValue={description}
                multiline
                disabled={isSaving}
                data-test-id="inputDescription"
            />
            <TextField
                inputRef={fields.guidanceUrl}
                fullWidth
                variant={'outlined'}
                margin={'normal'}
                label={'Guidance URL'}
                defaultValue={guidanceUrl}
                disabled={isSaving}
                data-test-id="inputGuidanceUrl"
            />
        </EditForm>
    );
};
