import { useEffect, useState } from 'react';
import { useOutletContext } from 'react-router-dom';
import { ArrowForwardIos } from '@mui/icons-material';
import { Paper } from '@mui/material';
import { useUserContext } from 'components';
import AccessDenied from 'components/AccessDenied/AccessDenied';
import HDPButton from 'components/Button/HDPButton';
import { SubmissionStatuses } from 'services/api/submissions/types';
import { RestrictAccess } from 'src/components/index';
import { FILE_STATUS, fileStatusById } from 'src/constants/FileStatus';
import {
    collectionIsLocked,
    getApprovalStates,
} from 'src/pages/Collections/Submission/utils';
import BackButton from 'src/patterns/BackButton/BackButton';
import useUpdateSubmission from 'src/queries/submissions/useUpdateSubmission';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';
import { Collection } from 'types/collection';

import { SubmissionOutletContextValue } from '../../types';
import { useStepper } from '../Stepper/StepperContext';

import SubmitStates from './SubmitStates';

import styles from './submit.module.scss';

const isSubmitAllowed = (
    submissionStatuses: SubmissionStatuses | undefined,
    isLoading: boolean,
    isCommitAllowed: boolean,
    collection?: Collection,
) => {
    /**
     * Submit is allowed if:
     * - submission is the latest (not not latest)
     * - submission is not not collection end date. (Is before collection end date?)
     * - submission doesn't have operationalFailures
     * - submission DOESN'T have pgrTransferConsentGiven
     * - collection is NOT closed
     *
     * OR
     *
     * commitNotAllowedReasons is empty
     *
     */
    if (collection && collectionIsLocked(collection)) return false;

    if (isLoading) return false;

    if (!isCommitAllowed) return false;

    if (submissionStatuses === undefined) return false;

    return (
        !submissionStatuses.isNotLatest &&
        !submissionStatuses.notCollectionEndDate &&
        !submissionStatuses.operationalFailures &&
        !submissionStatuses.pgrTransferConsentGiven
    );
};

const hasOpenIssues = (
    submissionStatuses: SubmissionStatuses | undefined,
    isLoading: boolean,
) => {
    if (isLoading) return false;

    if (!submissionStatuses) return false;

    return submissionStatuses
        ? submissionStatuses.openIssues || submissionStatuses.ruleFailures
        : false;
};

const getStateString = (
    isSubmitting: boolean,
    hasAlreadySubmitted: boolean,
) => {
    if (isSubmitting) return 'Submitting';
    if (hasAlreadySubmitted) return 'Continue';
    return 'Submit for approval';
};

const Submit = () => {
    // Get submission from the page
    const {
        data: submission,
        setPageTitle,
        getSubmission,
        collection,
    } = useOutletContext<SubmissionOutletContextValue>();
    const { roles, isProvider } = useUserContext();
    const { commitStatusQuery } = useStepper();
    const isHesaAdmin = roles && roles.includes('admin');
    const isProviderSignOff = isProvider && roles && roles.includes('sign-off');
    const [isSubmitting, setIsSubmitting] = useState(false);
    const { updateState } = useUpdateSubmission();
    const { data: commitStatusData } = commitStatusQuery;
    const { submissionStatuses, commitNotAllowedReasons } =
        commitStatusData || {};

    const submissionApprovalStates = getApprovalStates(
        submission?.provider?.riskCode,
        submission?.provider?.countryCode,
    );

    const [termsChecked, setTermsChecked] = useState(false);
    const { navigateToStep } = useStepper();

    useEffect(() => {
        setPageTitle('Submit');
    }, [setPageTitle]);

    const hasAlreadySubmitted = submission?.status?.id
        ? fileStatusById(submission?.status?.id)?.order >
          FILE_STATUS.QUALITY_PROCESSING_COMPLETE.order
        : false;

    const isSubmissionProcessing = submission?.status?.id
        ? fileStatusById(submission?.status?.id)?.order <
          FILE_STATUS.QUALITY_PROCESSING_COMPLETE.order
        : false;

    const hasPermissionToSubmit =
        Boolean(isHesaAdmin) || Boolean(isProviderSignOff);

    const canSubmit =
        hasAlreadySubmitted ||
        (hasPermissionToSubmit &&
            isSubmitAllowed(
                submissionStatuses,
                commitStatusQuery.isLoading,
                !commitNotAllowedReasons?.length,
            ));

    const handleSubmit = () => {
        if (hasAlreadySubmitted) {
            return navigateToStep('Approval');
        }

        if (!submission) {
            // TODO this is just for type guarding for below, I don't think this will ever happen
            // Would be good to sort out the `submission` type to be non-undefined
            return;
        }

        if (submissionApprovalStates === undefined) {
            // TODO this is just for type guarding for below, I don't think this will ever happen
            // Would be good to sort out the `submission` type to be non-undefined
            return;
        }

        setIsSubmitting(true);

        updateState(
            {
                submissionId: submission.uuid,
                statusId: submissionApprovalStates[0].id,
                action: 'submit',
            },
            {
                onSuccess: () => {
                    setIsSubmitting(false);
                    navigateToStep('Approval');
                },
                onSettled: () => {
                    getSubmission();
                },
            },
        );
    };

    return (
        <RestrictAccess
            allowPermissions={['collections.approval-and-sign-off']}
            render={() => <AccessDenied mt={8} />}
        >
            <Paper className={styles.submit}>
                <h2 className={styles.heading}>Submit for approval</h2>
                {submission && (
                    <SubmitStates
                        isLoading={commitStatusQuery?.isLoading}
                        isSubmissionProcessing={isSubmissionProcessing}
                        isSubmitAllowed={isSubmitAllowed(
                            submissionStatuses,
                            commitStatusQuery.isLoading,
                            !commitNotAllowedReasons?.length,
                            collection,
                        )}
                        hasAlreadySubmitted={hasAlreadySubmitted}
                        submission={submission}
                        commitNotAllowedReasons={commitNotAllowedReasons}
                        canSubmit={canSubmit}
                        hasOpenIssues={hasOpenIssues(
                            submissionStatuses,
                            commitStatusQuery.isLoading,
                        )}
                        termsChecked={termsChecked}
                        setTermsChecked={setTermsChecked}
                        hasPermissionToSubmit={hasPermissionToSubmit}
                        approvalStates={submissionApprovalStates}
                        collection={collection}
                    />
                )}
            </Paper>
            <div className={styles.buttons}>
                <BackButton
                    onClick={() => navigateToStep('Quality Assurance')}
                />

                <HDPButton
                    isDisabled={
                        !hasAlreadySubmitted &&
                        (!canSubmit || !termsChecked || isSubmitting)
                    }
                    variant="primary"
                    onClick={() => handleSubmit()}
                    data-test-id={
                        hasAlreadySubmitted
                            ? formatTestId('continue')
                            : formatTestId('submit')
                    }
                    iconPlacement="right"
                    icon={<ArrowForwardIos />}
                >
                    {getStateString(isSubmitting, hasAlreadySubmitted)}
                </HDPButton>
            </div>
        </RestrictAccess>
    );
};

export default Submit;
