import { Remove } from '@mui/icons-material';
import { FILE_STATUS } from 'constants/FileStatus';
import { Collection } from 'src/types/collection';
import { RawSubmission } from 'types/submission';

import { collectionContainsNilReturnSubmission } from './utils';

export default function CollectionIcon({
    collection,
    latestSubmission,
}: {
    collection: Collection;
    latestSubmission?: RawSubmission;
}) {
    if (latestSubmission) {
        return FILE_STATUS[latestSubmission.Status].statusIcon;
    }
    if (FILE_STATUS[collection.collectionStatus]) {
        return FILE_STATUS[collection.collectionStatus].statusIcon;
    } else if (collectionContainsNilReturnSubmission(collection)) {
        return <span>Nil return</span>;
    } else {
        return <Remove />;
    }
}
