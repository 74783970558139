import React from 'react';
import TablePagination from '@mui/material/TablePagination/TablePagination';
import HDPTableHeader, {
    Header,
} from 'components/HDPTableHeader/HDPTableHeader';

import styles from './hdpTable.module.scss';

export interface HDPTableProps<T extends object> {
    headers: Header<T>[];
    data: T[];
    onClickSort: (id: keyof T) => void;
    pagination?: {
        currentPage: number;
        totalRows: number;
        setLimit: (limit: number) => void;
        setOffset: (offset: number) => void;
        offset: number;
        limit: number;
    };
    sorting: {
        key: keyof T;
        direction: 'asc' | 'desc';
    };
    testId?: string;
}
/*
 * Table component to be used in HDP.
 * This table component is purely for display purposes.
 *   - It does not handle any data fetching or manipulation.
 *   - It does not handle any state.
 *   - The table headers are passed in as an array of objects.
 *   - Data to display needs to be transformed to match the header keys before being passed in.
 */
function HDPTable<T extends object>({
    headers,
    data,
    onClickSort,
    sorting,
    pagination,
    testId,
}: HDPTableProps<T>) {
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => {
        if (pagination === undefined) return;
        const rows = +event.target.value;
        pagination.setLimit(rows);
        pagination.setOffset(0);
    };

    const handleChangePage = (
        _event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        if (pagination === undefined) return;
        pagination.setOffset(newPage);
    };

    return (
        <HDPTableHeader
            headers={headers}
            onClickSort={onClickSort}
            sorting={sorting}
            testId={testId}
        >
            <tbody>
                {data.map((row, index) => (
                    <tr key={index} className={styles.dataRow}>
                        {headers.map(header => (
                            <td
                                key={header.id as string}
                                className={`${styles[header?.alignment || '']}`}
                                data-test-id={`table-cell-${String(header.id)}`}
                            >
                                {row[header.id as keyof T] as React.ReactNode}
                            </td>
                        ))}
                    </tr>
                ))}
            </tbody>
            <tfoot>
                {pagination !== undefined && (
                    <tr>
                        <td colSpan={headers.length}>
                            {/* Replace this with our own dropdowns and buttons instead of the MUI component at some point?.*/}
                            <div>
                                <TablePagination
                                    rowsPerPageOptions={[
                                        { label: '10', value: 10 },
                                        { label: '25', value: 25 },
                                        { label: '50', value: 50 },
                                        { label: '100', value: 100 },
                                        { label: 'All', value: -1 },
                                    ]}
                                    component="div"
                                    count={pagination.totalRows}
                                    rowsPerPage={pagination.limit}
                                    page={pagination.offset}
                                    onPageChange={handleChangePage}
                                    onRowsPerPageChange={
                                        handleChangeRowsPerPage
                                    }
                                />
                            </div>
                        </td>
                    </tr>
                )}
            </tfoot>
        </HDPTableHeader>
    );
}

export default HDPTable;
