import { useQuery } from '@tanstack/react-query';
import { files as filesApi } from 'services/api';

interface UseAllQualityResultsFileExistsOptions {
    submissionUuid: string;
    isOvt?: boolean;
    enabled?: boolean;
}

export default function useAllQualityResultsFileExists({
    submissionUuid,
    isOvt = false,
    enabled = true,
}: UseAllQualityResultsFileExistsOptions) {
    const endpoint = isOvt
        ? filesApi.ovtGetAllQualityRuleFailuresExists
        : filesApi.getAllQualityRuleFailuresExists;

    return useQuery({
        queryKey: [
            'files',
            'all-quality-rule-failures-exists',
            { submissionUuid, isOvt },
        ],
        queryFn: () => endpoint(submissionUuid),
        select: data => data.result.fileExists,
        refetchInterval: 5000,
        refetchOnWindowFocus: true,
        enabled,
    });
}
