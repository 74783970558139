import {
    ChangeEvent,
    SyntheticEvent,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useNavigate, useParams } from 'react-router-dom';
import {
    Box,
    CircularProgress,
    FormControlLabel,
    Paper,
    Switch,
    Tab,
    Table,
    TableBody,
    TableHead,
    TableRow,
    Tabs,
    Typography,
} from '@mui/material';
import { useUserContext } from 'components';
import HDPButton from 'components/Button/HDPButton';
import TabPanel from 'components/Tabs/TabPanel';
import useCredibilityCompareData from 'queries/credibility/useCredibilityCompareData';
import useCredibilityPreviousSubmissions from 'queries/credibility/useCredibilityPreviousSubmissions';
import useCredibilityReportData from 'queries/credibility/useCredibilityReportData';
import { ColumnSelectList } from 'src/components/ColumnSelectList/ColumnSelectList';
import InformationPopup from 'src/components/InformationPopup/InformationPopup';
import { TABLE_PARAMS_IDS } from 'src/constants/constants';
import useTableParams from 'src/hooks/useTableParams/useTableParams';
import { CredibilityCell } from 'src/pages/Collections/Submission/Steps/Reports/Credibility/CredibilityCell/CredibilityCell';
import BackButton from 'src/patterns/BackButton/BackButton';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';
import { CredibilityPreviousSubmission } from 'types/credibility';

import CredibilityDrilldownPopup from '../CredibilityDrilldown/CredibilityDrilldownPopup';
import DownloadResultsButton from '../DownloadResultsButton/DownloadResultsButton';
import SymbolsLegend from '../SymbolsLegend/SymbolsLegend';

import styles from './credibilityReport.module.scss';

const CredibilityReport = () => {
    const { isProvider, isHesa, roles } = useUserContext();
    const {
        reportCode = '',
        submissionId = '',
        collectionId = '',
        instId = '',
    } = useParams();
    const [dialogOpen, setDialogOpen] = useState(false);
    const [isMoreDataOverrideable, setIsMoreDataOverrideable] = useState(true);
    const [dialogErrorOpen, setDialogErrorOpen] = useState(false);
    const [dialogErrorMsg, setDialogErrorMsg] = useState(
        'Sorry about this, seems like something un-explained has happened',
    );

    const navigate = useNavigate();

    const DEFAULT_TABLE_PARAMETERS = {
        previousSubmissionId: '',
        activeTab: 0,
        activeCell: '',
        toggleDetails: false,
        isCompareToggled: false,
        isMoreData: false,
    };

    const tableId = TABLE_PARAMS_IDS.CREDIBILITY_REPORT_TABLE;

    const { values: tableParams, methods: tableParamsMethods } = useTableParams(
        tableId,
        DEFAULT_TABLE_PARAMETERS,
    );

    const {
        previousSubmissionId,
        activeTab,
        activeCell,
        toggleDetails,
        isCompareToggled,
        isMoreData,
    } = tableParams;

    const {
        setPreviousSubmissionId,
        setActiveTab,
        setActiveCell,
        setToggleDetails,
        setIsCompareToggled,
        setIsMoreData,
    } = tableParamsMethods;

    const {
        data: credibilityReport,
        isFetching: isFetchingCredibilityReport,
        isError: isErrorFetchingCredibilityReport,
        refetch: refetchCredibilityReport,
    } = useCredibilityReportData({
        reportCode: reportCode,
        submissionId: submissionId,
    });

    const {
        data: previousSubmissions,
        isFetching: isFetchingPreviousSubmissions,
    } = useCredibilityPreviousSubmissions({
        submissionId: submissionId,
        collectionId: collectionId,
        instId: instId,
    });

    const {
        data: credibilityComparisonReport,
        isFetching: isFetchingComparisonReport,
        isError: isErrorFetchingComparisonReport,
        refetch: refetchComparisonReport,
    } = useCredibilityCompareData({
        reportCode: reportCode,
        currentSubmissionId: submissionId,
        previousSubmissionId,
        enabled: Boolean(isCompareToggled) && Boolean(previousSubmissionId),
    });

    const isComparing = isCompareToggled && !!previousSubmissionId;

    const reportData = isComparing
        ? credibilityComparisonReport
        : credibilityReport;

    const isFetchingReport = isComparing
        ? isFetchingComparisonReport
        : isFetchingCredibilityReport;

    const isErrorFetchingReport = isComparing
        ? isErrorFetchingComparisonReport
        : isErrorFetchingCredibilityReport;

    const refetchReport = isComparing
        ? refetchComparisonReport
        : refetchCredibilityReport;

    const toggleDetailsOpen = () => {
        setToggleDetails(!toggleDetails);
    };

    const isProviderUserWithDrilldownPermission =
        isProvider && !roles.includes('guest');

    const isUserWithDrilldownPermission =
        isProviderUserWithDrilldownPermission || isHesa;

    const onSubmissionSelected = (
        selectedSubmission: CredibilityPreviousSubmission,
    ) => {
        if (selectedSubmission && selectedSubmission?.fullSubmissionId) {
            setPreviousSubmissionId(selectedSubmission.fullSubmissionId);
        }
    };

    useEffect(() => {
        if (reportData?.Error) {
            setDialogErrorMsg(reportData.ErrorMsg);
            setDialogErrorOpen(true);
        }
    }, [reportData]);

    const changeTab = (event: SyntheticEvent, newValue: number) => {
        setActiveTab(newValue);
    };

    const closeDialog = () => {
        setDialogOpen(false);
    };

    const closeErrorDialog = () => {
        setDialogErrorOpen(false);
    };

    const openDialog = (id: string) => {
        setActiveCell(id);
        setDialogOpen(true);
    };

    const toggleMoreData = (event: ChangeEvent<HTMLInputElement>) => {
        setIsMoreData(event.target.checked);
    };

    const toggleCompare = (event: ChangeEvent<HTMLInputElement>) => {
        setIsCompareToggled(event.target.checked);
        if (!event.target.checked) {
            setPreviousSubmissionId('');
        }
    };

    useEffect(() => {
        if (
            reportData?.ReportMetadata?.StartExpanded &&
            isMoreDataOverrideable
        ) {
            // We should show more data by default if the report metadata says so - but only do this on the first time.
            setIsMoreData(true);
            setIsMoreDataOverrideable(false);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reportData, isMoreData]);

    const goBack = useCallback(() => {
        navigate(-1);
    }, [navigate]);

    const dialogError = () => {
        return (
            <InformationPopup
                isOpen={dialogErrorOpen}
                onConfirm={closeErrorDialog}
                title="Something went wrong..."
                description={dialogErrorMsg}
            />
        );
    };

    if (isErrorFetchingReport) {
        return (
            <Box
                display={'flex'}
                flexDirection={'column'}
                p={5}
                alignItems={'center'}
            >
                <Typography variant="body1" paragraph align="center">
                    Sorry about this, but it seems there&apos;s an error
                    retrieving this report right now.
                </Typography>

                <Box
                    display="flex"
                    flexDirection="row"
                    gap={2}
                    justifyContent="center"
                >
                    <BackButton onClick={goBack} />
                    <HDPButton
                        onClick={() => {
                            refetchReport();
                        }}
                        variant="primary"
                    >
                        Try again
                    </HDPButton>
                </Box>
            </Box>
        );
    }

    if (
        isFetchingReport ||
        isFetchingPreviousSubmissions ||
        !previousSubmissions
    ) {
        return (
            <Paper className={styles.credibilityReport}>
                <CircularProgress aria-label="Circular loading animation" />
            </Paper>
        );
    }

    if (reportData?.ReportMetadata) {
        return (
            <Paper className={styles.wrapper}>
                <div className={styles.title}>
                    <h1>{reportData.ReportMetadata?.Title}</h1>
                </div>
                <p className={styles.details}>
                    {reportData.ReportMetadata?.UpperNotes}
                </p>
                <br />
                {toggleDetails ? (
                    <div>
                        {reportData.ReportMetadata?.Details ? (
                            <p className={styles.details}>
                                {reportData.ReportMetadata?.Details}{' '}
                            </p>
                        ) : null}
                        <br />
                        {reportData.ReportMetadata?.WhatToLookOutFor ? (
                            <div>
                                <p className={styles.details}>
                                    {reportData.ReportMetadata.WhatToLookOutFor}
                                </p>
                                <br />
                            </div>
                        ) : null}
                        {reportData.ReportMetadata?.WhatToLookOutFor ? (
                            <div>
                                <p className={styles.details}>
                                    {reportData.ReportMetadata?.Tips}
                                </p>
                                <br />
                            </div>
                        ) : null}

                        <p className={styles.details}>
                            {reportData.ReportMetadata?.LowerNotes}
                        </p>
                    </div>
                ) : (
                    ''
                )}
                <HDPButton
                    variant="link"
                    size="small"
                    onClick={toggleDetailsOpen}
                >
                    {toggleDetails ? 'Show less' : 'Show more'}
                </HDPButton>
                <CredibilityDrilldownPopup
                    isOpen={dialogOpen}
                    onClose={closeDialog}
                    cellId={activeCell}
                    submissionId={submissionId}
                    reportCode={reportCode}
                />
                {dialogErrorOpen ? dialogError() : null}
                <div className={styles.reportWrapper}>
                    <div className={styles.report}>
                        <Tabs
                            classes={{
                                indicator: styles.tabIndicator,
                                root: styles.tabs,
                            }}
                            value={activeTab}
                            onChange={changeTab}
                            aria-label="credibility report tabs"
                        >
                            {reportData.Tabs.map((tab, index) => (
                                <Tab
                                    disableRipple
                                    label={
                                        tab.Failures
                                            ? `${tab.TabName} (${tab.Failures})`
                                            : tab.TabName
                                    }
                                    key={crypto.randomUUID()}
                                    value={index}
                                    data-test-id={formatTestId(
                                        'tab',
                                        tab?.TabName,
                                    )}
                                    id={`credibility-report-tab-${index}`}
                                    className={styles.tab}
                                />
                            ))}
                        </Tabs>
                        <DownloadResultsButton />
                    </div>

                    <div className={styles.actionsBarWrapper}>
                        {isFetchingReport ? (
                            <div className={styles.loading}>
                                <CircularProgress aria-label="Circular loading animation" />
                            </div>
                        ) : null}
                        <div className={styles.actionsBar}>
                            <FormControlLabel
                                className={styles.switchLabel}
                                control={
                                    <Switch
                                        className={styles.switch}
                                        checked={isMoreData}
                                        onChange={toggleMoreData}
                                        data-test-id={formatTestId('show more')}
                                        inputProps={{
                                            'aria-label': 'show more data',
                                        }}
                                    />
                                }
                                label="Show more data"
                            />
                            <FormControlLabel
                                className={styles.switchLabel}
                                control={
                                    <Switch
                                        className={styles.switch}
                                        checked={isCompareToggled}
                                        onChange={toggleCompare}
                                        data-test-id={formatTestId(
                                            'toggle',
                                            'compare',
                                        )}
                                        inputProps={{
                                            'aria-label': 'compare',
                                        }}
                                    />
                                }
                                label="Compare"
                            />
                            <ColumnSelectList
                                selectedValue={previousSubmissions?.find(
                                    s =>
                                        s.fullSubmissionId ===
                                        previousSubmissionId,
                                )}
                                placeholder="Select a submission"
                                closeDropDown={onSubmissionSelected}
                                list={previousSubmissions}
                                columnNames={['ID', 'Date']}
                                columnKeys={['submissionId', 'uploadedDate']}
                                disabled={!isCompareToggled}
                            />
                        </div>
                    </div>
                    <div className={styles.innerWrapper}>
                        {reportData.Tabs.map((tab, index) => (
                            <TabPanel
                                value={activeTab}
                                index={index}
                                key={crypto.randomUUID()}
                            >
                                <Table
                                    id={`credibility-tabpanel-${index}`}
                                    data-test-id={formatTestId(
                                        'table',
                                        'credibility report',
                                    )}
                                >
                                    <TableHead className={styles.rowHeight}>
                                        <TableRow key={'subHeaders'}>
                                            {tab?.HeaderRow.map(cell => (
                                                <CredibilityCell
                                                    key={cell.Label}
                                                    label={cell.Label}
                                                    cellId={cell.Id}
                                                    cellType="Label"
                                                    colour="HeaderColour"
                                                    isRow={false}
                                                />
                                            ))}
                                        </TableRow>
                                    </TableHead>
                                    <TableBody>
                                        {tab?.DataRows.map(row => (
                                            <TableRow key={crypto.randomUUID()}>
                                                <CredibilityCell
                                                    key={row.HeaderCell.Label}
                                                    label={row.HeaderCell.Label}
                                                    colour="HeaderColour"
                                                    cellType="Label"
                                                    cellId={row.HeaderCell.Id}
                                                />
                                                {row.Cells.map(cell => (
                                                    <CredibilityCell
                                                        key={cell.Id}
                                                        cellData={
                                                            cell.DataValues
                                                        }
                                                        cellType="Data"
                                                        label={cell.Label}
                                                        cellId={cell.Id}
                                                        colour={cell.Colour}
                                                        isRow
                                                        isExpanded={isMoreData}
                                                        clickHandler={
                                                            openDialog
                                                        }
                                                        canDrillDown={
                                                            isUserWithDrilldownPermission &&
                                                            reportData
                                                                .ReportMetadata
                                                                .DrilldownAvailable
                                                        }
                                                    />
                                                ))}
                                            </TableRow>
                                        ))}
                                    </TableBody>
                                </Table>
                            </TabPanel>
                        ))}
                    </div>
                </div>
                <p className={styles.details}>
                    {reportData.ReportMetadata.LowerNotes}
                </p>
                <SymbolsLegend />
            </Paper>
        );
    }
};

export default CredibilityReport;
