import React from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { useQuery } from '@tanstack/react-query';
import { requestBlobDownload } from 'pages/Collections/OnlineValidation/utils';
import { SubmissionOutletContextValue } from 'pages/Collections/Submission/types';
import useRegulator from 'queries/regulator/useRegulator';
import {
    getValidationDetails,
    getValidationDetailsCsv,
    getValidationDetailsUri,
} from 'services/api/submissions';
import QualityRule from 'src/components/QualityRule/QualityRule';
import { TABLE_PARAMS_IDS } from 'src/constants/constants';
import useTableParams from 'src/hooks/useTableParams/useTableParams';

import { requestQualityRuleFailuresDownload } from '../../../utils';

import SubmissionQualityRuleError from './SubmissionQualityRuleError';

const SubmissionQualityRule = () => {
    const [isDownloading, setIsDownloading] = React.useState(false);
    const {
        rule = '',
        submissionId = '',
        regulatorCode = '',
    } = useParams<{
        rule: string;
        submissionId: string;
        regulatorCode: string;
    }>();

    const { data: submission } =
        useOutletContext<SubmissionOutletContextValue>();

    const DEFAULT_TABLE_PARAMETERS = {
        limit: 10,
        offset: 0,
    };

    const tableId = TABLE_PARAMS_IDS.REPORTS_QUALITY_RULES;

    const { values: tableParams, methods: tableParamsMethods } = useTableParams(
        tableId,
        DEFAULT_TABLE_PARAMETERS,
    );

    const { limit, offset } = tableParams;

    const { setLimit, setOffset } = tableParamsMethods;

    const { instId } = submission.provider;

    const { data, isFetching, refetch, isError } = useQuery({
        queryKey: ['validations', submissionId, instId, rule, offset, limit],
        queryFn: () =>
            getValidationDetails({
                submissionId,
                validationId: rule,
                instId,
                regulatorCode,
                offset,
                limit,
            }),
    });

    function handleChangeRowsPerPage(event: {
        target: { value: string | number };
    }) {
        setLimit(+event.target.value);
        setOffset(0);
    }

    function handleChangePage(_: unknown, newPage: number) {
        setOffset(newPage);
    }

    // All records will have the same regulator code, so just use the first record.
    const regulatorCodeForFirstRecord = data?.records?.at(0)?.RegulatorCode;

    const { data: regulator } = useRegulator(regulatorCodeForFirstRecord);

    const validation = data?.validation;
    const pageData = data?.pagingMetadata;
    const results = data?.records;

    const handleClickDownloadCsv = async () => {
        if (submissionId) {
            setIsDownloading(true);
            if (data?.pagingMetadata.totalResultsCount > 1000) {
                const uri = await getValidationDetailsUri({
                    submissionId,
                    validationId: rule,
                    instId,
                    regulatorCode,
                });

                await requestQualityRuleFailuresDownload(uri);
            } else {
                const csv = await getValidationDetailsCsv({
                    submissionId,
                    validationId: rule,
                    instId,
                    regulatorCode,
                });

                await requestBlobDownload(
                    csv,
                    `QualityRuleReport.${submissionId.toLowerCase()}.${rule}.${new Date().toISOString()}.csv`,
                );
            }
            setIsDownloading(false);
        }
    };

    if (
        isError ||
        !data?.records?.length ||
        !data?.pagingMetadata ||
        data?.records?.validation
    ) {
        return (
            <SubmissionQualityRuleError
                rule={rule}
                refetch={refetch}
                isFetching={isFetching}
            />
        );
    }

    return (
        <QualityRule
            isDownloading={isDownloading}
            rule={rule}
            regulator={regulator}
            results={results}
            validation={validation}
            handleChangePage={handleChangePage}
            handleChangeRowsPerPage={handleChangeRowsPerPage}
            handleClickDownloadCsv={handleClickDownloadCsv}
            pageData={pageData}
            offset={offset}
            limit={limit}
        />
    );
};

export default SubmissionQualityRule;
