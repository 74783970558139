import React from 'react';
import { Edit } from '@mui/icons-material';

import HDPIconButton, {
    HDPIconButtonProps,
} from '../../components/HDPIconButton/HDPIconButton';

interface EditIconButtonProps extends Omit<HDPIconButtonProps, 'children'> {}

const EditIconButton = ({ ...iconButtonProps }: EditIconButtonProps) => {
    return (
        <HDPIconButton {...iconButtonProps}>
            <Edit />
        </HDPIconButton>
    );
};

export default EditIconButton;
