import { useCallback } from 'react';
import { useNavigate } from 'react-router-dom';
import { Box, CircularProgress, Paper, Typography } from '@mui/material';
import { QueryObserverResult, RefetchOptions } from '@tanstack/react-query';
import HDPButton from 'components/Button/HDPButton';
import BackButton from 'src/patterns/BackButton/BackButton';

import styles from './submissionQualityRule.module.scss';

interface SubmissionQualityRuleErrorProps {
    rule: string;
    refetch: (
        options?: RefetchOptions,
    ) => Promise<QueryObserverResult<unknown, Error>>;
    isFetching: boolean;
}

const SubmissionQualityRuleError = ({
    rule = '',
    refetch,
    isFetching,
}: SubmissionQualityRuleErrorProps) => {
    // TODO: This is present for submission quality rule, but not for the other quality rules?
    const navigate = useNavigate();

    const goBack = useCallback(() => {
        navigate('../', { relative: 'route' });
    }, [navigate]);

    return (
        <Paper className="QualityRule__root">
            <div className="QualityRule__title-wrapper">
                <h2
                    className={`QualityRule__id-link ${styles.qualityRuleHeader}`}
                >
                    {rule}
                </h2>
            </div>

            <Box
                display={'flex'}
                flexDirection={'column'}
                p={5}
                alignItems={'center'}
            >
                {isFetching ? (
                    <CircularProgress aria-label="Circular loading animation" />
                ) : (
                    <>
                        <Typography
                            variant={'body1'}
                            paragraph
                            align={'center'}
                        >
                            No data available for this submission
                        </Typography>

                        <Box
                            display="flex"
                            flexDirection="row"
                            gap={2}
                            justifyContent="center"
                        >
                            <BackButton onClick={() => goBack()} />
                            <HDPButton onClick={() => refetch()}>
                                Try again
                            </HDPButton>
                        </Box>
                    </>
                )}
            </Box>
        </Paper>
    );
};

export default SubmissionQualityRuleError;
