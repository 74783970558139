import { Box, Chip } from '@mui/material';
import HDPTooltip from 'components/HDPTooltip/HDPTooltip';
import { RegulatorInterface } from 'queries/specifications/types';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import styles from './regulatorLabels.module.scss';

interface RegulatorLabelsProps {
    regulators: RegulatorInterface[];
}

const RegulatorLabels = ({ regulators }: RegulatorLabelsProps) => {
    return (
        regulators.length > 0 && (
            <Box mt={1} mb={1} className={styles.regulators}>
                {regulators.map((regulator, index) => (
                    <HDPTooltip
                        title={regulator.name}
                        arrow={true}
                        key={`${regulator.shortName}-${index}`}
                    >
                        <Chip
                            className={styles.regulator}
                            variant={'outlined'}
                            size={'small'}
                            label={regulator.shortName}
                            data-test-id={formatTestId(
                                'regulator',
                                regulator.shortName,
                            )}
                        ></Chip>
                    </HDPTooltip>
                ))}
            </Box>
        )
    );
};

export default RegulatorLabels;
