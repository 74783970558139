import React from 'react';
import { Clear } from '@mui/icons-material';

import HDPIconButton, {
    HDPIconButtonProps,
} from '../../components/HDPIconButton/HDPIconButton';

interface CancelIconButtonProps extends Omit<HDPIconButtonProps, 'children'> {}

const CancelIconButton = ({ ...iconButtonProps }: CancelIconButtonProps) => {
    return (
        <HDPIconButton {...iconButtonProps}>
            <Clear />
        </HDPIconButton>
    );
};

export default CancelIconButton;
