import { TableCell } from '@mui/material';
import { getRegulatorShortName } from 'queries/regulator/utils';
import { calculatePercentage } from 'src/pages/Collections/Submission/utils';
import { Regulator } from 'types/regulator';
import { SubmissionValidationComparision } from 'types/submission';

import styles from './qualityRulesCompare.module.scss';

interface QualityRuleCompareRowProps {
    rule: SubmissionValidationComparision;
    regulators: Regulator[];
}

export const QualityRuleCompareRow = ({
    rule,
    regulators,
}: QualityRuleCompareRowProps) => {
    return (
        <>
            <TableCell align="left">
                {getRegulatorShortName(rule.RegulatorCode, regulators)}
            </TableCell>
            <TableCell align="left" className={styles.leftBorder}>
                {rule.ConjoinedTolerances}
            </TableCell>
            <TableCell align="left">{rule.PopulationCount}</TableCell>
            <TableCell align="left">
                {rule.FailureCount} (
                {!rule.FailureCount && !rule.PopulationCount
                    ? 0
                    : calculatePercentage(
                          rule.FailureCount,
                          rule.PopulationCount,
                      )}
                %)
            </TableCell>
            <TableCell align="left" className={styles.leftBorder}>
                {rule.PrevPopulationCount}
            </TableCell>
            <TableCell align="left">
                {rule.PrevFailureCount} (
                {!rule.PrevFailureCount && !rule.PrevPopulationCount
                    ? 0
                    : calculatePercentage(
                          rule.PrevFailureCount,
                          rule.PrevPopulationCount,
                      )}
                %)
            </TableCell>
            <TableCell
                align="left"
                className={
                    Math.sign(rule.FailureChange) === 1
                        ? `${styles.error} ${styles.leftBorder}`
                        : styles.leftBorder
                }
            >
                {rule.FailureChange}
            </TableCell>
        </>
    );
};

export default QualityRuleCompareRow;
