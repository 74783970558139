import React, { useState } from 'react';
import { Box, NativeSelect, NativeSelectProps } from '@mui/material';
import { RestrictAccess } from 'components';
import HDPButton from 'components/Button/HDPButton';
import WithTestId from 'components/WithTestId/WithTestId';
import { collectionStates } from 'constants/collections';
import { SpecificationInterface } from 'queries/specifications/types';
import { getSpecificationVersions } from 'queries/specifications/utils';
import useUpdateSpecification from 'src/queries/collections/useUpdateSpecification';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import CancelIconButton from '../../../../patterns/CancelIconButton/CancelIconButton';
import SaveIconButton from '../../../../patterns/SaveIconButton/SaveIconButton';
import EditButton from '../Buttons/EditButton';

const Dropdown = WithTestId<NativeSelectProps>(NativeSelect);

const isSpecificationEditable = (collectionState: number) =>
    [
        collectionStates.DRAFT,
        collectionStates.VALIDATION,
        collectionStates.OPEN,
    ].includes(collectionState);

interface SpecificationProps {
    reference: string;
    specification: SpecificationInterface | undefined;
    specifications: SpecificationInterface[];
    collectionState: number;
    limit: number;
    offset: number;
}

export const Specification = ({
    reference,
    specification,
    specifications,
    collectionState,
    limit,
    offset,
}: SpecificationProps) => {
    const { mutate: updateSpecification } = useUpdateSpecification();
    const [isEditing, setIsEditing] = useState(false);
    const [isSaving, setIsSaving] = useState(false);
    const [hasError, setHasError] = useState(false);

    const specificationVersions = getSpecificationVersions(specifications);
    const initialValue = specification?.id ?? specificationVersions[0]?.id;
    const [value, setValue] = useState(initialValue);

    const handleChange = (event: React.ChangeEvent<HTMLSelectElement>) => {
        setValue(Number(event.target.value));
        setHasError(false);
    };

    const handleSave = () => {
        if (!value) return;

        setIsSaving(true);
        updateSpecification(
            { reference, specificationId: value, limit, offset },
            {
                onSuccess: () => {
                    setIsEditing(false);
                    setIsSaving(false);
                },
                onError: () => {
                    setHasError(true);
                    setIsSaving(false);
                },
            },
        );
    };

    const handleCancel = () => {
        setIsEditing(false);
        setHasError(false);
        setValue(initialValue);
    };

    const dropdownProps = {
        value: value ?? '',
        onChange: handleChange,
        disabled: isSaving,
        error: hasError,
        testIdProps: {
            inputProps: {
                'data-test-id': formatTestId('selectSpecification', reference),
            },
        },
    };

    if (!specification && !isEditing) {
        return (
            <RestrictAccess
                allowPermissions={['management.assign-specification']}
                fallBackComponent={<Box>No specification assigned</Box>}
            >
                <HDPButton
                    size="small"
                    variant="link"
                    onClick={() => setIsEditing(true)}
                    data-test-id={formatTestId(
                        'assign specification',
                        reference,
                    )}
                >
                    Assign specification version
                </HDPButton>
            </RestrictAccess>
        );
    }

    if (isEditing || isSaving || hasError) {
        return (
            <Box
                display="flex"
                justifyContent="space-between"
                alignItems="center"
            >
                <Dropdown {...dropdownProps}>
                    {specificationVersions.map(({ id, name }) => (
                        <option key={id} value={id}>
                            {name}
                        </option>
                    ))}
                </Dropdown>
                <Box>
                    <SaveIconButton
                        onClick={handleSave}
                        isLoading={isSaving}
                        disabled={isSaving || !value}
                        data-test-id={formatTestId(
                            'saveSpecification',
                            reference,
                        )}
                    />
                    <CancelIconButton
                        onClick={handleCancel}
                        disabled={isSaving}
                        data-test-id={formatTestId(
                            'cancelSpecificationChange',
                            reference,
                        )}
                    />
                </Box>
            </Box>
        );
    }

    if (!specification) {
        return (
            <RestrictAccess
                allowPermissions={['management.assign-specification']}
                fallBackComponent={<Box>No specification assigned</Box>}
            >
                <HDPButton
                    variant="link"
                    onClick={() => setIsEditing(true)}
                    data-test-id={formatTestId(
                        'assign specification',
                        reference,
                    )}
                >
                    Assign specification version
                </HDPButton>
            </RestrictAccess>
        );
    }

    return (
        <Box display="flex" alignItems="center">
            {specification.name}
            {isSpecificationEditable(collectionState) && (
                <RestrictAccess
                    allowPermissions={['management.assign-specification']}
                >
                    <EditButton
                        onClick={() => setIsEditing(true)}
                        dataTestId={formatTestId(
                            'changeSpecification',
                            reference,
                        )}
                        title="Change specification version"
                    />
                </RestrictAccess>
            )}
        </Box>
    );
};

export default Specification;
