import {
    FormControl,
    FormControlLabel,
    FormHelperText,
    FormLabel,
    Radio,
    RadioGroup,
} from '@mui/material';
import { SpecFieldsInterface } from 'queries/specifications/types';
import { formatTestId } from 'utils/formatTestId/formatTestId';

interface VersionIncrementDropdownProps {
    isDisabled?: boolean;
    fields: SpecFieldsInterface;
}

const VersionIncrementDropdown = ({
    isDisabled = false,
    fields,
}: VersionIncrementDropdownProps) => {
    const { value, onChange, error } = fields.versionIncrement;

    const handleChange = (e: { target: { value: string } }) => {
        onChange(e.target.value);
    };

    return (
        <FormControl component={'fieldset'} error={error} disabled={isDisabled}>
            <FormLabel>Version increment</FormLabel>
            <RadioGroup onChange={handleChange} value={value}>
                <FormControlLabel
                    value={'major'}
                    control={<Radio size={'small'} color={'default'} />}
                    label={'Major'}
                    data-test-id={formatTestId('versionIncrement', 'major')}
                />
                <FormControlLabel
                    value={'minor'}
                    control={<Radio size={'small'} color={'default'} />}
                    label={'Minor'}
                    data-test-id={formatTestId('versionIncrement', 'minor')}
                />
                <FormControlLabel
                    value={'patch'}
                    control={<Radio size={'small'} color={'default'} />}
                    label={'Patch'}
                    data-test-id={formatTestId('versionIncrement', 'patch')}
                />
            </RadioGroup>
            {error && (
                <FormHelperText>Choose a version increment</FormHelperText>
            )}
        </FormControl>
    );
};

export default VersionIncrementDropdown;
