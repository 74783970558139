import React from 'react';
import { Box, TablePagination, Typography } from '@mui/material';
import { PageTitle, usePageTitle } from 'components';
import HDPButton from 'components/Button/HDPButton';
import useSpecifications from 'queries/specifications/useSpecifications';
import Loading from 'src/components/Loading/Loading';
import { TABLE_PARAMS_IDS } from 'src/constants/constants';
import useTableParams from 'src/hooks/useTableParams/useTableParams';
import useCollections from 'src/queries/collections/useCollections';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import CollectionsTable from './CollectionsTable';
import ErrorMessage from './ErrorMessage';

const states = {
    UNINITIALISED: 1,
    LOADING: 2,
    LOADED: 3,
    LOADING_FAILED: 4,
};

const determineState = (
    collectionsState: string,
    specificationsStatus: string,
) => {
    if (collectionsState === 'pending' || specificationsStatus === 'pending') {
        return states.LOADING;
    }

    if (collectionsState === 'success' && specificationsStatus === 'success') {
        return states.LOADED;
    }

    if (collectionsState === 'error' || specificationsStatus === 'error') {
        return states.LOADING_FAILED;
    }

    return states.UNINITIALISED;
};

const Collections = () => {
    usePageTitle('Collections');

    const DEFAULT_TABLE_PARAMETERS = {
        offset: 0,
        limit: 10,
    };

    const tableId = TABLE_PARAMS_IDS.COLLECTIONS;

    const { values: tableParams, methods: tableParamsMethods } = useTableParams(
        tableId,
        DEFAULT_TABLE_PARAMETERS,
    );

    const { limit, offset } = tableParams;

    const { setLimit, setOffset } = tableParamsMethods;

    const { data, status } = useCollections({
        limit: limit,
        offset: offset * limit,
    });

    const { data: specifications, status: specificationsStatus } =
        useSpecifications({ state: ['available', 'in_use'] });

    const state = determineState(status, specificationsStatus);

    const pagingMetadata = data?.pagingMetadata || { total: 0 };

    const handleChangePage = (
        _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number,
    ) => {
        setOffset(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setLimit(Number(event.target.value));

        if (Number(event.target.value) > pagingMetadata.total) {
            setOffset(0);
        }
    };

    const handleTryAgain = () => {
        // Refresh the page so that the data is fetched again
        window.location.reload();
    };

    const renderState = () => {
        switch (state) {
            case states.LOADING:
                return <Loading />;

            case states.LOADED:
                return (
                    <>
                        {data?.collections && specifications && (
                            <CollectionsTable
                                collections={data.collections}
                                limit={limit}
                                offset={offset}
                                specifications={specifications}
                            />
                        )}
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            component="div"
                            count={pagingMetadata.total}
                            rowsPerPage={limit}
                            page={offset}
                            backIconButtonProps={{
                                'aria-label': 'previous page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'next page',
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                );

            case states.LOADING_FAILED:
                return (
                    <ErrorMessage title={'Loading Error'}>
                        <Typography paragraph align={'left'}>
                            The list of collections failed to load.
                        </Typography>
                        <HDPButton
                            onClick={handleTryAgain}
                            data-test-id={formatTestId('try again')}
                        >
                            Try again
                        </HDPButton>
                    </ErrorMessage>
                );

            default:
                return null;
        }
    };

    return (
        <>
            <PageTitle title="Collection configuration" />
            <Typography>
                Assign a specification version to a collection and manage the
                collection state
            </Typography>
            <Box marginTop={3}>{renderState()}</Box>
        </>
    );
};

export default Collections;
