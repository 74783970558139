import ClearIcon from '@mui/icons-material/Clear';
import SearchIcon from '@mui/icons-material/Search';

import styles from './input.module.scss';

interface InputProps {
    value: string;
    setValue: (value: string) => void;
    onChangeDebounced?: (value: string) => void;
    label: string;
}

const Input = ({ value, setValue, onChangeDebounced, label }: InputProps) => {
    return (
        <div className={styles.root}>
            <span className={styles.searchIcon}>
                <SearchIcon />
            </span>{' '}
            <button
                aria-label="Clear input"
                className={styles.clearIcon}
                onClick={() => setValue('')}
            >
                <ClearIcon />
            </button>
            <input
                aria-label={label}
                placeholder="Search for provider name / ID / Submission"
                className={styles.hdpInput}
                value={value}
                onChange={event => {
                    setValue(event.target.value);
                    onChangeDebounced?.(event.target.value);
                }}
            />
        </div>
    );
};

export default Input;
