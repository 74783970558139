import HDPLink from 'components/HDPLink/HDPLink';
import { useUserContext } from 'components/User/useUserContext';
import { FILE_STATUS, fileStatusByKey } from 'constants/FileStatus';
import { RawSubmission } from 'src/types/submission';
import { formatTestId } from 'utils/formatTestId/formatTestId';

import { getSubmissionLinkForStep } from './utils';

import styles from './submissions.module.scss';

interface SubmissionLinkProps {
    submission: RawSubmission;
    index: number;
    isOvt?: boolean;
}

export default function SubmissionLink({
    submission,
    index,
    isOvt = false,
}: SubmissionLinkProps) {
    const { isStatutoryCustomer: isStatutoryCustomer } = useUserContext();

    const stateHistory = submission.StateHistory.map(fileStatusByKey);
    const isHistoricResubmission = stateHistory.includes(
        FILE_STATUS.RESUBMISSION_REQUIRED_HISTORIC_AMENDMENT,
    );
    const isNilReturn = stateHistory.includes(FILE_STATUS.NIL_RETURN);

    if (isNilReturn) {
        return (
            <p className={styles.name}>
                {isHistoricResubmission
                    ? 'Resubmission required - historic amendment (Nil return)'
                    : 'Nil return'}
            </p>
        );
    }

    const { path } =
        getSubmissionLinkForStep({
            submissionId: submission.Uuid,
            collectionId: submission.CollectionId,
            status: submission.Status,
            isOvt,
            isStatutoryCustomer,
        }) ?? {};

    return (
        <HDPLink
            customStyles={styles.link}
            data-test-id={formatTestId('linkTo', `submission ${index + 1}`)}
            reactRouterProps={{ to: path ?? '#' }}
        >
            {submission.FileName}
        </HDPLink>
    );
}
