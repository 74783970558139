import { ArrowBackIosNew } from '@mui/icons-material';
import HDPButton, { HDPButtonProps } from 'components/Button/HDPButton';

interface BackButtonProps extends HDPButtonProps {}

const BackButton = ({
    variant = 'secondary',
    ...buttonProps
}: BackButtonProps) => {
    return (
        <HDPButton
            data-test-id="back"
            variant={variant}
            icon={<ArrowBackIosNew />}
            {...buttonProps}
        >
            Go back
        </HDPButton>
    );
};

export default BackButton;
