// eslint-disable-next-line @typescript-eslint/ban-ts-comment
// @ts-nocheck
// disable eslint no-multi-comp rule
/* eslint-disable react/no-multi-comp */
import {
    Children,
    cloneElement,
    ElementRef,
    forwardRef,
    ReactNode,
    useEffect,
    useId,
    useRef,
    useState,
} from 'react';
import { createSearchParams, useSearchParams } from 'react-router-dom';

import {
    HDPAccordionActionsBase,
    HDPAccordionBase,
    HDPAccordionDetailsBase,
    HDPAccordionDetailsWrapper,
    HDPAccordionExpandIconBase,
    HDPAccordionGroupBase,
    HDPAccordionSummaryBase,
    HDPAccordionSummaryButtonBase,
} from './HDPAccordion.styles';

interface HDPAccordionProps {
    children: ReactNode;
    className?: string;
    disabled?: boolean;
    isOpenByDefault?: boolean;
    noBorder?: boolean;
    square?: boolean;
    size?: string;
    elevated?: boolean;
    searchParamId: string;
}

export const HDPAccordion = forwardRef<HTMLDivElement, HDPAccordionProps>(
    (props, ref) => {
        const {
            children: baseChildren,
            className,
            disabled = false,
            isOpenByDefault = false,
            searchParamId,
            ...other
        } = props;
        const [searchParams, setSearchParams] = useSearchParams();
        const wrapperRef = useRef(ref);
        const detailsRef = useRef(null);
        const [isOpen, setIsOpen] = useState(
            isOpenByDefault || searchParams.get(searchParamId) === 'true',
        );

        useEffect(() => {
            const newSearchParams = createSearchParams(searchParams);

            if (isOpen) {
                newSearchParams.set(searchParamId, 'true');
            } else {
                newSearchParams.delete(searchParamId);
            }

            if (newSearchParams.toString() !== searchParams.toString()) {
                setSearchParams(newSearchParams, { replace: true });
            }

            // eslint-disable-next-line react-hooks/exhaustive-deps
        }, [isOpen, searchParams]);

        /* Get the HDPAccordionSummary & Details child elements -
         * should always be the first two children, the rest populate
         * the HDPAccordionDetailsBase component.
         */
        const [summary, details, ...children] = Children.toArray(baseChildren);

        /* Enforce accessibility guidelines
         * https://www.w3.org/WAI/ARIA/apg/patterns/accordion/
         */

        const generatedAriaControlsId = useId();
        const generatedSummaryId = useId();
        const generatedAccordionId = useId();

        const ariaControlsId = summary.props['aria-controls']
            ? summary.props['aria-controls']
            : generatedAriaControlsId;
        const summaryId = summary.props['id']
            ? summary.props['id']
            : generatedSummaryId;
        const accordionId = details.props.id
            ? details.props.id
            : generatedAccordionId;

        return (
            <HDPAccordionBase
                className={className}
                expanded={isOpen}
                ref={wrapperRef}
                id={accordionId}
                {...other}
            >
                {summary &&
                    cloneElement(summary, {
                        onClick: () => setIsOpen(!isOpen),
                        expanded: isOpen,
                        disabled,
                        ariaControls: ariaControlsId,
                        id: summaryId,
                    })}
                {details &&
                    cloneElement(
                        details,
                        {
                            expanded: isOpen,
                            detailsRef,
                            ariaControlsId,
                            summaryId,
                        },
                        ...children,
                    )}
            </HDPAccordionBase>
        );
    },
);

interface HDPAccordionSummaryProps {
    children?: ReactNode;
    className?: string;
    expandIcon?: ReactNode;
    onClick?: () => void;
    disabled?: boolean;
    expanded?: boolean;
    header: ReactNode;
    ariaControls?: string;
    size?: string;
    fontWeight?: string | number;
    color?: string;
    'data-test-id'?: string;
}

export const HDPAccordionSummary = forwardRef<
    HTMLDivElement,
    HDPAccordionSummaryProps
>((props, ref) => {
    const {
        children,
        className,
        expandIcon,
        onClick,
        disabled,
        expanded,
        header,
        ariaControls,
        size,
        fontWeight,
        color,
        ...other
    } = props;

    return (
        <HDPAccordionSummaryBase
            className={className}
            size={size}
            fontWeight={fontWeight}
            color={color}
            aria-label={header}
        >
            <HDPAccordionSummaryButtonBase
                onClick={onClick}
                disabled={disabled}
                ref={ref}
                aria-expanded={expanded}
                aria-controls={ariaControls}
                {...other}
            >
                {expandIcon && (
                    <HDPAccordionExpandIconBase
                        disabled={disabled}
                        expanded={expanded}
                        {...expandIcon.props}
                    >
                        {expandIcon}
                    </HDPAccordionExpandIconBase>
                )}
                {header}
            </HDPAccordionSummaryButtonBase>
            <HDPAccordionActionsBase>{children}</HDPAccordionActionsBase>
        </HDPAccordionSummaryBase>
    );
});

interface HDPAccordionDetailsProps {
    children: ReactNode;
    className?: string;
    expanded?: boolean;
    detailsRef?: ElementRef<HTMLDivElement>;
    ariaControlsId?: string;
    summaryId?: string;
}
export const HDPAccordionDetails = forwardRef<
    HTMLDivElement,
    HDPAccordionDetailsProps
>((props, ref) => {
    const {
        children,
        className,
        expanded,
        detailsRef,
        ariaControlsId,
        summaryId,
        ...other
    } = props;

    return (
        <HDPAccordionDetailsWrapper
            ref={detailsRef}
            expanded={expanded}
            aria-labelledby={summaryId}
            id={ariaControlsId}
            role="region"
        >
            <HDPAccordionDetailsBase className={className} ref={ref} {...other}>
                {expanded && children}
            </HDPAccordionDetailsBase>
        </HDPAccordionDetailsWrapper>
    );
});

export const HDPAccordionGroup = (props: {
    children: ReactNode;
    spread?: boolean;
}) => {
    const { children, spread = true } = props;
    return (
        <HDPAccordionGroupBase as="section" spread={spread}>
            {Children.map(children, child => {
                return child && cloneElement(child, { spread });
            })}
        </HDPAccordionGroupBase>
    );
};

HDPAccordion.displayName = 'HDPAccordion';
HDPAccordionSummary.displayName = 'HDPAccordionSummary';
HDPAccordionDetails.displayName = 'HDPAccordionDetails';
HDPAccordionGroup.displayName = 'HDPAccordionGroup';
