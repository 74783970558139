import { generatePath } from 'react-router-dom';
import { Table, TableBody, TableRow, Typography } from '@mui/material';
import HDPLink from 'components/HDPLink/HDPLink';
import { CollectionReport } from 'types/collectionReports';
import { formatTestId } from 'utils/formatTestId/formatTestId';

import { reports } from './AdditionalReport';

import styles from './additionalReports.module.scss';

interface AdditionalReportTableProps {
    data: CollectionReport[];
    isUserAbleToAccessNSSReport: boolean;
}

export default function AdditionalReportTable({
    data,
    isUserAbleToAccessNSSReport,
}: AdditionalReportTableProps) {
    return (
        <Table
            className={styles.table}
            data-test-id={formatTestId('table', 'additional reports')}
        >
            <TableBody>
                {data.map(row => {
                    // Only show reports which have a corresponding component to avoid errors.
                    if (
                        !Object.keys(reports).includes(row.CollectionReportCode)
                    )
                        return null;

                    if (
                        row.CollectionReportCode === 'NSS' &&
                        !isUserAbleToAccessNSSReport
                    ) {
                        return null;
                    }

                    return (
                        <TableRow
                            key={crypto.randomUUID()}
                            className={styles.row}
                        >
                            <td className={styles.linkCell}>
                                <div className={styles.title}>
                                    <HDPLink
                                        data-test-id={formatTestId(
                                            'link to',
                                            row?.Title,
                                        )}
                                        reactRouterProps={{
                                            to: {
                                                pathname: generatePath(
                                                    'additional-reports/:reportCode',
                                                    {
                                                        reportCode:
                                                            row.CollectionReportCode,
                                                    },
                                                ),
                                            },
                                            state: {
                                                reportTitle: row.Title,
                                                reportDetails: row.Details,
                                            },
                                        }}
                                    >
                                        <h3>{row.Title}</h3>
                                    </HDPLink>
                                </div>
                                <Typography className={styles.description}>
                                    {row.Details}
                                </Typography>
                            </td>
                        </TableRow>
                    );
                })}
            </TableBody>
        </Table>
    );
}
