import { HTMLAttributes } from 'react';
import Highlighter from 'react-highlight-words';
import { CheckBox, CheckBoxOutlineBlank } from '@mui/icons-material';
import {
    AutocompleteValue,
    UseAutocompleteRenderedOption,
} from '@mui/material';
import HDPCheckbox from 'components/HDPCheckbox/HDPCheckbox';
import { FuzzyMatch } from 'hooks/useFuzzySearch/useFuzzySearch';

import { AutocompleteOption } from '../AutocompleteDropdown';

import styles from './autocompleteDropdownItem.module.scss';

const checkboxIcon = <CheckBoxOutlineBlank fontSize="small" />;
const checkedIcon = <CheckBox fontSize="small" />;

/**
 * Highlight the search term in the string.
 *
 * @param searchWords Searchwords to highlight
 * @param string String to search for substrings in
 * @returns A JSX element with the substring highlighted
 */
const highlightSubstring = (searchWords: string[], string: string) => {
    return (
        <Highlighter
            highlightClassName={styles.highlight}
            autoEscape
            searchWords={searchWords}
            textToHighlight={string ? string : ''}
            highlightTag={'span'}
        />
    );
};

interface AutocompleteDropdownItemProps<Multiple extends boolean | undefined> {
    option: FuzzyMatch<AutocompleteOption>;
    index: number;
    getOptionProps: (
        renderedOption: UseAutocompleteRenderedOption<string>,
    ) => HTMLAttributes<HTMLLIElement> & {
        key: string | number;
    };
    isEmptySearch: boolean;
    selectedOptions?: AutocompleteValue<
        string | number,
        Multiple,
        false,
        false
    >;
}

export default function AutocompleteDropdownItem<
    Multiple extends boolean | undefined,
>({
    option,
    index,
    getOptionProps,
    isEmptySearch,
    selectedOptions,
}: AutocompleteDropdownItemProps<Multiple>) {
    const { key, ...optionProps } = getOptionProps({
        option: option.result.value.toString(),
        index,
    });

    let isSelected: boolean;

    /*
     * When there is no input value in the search box, the score will be 0.
     * This is used to display the full option label, without highlighting
     * If we call option.highlight() when there is no search value, it will return an empty string
     * and the option will be blank.
     */
    const optionLabel = isEmptySearch
        ? option.result.label
        : highlightSubstring(option.queryTerms, option.result.label);

    if (Array.isArray(selectedOptions)) {
        isSelected =
            selectedOptions.findIndex(v => v === option.result.value) !== -1;
    } else {
        isSelected = selectedOptions === option.result.value;
    }

    return (
        <li
            key={key}
            className={styles.item}
            {...optionProps}
            aria-selected={isSelected}
        >
            <HDPCheckbox
                icon={checkboxIcon}
                checkedIcon={checkedIcon}
                checked={isSelected}
                disableFocusRipple
                disableRipple
            />
            {optionLabel}
        </li>
    );
}
