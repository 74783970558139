import React from 'react';
import { Check } from '@mui/icons-material';
import { CircularProgress } from '@mui/material';

import HDPIconButton, {
    HDPIconButtonProps,
} from '../../components/HDPIconButton/HDPIconButton';

import styles from './saveIconButton.module.scss';
interface SaveIconButtonProps extends Omit<HDPIconButtonProps, 'children'> {
    isLoading?: boolean;
}

const SaveIconButton = ({
    isLoading = false,
    ...iconButtonProps
}: SaveIconButtonProps) => {
    return (
        <HDPIconButton {...iconButtonProps}>
            {isLoading ? (
                <CircularProgress
                    className={styles.loading}
                    aria-label="Circular loading animation"
                />
            ) : (
                <Check />
            )}
        </HDPIconButton>
    );
};

export default SaveIconButton;
