import { MouseEvent, useCallback, useEffect, useRef, useState } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import ExpandMoreIcon from '@mui/icons-material/ExpandMore';
import { CircularProgress, MenuItem } from '@mui/material';
import { useQueryClient } from '@tanstack/react-query';
import BannerBox from 'components/BannerBox/BannerBox';
import {
    HDPAccordion,
    HDPAccordionDetails,
    HDPAccordionGroup,
    HDPAccordionSummary,
} from 'components/HDPAccordion';
import HDPLink from 'components/HDPLink/HDPLink';
import HDPMenu from 'components/HDPMenu/HDPMenu';
import useProviderCollections from 'queries/provider/useProviderCollections';
import { connectWebSocket } from 'services/api/utils';
import { useUserContext } from 'src/components';
import { ACCORDION_IDS, PATHS } from 'src/constants/constants';
import { Collection } from 'src/types/collection';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import SubmissionAccordionActions from './SubmissionAccordionActions';
import SubmissionAccordionHeader from './SubmissionAccordionHeader';
import SubmissionsList from './SubmissionsList';

import styles from './submissions.module.scss';

interface SubmissionsProps {
    isOvt?: boolean;
}

const Submissions = ({ isOvt = false }: SubmissionsProps) => {
    const navigate = useNavigate();
    const [activityLogLink, setActivityLogLink] = useState('');
    const { activeOrganisation } = useUserContext();
    const [menuAnchorEl, setMenuAnchorEl] = useState<HTMLButtonElement | null>(
        null,
    );
    const socketRef = useRef<WebSocket>();
    const queryClient = useQueryClient();

    const collectionStateFilters = isOvt
        ? ['open', 'historic amendment', 'validation']
        : ['open', 'closed', 'historic amendment'];

    const {
        data: collections,
        isLoading: collectionsLoading,
        isError: collectionsError,
        isSuccess: collectionsLoaded,
    } = useProviderCollections({
        instId: activeOrganisation.id,
        state: collectionStateFilters,
    });

    const openMenu = (
        event: MouseEvent<HTMLButtonElement>,
        collection: Collection,
    ) => {
        event.stopPropagation();

        const activityLogLink = generatePath(PATHS.PROVIDER_ACTIVITY_LOG, {
            collectionReference: collection.reference,
        });

        setMenuAnchorEl(event.currentTarget);
        setActivityLogLink(activityLogLink);
    };

    const closeMenu = () => {
        setActivityLogLink('');
        setMenuAnchorEl(null);
    };

    // We invalidate the queries here to update the submission list when a submission is progressing through the upload process
    const handleMessage = useCallback(
        (_msg: MessageEvent) => {
            queryClient.invalidateQueries({
                queryKey: ['submissions'],
                exact: false,
            });
        },
        [queryClient],
    );

    useEffect(() => {
        const socketExists = !!socketRef.current;

        const createConnection = () => {
            socketRef.current = connectWebSocket(activeOrganisation.id);
            socketRef.current.onmessage = handleMessage;
        };

        if (!socketExists) {
            createConnection();
        }

        if (
            socketExists &&
            socketRef.current?.readyState === WebSocket.CLOSED
        ) {
            createConnection();
        }
    }, [activeOrganisation.id, handleMessage]);

    useEffect(() => {
        // Clean up websocket connection
        return () => {
            if (socketRef.current?.readyState === WebSocket.OPEN) {
                socketRef.current?.close();
            }
        };
    }, []);

    const pageTitle = isOvt ? 'Online Validation Tool' : 'Manage Submissions';

    return (
        <>
            <h1 className={styles.heading1} id="heading">
                {pageTitle}
            </h1>
            {isOvt && (
                <BannerBox
                    heading="Online validation tool"
                    upperText="This is the online validation tool (OVT). This tool is designed for providers to validate their files. Once your file is ready to be analysed you will need to upload it to the relevant live collection. Please note that only the latest uploaded file will be available to view."
                    lowerText={
                        <span>
                            If you need support please contact{' '}
                            <HDPLink href="mailto:liaison@hesa.ac.uk">
                                HESA Liaison.
                            </HDPLink>
                        </span>
                    }
                />
            )}
            <div className={styles.accordionheader}>
                <span className={styles.accordionHeaderName}>Name</span>
                <span className={styles.accordionHeaderHeading}>
                    Date range
                </span>
                <span className={styles.accordionHeaderHeading}>
                    {!isOvt ? 'Submissions' : ''}
                </span>
                <span className={styles.accordionHeaderHeading}>&nbsp;</span>
                <span className={styles.accordionHeaderHeading}>&nbsp;</span>
            </div>
            <HDPMenu
                id="log-menu"
                anchorEl={menuAnchorEl}
                open={Boolean(menuAnchorEl)}
                onClose={closeMenu}
                anchorOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
                transformOrigin={{
                    vertical: 'center',
                    horizontal: 'left',
                }}
            >
                <MenuItem
                    onClick={() => {
                        if (activityLogLink) {
                            navigate(activityLogLink);
                        }
                    }}
                >
                    Activity log
                </MenuItem>
            </HDPMenu>
            {collectionsLoading && (
                <CircularProgress aria-label="Circular loading animation" />
            )}
            {collectionsError && <p>Error getting collections</p>}
            {collectionsLoaded && (
                <HDPAccordionGroup>
                    {collections.map((collection: Collection) => (
                        <HDPAccordion
                            data-test-id={formatTestId(
                                'openAccordion',
                                collection?.reference,
                            )}
                            key={`submissions-${collection.reference}`}
                            searchParamId={
                                ACCORDION_IDS.MANAGE_SUBMISSIONS.COLLECTION +
                                collection.reference
                            }
                        >
                            <HDPAccordionSummary
                                expandIcon={<ExpandMoreIcon />}
                                header={
                                    <SubmissionAccordionHeader
                                        collection={collection}
                                        isOvt={isOvt}
                                    />
                                }
                                size="md"
                                fontWeight="normal"
                                data-test-id={formatTestId(
                                    'open accordion',
                                    collection.id.toString(),
                                )}
                            >
                                <SubmissionAccordionActions
                                    collection={collection}
                                    openMenu={openMenu}
                                    isOvt={isOvt}
                                />
                            </HDPAccordionSummary>
                            <HDPAccordionDetails>
                                <SubmissionsList
                                    collection={collection}
                                    instId={activeOrganisation.id}
                                    isOvt={isOvt}
                                />
                            </HDPAccordionDetails>
                        </HDPAccordion>
                    ))}
                </HDPAccordionGroup>
            )}
        </>
    );
};

Submissions.displayName = 'Submissions';

export default Submissions;
