import HDPButton from 'components/Button/HDPButton';
import { DateTime } from 'luxon';
import {
    requestFileDownload,
    requestOvtFileDownload,
} from 'pages/Collections/OnlineValidation/utils';
import { Submission } from 'types/submission';

import styles from './upload.module.scss';

interface UploadedMessageProps {
    instId: string;
    data: Submission;
    isOvt?: boolean;
}

export default function UploadedMessage({
    instId,
    data,
    isOvt = false,
}: UploadedMessageProps) {
    // We use `new Date()` as a fallback for when a file has just been uploaded but the real data hasn't been fetched yet.
    const uploadedDate = DateTime.fromISO(
        data.uploaded || new Date().toISOString(),
    ).toLocaleString(DateTime.DATETIME_MED);

    const handleRequestDownload = () => {
        const downloadFn = isOvt ? requestOvtFileDownload : requestFileDownload;
        downloadFn(data.uuid, instId, data.fileName, data.uploaded, false);
    };

    return (
        <>
            <div className={styles.uploadActions}>
                <p>
                    File <strong>{data.fileName}</strong> was uploaded on{' '}
                    {uploadedDate}
                </p>
                <p>
                    <HDPButton
                        variant="link"
                        onClick={() => {
                            handleRequestDownload();
                        }}
                    >
                        Download Original File
                    </HDPButton>
                </p>
            </div>
        </>
    );
}
