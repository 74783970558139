import React from 'react';
import {
    CircularProgress,
    Dialog,
    DialogActions,
    DialogContent,
    DialogContentText,
    DialogTitle,
} from '@mui/material';
import HDPButton from 'components/Button/HDPButton';

import styles from './confirmationPopup.module.scss';

type ConfirmationPopupProps = {
    isOpen: boolean;
    title: string;
    onCancel: () => void;
    onConfirm: () => void;
    children?: React.ReactNode;
    description?: string;
    isLoading?: boolean;
    customConfirmButtonText?: string;
    isCancelButtonHidden?: boolean;
    isConfirmButtonHidden?: boolean;
    customCancelButtonText?: string;
    size?: 'sm' | 'md' | 'lg';
};

const ConfirmationPopup = ({
    isOpen,
    title,
    onCancel,
    onConfirm,
    children,
    description,
    isLoading,
    customConfirmButtonText,
    isCancelButtonHidden,
    isConfirmButtonHidden,
    customCancelButtonText,
    size = 'sm',
}: ConfirmationPopupProps) => {
    return (
        <Dialog
            open={isOpen}
            className={styles.confirmationPopup}
            maxWidth={size}
            onClose={onCancel}
        >
            {isLoading ? (
                <div className={styles.loaderWrapper}>
                    <div className={styles.loader}>
                        <CircularProgress aria-label="Circular loading animation" />
                    </div>
                </div>
            ) : null}
            <DialogTitle>{title}</DialogTitle>
            <DialogContent>
                {description && description.length > 0 && (
                    <DialogContentText>{description}</DialogContentText>
                )}
                {children}
            </DialogContent>
            <DialogActions>
                {!isConfirmButtonHidden && (
                    <HDPButton onClick={onConfirm} variant="primary" autoFocus>
                        {' '}
                        {customConfirmButtonText
                            ? customConfirmButtonText
                            : 'Confirm'}
                    </HDPButton>
                )}
                {/* {!isConfirmButtonHidden && (
                    <HDPButton
                        onClick={onConfirm}
                        variant="secondary"
                        autoFocus
                    >
                        {' '}
                        {customConfirmButtonText
                            ? customConfirmButtonText
                            : 'Confirm'}
                    </HDPButton>
                )} */}
                {!isCancelButtonHidden && (
                    <HDPButton onClick={onCancel} variant="secondary">
                        {customCancelButtonText
                            ? customCancelButtonText
                            : 'Cancel'}
                    </HDPButton>
                )}
            </DialogActions>
        </Dialog>
    );
};

export default ConfirmationPopup;
