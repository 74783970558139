import { generatePath, useNavigate, useOutletContext } from 'react-router-dom';
import { ArrowForwardIos } from '@mui/icons-material';
import HDPButton from 'components/Button/HDPButton';
import { SubmissionOutletContextValue } from 'pages/Collections/Submission/types';
import { collectionIsLocked } from 'pages/Collections/Submission/utils';
import { OVTPATHS, PATHS } from 'src/constants/constants';
import { useStepper } from 'src/pages/Collections/Submission/Steps/Stepper/StepperContext';
import BackButton from 'src/patterns/BackButton/BackButton';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';
import { v4 as uuid } from 'uuid';

import { RestrictAccess, useUserContext } from '..';

import styles from './continueBackUploadNew.module.scss';

type ContinueBackUploadNewProps = {
    isContinuePossible: boolean;
    collectionId: string;
    collectionReference?: string;
    instId: string;
    isOvt: boolean;
    nextStepName: string;
    previousStepName: string;
    uploadPermissionsList: string[];
    isResubmissionAllowed: boolean;
    isThereAnotherStep?: boolean;
};

const getUploadPath = (isOvt: boolean, isAdmin: boolean) => {
    switch (isOvt) {
        case true:
            switch (isAdmin) {
                case true:
                    return OVTPATHS.ADMIN_UPLOAD;
                case false:
                    return OVTPATHS.UPLOAD;
            }
            break;

        case false:
            switch (isAdmin) {
                case true:
                    return PATHS.ADMIN_UPLOAD;
                case false:
                    return PATHS.UPLOAD;
            }
    }
};

/*
    Component for the Continue, Back and Upload new file buttons on the submission steps. 
    We currently copy-paste these buttons across multiple steps, with various differences in how the button
    props are set. This component is intended to gather up all the logic for these buttons based on the 
    submission state and permissions, and reduce the amount of duplication of logic across the steps,
    hopefully simplifying the whole thing.
 */
const ContinueBackUploadNew = ({
    isContinuePossible,
    collectionId,
    instId,
    collectionReference,
    isOvt,
    nextStepName,
    previousStepName,
    uploadPermissionsList,
    isResubmissionAllowed,
    isThereAnotherStep = true,
}: ContinueBackUploadNewProps) => {
    const { navigateToStep } = useStepper();
    const navigate = useNavigate();
    const { isAdmin } = useUserContext();
    const { collection } = useOutletContext<SubmissionOutletContextValue>();

    const uploadPath = getUploadPath(isOvt, isAdmin);
    const newSubmissionRoute = generatePath(uploadPath, {
        collectionId: collectionId,
        reference: collectionReference ? collectionReference : '',
        submissionId: uuid().toUpperCase(),
        instId,
    });

    return (
        <div className={styles.buttons}>
            <BackButton onClick={() => navigateToStep(previousStepName)} />

            {isThereAnotherStep && (
                <HDPButton
                    icon={<ArrowForwardIos />}
                    data-test-id="continue"
                    isDisabled={!isContinuePossible}
                    variant="primary"
                    onClick={() => navigateToStep(nextStepName)}
                    iconPlacement="right"
                >
                    Continue
                </HDPButton>
            )}
            <div className={styles.uploadButton}>
                <RestrictAccess allowPermissions={uploadPermissionsList}>
                    <HDPButton
                        variant="secondary"
                        data-test-id={formatTestId('upload', 'new file')}
                        isDisabled={
                            !isResubmissionAllowed ||
                            (!isOvt &&
                                collection &&
                                collectionIsLocked(collection))
                        }
                        onClick={() => navigate(newSubmissionRoute)}
                    >
                        Upload new file
                    </HDPButton>
                </RestrictAccess>
            </div>
        </div>
    );
};

export default ContinueBackUploadNew;
