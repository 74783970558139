import { ImCross } from 'react-icons/im';
import HDPLink from 'components/HDPLink/HDPLink';

import { useStepper } from '../Stepper/StepperContext';

import styles from './upload.module.scss';

const UploadError = () => {
    const { paths } = useStepper();

    return (
        <>
            <div className={styles.uploadActions}>
                <ImCross className={`${styles.icon} ${styles.red}`} />

                <span>Upload failed</span>

                <div className={styles.or}>
                    We were unable to upload your file or the upload was
                    cancelled.
                </div>
                <HDPLink
                    className={styles.errorLink}
                    reactRouterProps={{ to: paths.Submissions }}
                >
                    Back
                </HDPLink>
            </div>
        </>
    );
};

export default UploadError;
