import { generatePath, useParams } from 'react-router-dom';
import { Typography } from '@mui/material';
import { useUserContext } from 'components';
import AccordionSecondary from 'components/AccordionSecondary/AccordionSecondary';
import HDPLink from 'components/HDPLink/HDPLink';
import { ACCORDION_IDS, PATHS } from 'constants/constants';
import { Collection } from 'types/collection';
import { CredibilityChapter } from 'types/credibility';
import { formatTestId } from 'utils/formatTestId/formatTestId';

import DownloadButton from '../../../../../../../patterns/DownloadButton/DownloadButton';

import styles from './credibilityReportListItem.module.scss';

interface CredibilityReportListItemProps {
    row: CredibilityChapter;
    index: number;
    handleClickDownloadChapterReports: (chapterId: number) => void;
    collection?: Collection;
    submissionId: string;
    instId: string;
}

export default function CredibilityReportListItem({
    row,
    index,
    handleClickDownloadChapterReports,
    collection,
    submissionId,
    instId,
}: CredibilityReportListItemProps) {
    const { isAdmin, isSpecialCategory, isAnalyst } = useUserContext();
    const { collectionId = '', reference = '' } = useParams();

    const searchParam =
        ACCORDION_IDS.SUBMISSION.CREDIBILITY_REPORT_CHAPTER + (index + 1);

    return (
        <li className={styles.listItem}>
            <AccordionSecondary
                dataTestId={formatTestId('open accordion', row?.chapterName)}
                title={row.chapterName}
                id={searchParam}
            >
                <ul className={styles.reports}>
                    {row?.reports &&
                        row?.reports?.map(report => (
                            <li
                                className={styles.report}
                                key={crypto.randomUUID()}
                            >
                                <div className={styles.rule}>
                                    <HDPLink
                                        reactRouterProps={{
                                            to: generatePath(
                                                isAdmin ||
                                                    isSpecialCategory ||
                                                    isAnalyst
                                                    ? PATHS.ADMIN_CREDIBILITY_REPORT
                                                    : PATHS.CREDIBILITY_REPORT,
                                                {
                                                    collectionId:
                                                        collection?.id?.toString() ||
                                                        collectionId,
                                                    reference:
                                                        collection?.reference ||
                                                        reference,
                                                    submissionId,
                                                    reportCode:
                                                        report.ReportCode,
                                                    instId,
                                                },
                                            ),
                                        }}
                                    >
                                        {report.Title}
                                    </HDPLink>
                                </div>
                                <Typography>{report.UpperNotes}</Typography>
                            </li>
                        ))}
                </ul>
            </AccordionSecondary>
            <div className={styles.downloadIcon}>
                <DownloadButton
                    onClick={() => {
                        handleClickDownloadChapterReports(row.chapterId);
                    }}
                    data-test-id={formatTestId(
                        'download chapter',
                        `${index + 1}`,
                    )}
                    aria-label="Download Chapter"
                />
            </div>
        </li>
    );
}
