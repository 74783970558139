import HDPTooltip from 'components/HDPTooltip/HDPTooltip';

import EditIconButton from '../../../../patterns/EditIconButton/EditIconButton';

interface EditButtonProps {
    title?: string;
    onClick: () => void;
    dataTestId?: string;
}

export default function EditButton({
    title,
    onClick,
    dataTestId,
}: EditButtonProps) {
    return (
        <HDPTooltip arrow title={title} placement="top">
            <div>
                {<EditIconButton onClick={onClick} data-test-id={dataTestId} />}
            </div>
        </HDPTooltip>
    );
}
