import { useEffect, useState } from 'react';
import { generatePath, useNavigate, useOutletContext } from 'react-router-dom';
import { ArrowForwardIos } from '@mui/icons-material';
import { Paper } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import { useUserContext } from 'components';
import HDPButton from 'components/Button/HDPButton';
import HDPLink from 'components/HDPLink/HDPLink';
import { getActivityLogEntries } from 'services/api/collections/collections';
import AccessDenied from 'src/components/AccessDenied/AccessDenied';
import { RestrictAccess } from 'src/components/index';
import { AL_UPLOADED_VIA_UI } from 'src/constants/activityLogTypeIds';
import { PATHS } from 'src/constants/constants';
import { fileStatusById, fileStatusByKey } from 'src/constants/FileStatus';
import BackButton from 'src/patterns/BackButton/BackButton';
import useUpdateSubmission from 'src/queries/submissions/useUpdateSubmission';

import { SubmissionOutletContextValue } from '../../types';
import {
    collectionIsClosed,
    requestSignOffFormFileDownload,
} from '../../utils';
import { useStepper } from '../Stepper/StepperContext';

import SignOffForm from './SignOffForm';

import styles from './signOff.module.scss';

interface ActivityLog {
    records: { username: string }[];
}

// TO SEE THIS COMPONENT RENDERED IN THE APP:
// 1. Upload a submission as a provider submitter (e.g hdp.provider.submitter.bristol@test.com)
// 2. Wait for it to process and get through to Quality Assurance
// 3. Sign out and sign in as a HESA Admin user (hdp.hesa.admin@test.com), find the submission you just uploaded in Monitoring and go to the Submit step
// 4. Go through the submit process on that screen, then go back to monitoring and for the submission you uploaded, there will be a 3-dot menu with 'Approve'.
// You may have to refresh and do this again - this is due to some submissions requiring two approvals for risk
// purposes (though at time of writing, it does'nt explain this in the UI)
// 5. If the state goes to 'Awaiting SC Approval' you need to sign in as the sc ofs test user (hdp.sc.analyst.ofs@test.com) and do the
// same (Monitoring -> 3 dot menu for submission -> approve)
// 6. Now log back in as the provider submitter (hdp.provider.submitter.bristol@test.com), and go to the submission you uploaded.
// 7. You should now be able to see the 'Sign Off' step, which includes this component.
const SignOff = () => {
    const history = useNavigate();
    const { roles, isProvider, isHesa, isStatutoryCustomer, isAdmin } =
        useUserContext();
    const {
        data: submission,
        setPageTitle,
        getSubmission,
        collection,
    } = useOutletContext<SubmissionOutletContextValue>();
    const [termsChecked, setTermsChecked] = useState(false);
    const [loading, setLoading] = useState(false);
    const isProviderSignOff = isProvider && !!roles.includes('sign-off');
    const canSignOff = submission.isLatest && (isAdmin || isProviderSignOff);
    const { navigateToStep } = useStepper();
    const { updateState } = useUpdateSubmission();

    const { data: activityLog, isFetching: activityLogLoading } =
        useQuery<ActivityLog>({
            queryKey: ['activity-log-uploaded', submission?.uuid],
            queryFn: () =>
                getActivityLogEntries({
                    submissionUuid: submission?.uuid,
                    activityLogTypeId: AL_UPLOADED_VIA_UI,
                }),
            enabled: Boolean(submission?.uuid),
            refetchInterval: false,
        });

    const user = activityLog?.records?.at(0)?.username;

    useEffect(() => {
        setPageTitle('Sign Off');
    }, [setPageTitle]);

    const handleSubmit = async () => {
        try {
            if (isSignOffRequested) {
                setLoading(true);
                await requestSignOffFormFileDownload(submission.fileHash);
                setLoading(false);
                return;
            }

            if (isApproved) {
                setLoading(true);
                await updateState(
                    {
                        submissionId: submission.uuid,
                        statusId: fileStatusByKey('PROVIDER_SIGNOFF').id,
                        action: 'Provider sign-off',
                    },
                    {
                        onSettled: () => {
                            getSubmission();
                            setLoading(false);
                        },
                    },
                );
            }
        } catch (err: unknown) {
            console.error('Error during sign off:', err);
            setLoading(false);
        }
    };

    const navigateToMonitoringDashboard = () => {
        const route = generatePath(
            isHesa || isStatutoryCustomer ? PATHS.MONITORING : PATHS.DASHBOARD,
        );
        history(route);
    };

    const isSignOffRequested =
        fileStatusById(submission?.status.id).order ===
        fileStatusByKey('PROVIDER_SIGNOFF').order;

    const isApproved =
        fileStatusById(submission?.status.id).order ===
        fileStatusByKey('APPROVED').order;

    const isSignedOff =
        fileStatusById(submission?.status.id).order ===
        fileStatusByKey('SIGNED_OFF').order;

    const isCollectionClosed = Boolean(
        collection && collectionIsClosed(collection),
    );

    const canSubmit = () => {
        if (isCollectionClosed) {
            return isSignedOff;
        }

        return (
            isSignedOff || isSignOffRequested || (canSignOff && termsChecked)
        );
    };

    const getSubmitButtonLabel = () => {
        if (isSignedOff) {
            return isAdmin ? 'Return to monitoring' : 'Return to my Dashboard';
        }
        if (isSignOffRequested) {
            return 'Download Sign-off Form';
        }
        if (isApproved) {
            return 'Request Sign-Off';
        }
    };

    return (
        <RestrictAccess
            allowPermissions={['collections.approval-and-sign-off']}
            render={() => <AccessDenied mt={8} />}
        >
            <div>
                <Paper className={styles.signOff}>
                    <h2 className={styles.heading}>Sign off</h2>{' '}
                    <SignOffForm
                        isCollectionClosed={isCollectionClosed}
                        isApproved={isApproved}
                        isSignOffRequested={isSignOffRequested}
                        isSignedOff={isSignedOff}
                        isLoading={loading}
                        isActivityLogLoading={activityLogLoading}
                        submission={submission}
                        user={user}
                        canSignOff={canSignOff}
                        termsChecked={termsChecked}
                        setTermsChecked={setTermsChecked}
                    />
                </Paper>
                <div className={styles.buttons}>
                    <BackButton onClick={() => navigateToStep('Approval')} />

                    <HDPButton
                        data-test-id="submit"
                        isDisabled={!canSubmit()}
                        variant="primary"
                        onClick={
                            isSignedOff
                                ? navigateToMonitoringDashboard
                                : handleSubmit
                        }
                        icon={<ArrowForwardIos />}
                        iconPlacement="right"
                    >
                        {getSubmitButtonLabel()}
                    </HDPButton>
                </div>
                <div className={styles.contactText}>
                    <span>
                        Please contact{' '}
                        <HDPLink
                            href="mailto:liaison@hesa.ac.uk"
                            data-test-id="emailLiaison"
                        >
                            HESA Liaison
                        </HDPLink>{' '}
                        if a resubmission is required.
                    </span>
                </div>
            </div>
        </RestrictAccess>
    );
};

export default SignOff;
