import { ButtonHTMLAttributes, ReactNode } from 'react';

import styles from './hdpButton.module.scss';

export interface HDPButtonProps
    extends ButtonHTMLAttributes<HTMLButtonElement> {
    children?: ReactNode;
    'data-test-id'?: string;
    variant?: 'primary' | 'secondary' | 'ghost' | 'text' | 'link';
    isDisabled?: boolean;
    icon?: ReactNode;
    iconPlacement?: 'left' | 'right' | 'both';
    size?: 'large' | 'regular' | 'small' | 'xSmall';
    customStyles?: string;
}

export default function HDPButton({
    children,
    isDisabled,
    variant = 'primary',
    icon,
    iconPlacement = 'left',
    size = 'regular',
    customStyles,
    ...buttonProps
}: HDPButtonProps) {
    const buttonClasses = [
        customStyles ? customStyles : '',
        styles.button,
        styles[variant],
        styles[size],
        isDisabled ? styles.disabled : '',
    ]
        .filter(Boolean)
        .join(' ');

    return (
        <button
            disabled={isDisabled}
            {...buttonProps}
            className={buttonClasses}
        >
            {icon && ['left', 'both'].includes(iconPlacement) && (
                <div className={styles.icon}>{icon}</div>
            )}
            {children}
            {icon && ['right', 'both'].includes(iconPlacement) && (
                <div className={styles.icon}>{icon}</div>
            )}
        </button>
    );
}
