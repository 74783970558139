import React from 'react';
import { useNavigate } from 'react-router-dom';
import { SimCardDownloadOutlined } from '@mui/icons-material';
import {
    Chip,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TableRow,
} from '@mui/material';
import HDPButton from 'components/Button/HDPButton';
import HDPLink from 'components/HDPLink/HDPLink';
import { calculatePercentage } from 'src/pages/Collections/Submission/utils';
import BackButton from 'src/patterns/BackButton/BackButton';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';
import { PagingMetadata } from 'types/api';
import { Regulator } from 'types/regulator';
import { Record, Validation } from 'types/submission';

import styles from './qualityRule.module.scss';

interface Detail {
    Name: string;
    Value: string;
}

type QualityRuleProps = {
    rule: string;
    validation: Validation;
    results: Record[];
    regulator: Regulator | undefined;
    isDownloading: boolean;
    handleClickDownloadCsv: () => void;
    handleChangePage: (
        event: React.MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => void;
    handleChangeRowsPerPage: (
        event: React.ChangeEvent<HTMLInputElement>,
    ) => void;

    pageData: PagingMetadata;
    limit: number;
    offset: number;
};

/*
    Quality rules table component that shows the details of a single quality rule. 
    TODO: Reduce the amount of props passed to the component by handling the data fetching 
    and table params in this component, and only pass in the tableID and API url?
*/
const QualityRule = (props: QualityRuleProps) => {
    const {
        rule,
        validation,
        results,
        regulator,
        isDownloading,
        handleClickDownloadCsv,
        handleChangePage,
        handleChangeRowsPerPage,
        pageData,
        limit,
        offset,
    } = props;
    const history = useNavigate();

    return (
        <div>
            <Paper className={styles.QualityRule}>
                <div className={styles.title}>
                    <h2 className={styles.heading}>
                        <HDPLink
                            size="regular"
                            href={validation.GuidanceUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {rule}
                        </HDPLink>
                    </h2>
                </div>
                <div className={styles.ruleDataItem}>
                    <span>Description</span>
                    <span>{validation.Description}</span>
                </div>
                <div className={styles.ruleDataWrapper}>
                    {results && results?.length > 0 ? (
                        <div className={styles.ruleData}>
                            <div className={styles.ruleDataItem}>
                                <span>Regulator</span>
                                <span>
                                    {regulator?.name} ({regulator?.shortName})
                                </span>
                            </div>
                            <div className={styles.ruleDataItem}>
                                <span>Tolerance</span>
                                <span className={styles.tolerance}>
                                    {results[0]?.ConjoinedTolerances}

                                    {results[0]
                                        ?.IsProviderToleranceOverride && (
                                        <Chip
                                            size="small"
                                            label="Override"
                                            className={styles.overrideChip}
                                        />
                                    )}
                                </span>
                            </div>
                            <div className={styles.ruleDataItem}>
                                <span>Population</span>
                                <span>{results[0]?.Population}</span>
                            </div>
                            <div className={styles.ruleDataItem}>
                                <span>Failed</span>
                                <span>
                                    {results[0]?.Failures} (
                                    {calculatePercentage(
                                        results[0]?.Failures,
                                        results[0]?.Population,
                                    )}
                                    %)
                                </span>
                            </div>
                        </div>
                    ) : (
                        ''
                    )}
                    <div className={styles.downloadButtons}>
                        <HDPButton
                            variant="link"
                            isDisabled={isDownloading}
                            data-test-id={formatTestId('download details')}
                            onClick={handleClickDownloadCsv}
                            icon={<SimCardDownloadOutlined />}
                        >
                            Download results
                        </HDPButton>
                    </div>
                </div>
                <Paper className={styles.tableWrapper}>
                    <Table
                        className={styles.table}
                        data-test-id={formatTestId('table', 'quality rule')}
                    >
                        <TableHead>
                            <TableRow key={'dataColumns'}>
                                {results &&
                                    results?.length > 0 &&
                                    results[0]?.Details &&
                                    results[0]?.Details?.map(
                                        (column: {
                                            Name: string | null | undefined;
                                        }) => (
                                            <TableCell
                                                key={`header_${column.Name}`}
                                                className={`${styles.cell} ${styles.cellHeader}`}
                                            >
                                                {column.Name}
                                            </TableCell>
                                        ),
                                    )}
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {results &&
                                results.map(
                                    (
                                        row: {
                                            RowIdentifier: string;
                                            Details: Detail[];
                                        },
                                        index: number,
                                    ) => (
                                        <TableRow
                                            className={styles.tableRow}
                                            key={`${index}-${row.RowIdentifier}`}
                                            hover
                                        >
                                            {row.Details &&
                                                row.Details.map(detail => (
                                                    <TableCell
                                                        key={`${row.RowIdentifier}_valueFor_${detail.Name}`}
                                                    >
                                                        {detail.Value}
                                                    </TableCell>
                                                ))}
                                        </TableRow>
                                    ),
                                )}
                        </TableBody>
                    </Table>
                    <TablePagination
                        rowsPerPageOptions={[10, 25, 50, 100]}
                        component="div"
                        count={pageData?.totalResultsCount || 0}
                        rowsPerPage={limit}
                        page={offset}
                        backIconButtonProps={{
                            'aria-label': 'previous page',
                        }}
                        nextIconButtonProps={{
                            'aria-label': 'next page',
                        }}
                        onPageChange={handleChangePage}
                        onRowsPerPageChange={handleChangeRowsPerPage}
                    />
                </Paper>
            </Paper>
            <div className={styles.buttons}>
                <BackButton onClick={() => history(-1)} variant="primary" />
            </div>
        </div>
    );
};

export default QualityRule;
