import { useCallback } from 'react';
import { Box, Grid, Typography } from '@mui/material';
import HDPButton from 'components/Button/HDPButton';
import HDPLink from 'components/HDPLink/HDPLink';
import { ValidationInterface } from 'queries/specifications/types';
import { getRuleMetadata } from 'queries/specifications/utils';

import useEditModal from '../EditModal/useEditModal';
import MoveSpecificationToDraft from '../MoveSpecificationToDraft/MoveSpecificationToDraft';

interface RuleMetadataProps {
    validation: ValidationInterface;
}

export default function RuleMetadata({ validation }: RuleMetadataProps) {
    const { description, guidanceUrl } = getRuleMetadata(validation);
    const validationId = validation.id;

    const { editRuleMetadata, spec } = useEditModal();

    const handleEditRuleMetadata = useCallback(
        () => editRuleMetadata(validationId),
        [editRuleMetadata, validationId],
    );

    return (
        <>
            <Box p={3} pt={0}>
                <Grid container spacing={3}>
                    <Grid item xs={12}>
                        <Typography variant={'subtitle1'}>
                            Rule description
                        </Typography>
                        <Typography variant={'body2'}>{description}</Typography>
                    </Grid>

                    <Grid item xs={12}>
                        <Typography variant={'subtitle1'}>
                            Guidance link
                        </Typography>
                        <HDPLink
                            href={guidanceUrl}
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            {guidanceUrl}
                        </HDPLink>
                    </Grid>
                </Grid>
            </Box>
            <MoveSpecificationToDraft
                spec={spec}
                message={'Edit rule metadata?'}
            >
                <HDPButton
                    variant="link"
                    size="small"
                    onClick={handleEditRuleMetadata}
                >
                    Edit rule metadata
                </HDPButton>
            </MoveSpecificationToDraft>
        </>
    );
}
