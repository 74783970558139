import { usePageTitle } from './PageTitleProvider';

import styles from './pageTitle.module.scss';

type PageTitleProps = { title: string };

export const PageTitle = ({ title }: PageTitleProps) => {
    usePageTitle(title);

    return (
        <h1 id="heading" className={styles.heading}>
            {title}
        </h1>
    );
};
