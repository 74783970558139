import { UseMutateFunction } from '@tanstack/react-query';
import HDPButton from 'components/Button/HDPButton';
import { createDownloadLinkAndClickIt } from 'pages/Collections/OnlineValidation/utils';
import { getDpsPresignedUrl } from 'services/api/files';
import { DpsSubmission } from 'services/api/submissions/types';
import { formatTestId } from 'utils/formatTestId/formatTestId';

interface DpsTaskResponse {
    message: string;
    status: number;
}

const getActionLabelForSubmission = (status: string | null | undefined) => {
    switch (status) {
        case 'Success':
            return 'Download';
        case 'Pending':
            return 'Anonymising...';
        case 'Error':
            return 'Anonymise';
        case 'Failed':
            return 'Download';
        case '':
            return 'Anonymise';
        case null:
            return 'Anonymise';
        case undefined:
            return 'Anonymise';
        default:
            return status;
    }
};

const SubmissionActionForm = ({
    submission,
    runDpsTask,
}: {
    submission: DpsSubmission;
    runDpsTask: UseMutateFunction<DpsTaskResponse, Error, string, unknown>;
}) => {
    const submissionStatus = submission.dpsFileStatus ?? '';

    const handleAnonymise = (uuid: string) => {
        // set the status of the submission to 'Pending' optimistically to avoid lag in UI
        submission.dpsFileStatus = 'Pending';

        runDpsTask(uuid, {
            onError: () => {
                submission.dpsFileStatus = 'Failed';
            },
            onSuccess: () => {
                submission.dpsFileStatus = 'Pending';
            },
        });
    };

    const handleDownload = async (uuid: string) => {
        const { url } = await getDpsPresignedUrl(uuid);
        createDownloadLinkAndClickIt(url, ``);
    };

    const handleClick = () => {
        if (submission.dpsFileStatus === 'Success') {
            handleDownload(submission.uuid);
        }

        if (submission.dpsFileStatus === null) {
            handleAnonymise(submission.uuid);
        }
    };

    const isButtonDisabled = ['Pending', 'Error', 'Failed'].includes(
        submissionStatus,
    );
    return (
        <form
            id={submission.uuid}
            data-test-id={formatTestId(submission.uuid, 'form')}
        >
            <HDPButton
                onClick={e => {
                    e.preventDefault();
                    handleClick();
                }}
                variant="secondary"
                isDisabled={isButtonDisabled}
            >
                {getActionLabelForSubmission(submissionStatus)}
            </HDPButton>
        </form>
    );
};

export default SubmissionActionForm;
