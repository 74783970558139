import classNames from 'classnames';

import styles from './step.module.scss';

type StepConnectorProps = {
    isActive: boolean;
    isComplete: boolean;
    isError: boolean;
};

const StepConnector = ({
    isActive,
    isComplete,
    isError,
}: StepConnectorProps) => {
    const classes = classNames(styles.connector, {
        [styles.connectorActive]: isActive,
        [styles.connectorComplete]: isComplete,
        [styles.connectorError]: isError,
    });

    return <div className={classes} />;
};

export default StepConnector;
