import { ChangeEvent, MouseEvent } from 'react';
import { Table, TableContainer } from '@mui/material';
import { SubmissionHydrated } from 'pages/Monitoring/SubmissionSummary/utils';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import OvtSubmissionSummaryTableBody from './OvtSubmissionSummaryTableBody';
import OvtSubmissionSummaryTableHead from './OvtSubmissionSummaryTableHead';
import PaginationWithTestId from './PaginationWithTestId';

import styles from './ovtSubmissionSummaryTable.module.scss';

interface OvtSubmissionSummaryTableProps {
    submissions: SubmissionHydrated[];
    order: 'asc' | 'desc';
    orderBy: string;
    onSort: (id: string) => void;
    limit: number;
    setLimit: (limit: number) => void;
    offset: number;
    setOffset: (offset: number) => void;
}

export const OvtSubmissionSummaryTable = ({
    submissions,
    order,
    orderBy,
    onSort,
    limit,
    setLimit,
    offset,
    setOffset,
}: OvtSubmissionSummaryTableProps) => {
    const handleSort = (id: string) => {
        onSort(id);
    };
    // required for pagination
    const rowCount = submissions.length;

    const handleChangeRowsPerPage = (
        event: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        const rows = +event.target.value;
        setLimit(rows);
        setOffset(0);
    };

    const handleChangePage = (
        event: MouseEvent<HTMLButtonElement> | null,
        newPage: number,
    ) => {
        setOffset(newPage);
    };

    // prettier-ignore
    const pagedSubmissions = submissions.slice(
        (offset * limit),
        ((offset * limit) + limit)
    );

    return (
        <TableContainer className={styles.tableContainer}>
            <Table
                size={'small'}
                data-test-id={formatTestId('table', 'ovt submissions')}
            >
                <OvtSubmissionSummaryTableHead
                    orderBy={orderBy}
                    order={order}
                    onSort={handleSort}
                />
                <OvtSubmissionSummaryTableBody
                    submissions={submissions}
                    pagedSubmissions={pagedSubmissions}
                />
            </Table>

            <PaginationWithTestId
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={rowCount || 0}
                rowsPerPage={limit}
                page={offset}
                backIconButtonProps={{
                    'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'next page',
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                testIdProps={{
                    backIconButtonProps: {
                        'data-test-id': 'previousPage',
                    },
                    nextIconButtonProps: {
                        'data-test-id': 'nextPage',
                    },
                }}
            />
        </TableContainer>
    );
};

OvtSubmissionSummaryTable.displayName = 'OvtSubmissionSummaryTable';
