import React, { useEffect, useMemo, useRef } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
} from '@mui/material';
import { RestrictAccess } from 'components';
import ContinueBackUploadNew from 'components/ContinueBackUploadNew/ContinueBackUploadNew';
import HDPLink from 'components/HDPLink/HDPLink';
import {
    getIconForDisplayState,
    getStateForStep,
} from 'pages/Collections/Submission/Steps/Processing/utils';
import AccessDenied from 'src/components/AccessDenied/AccessDenied';
import {
    fileStatusById,
    fileStatusByKey,
    ifStatusIdIsUpToQualityProcessingComplete,
} from 'src/constants/FileStatus';
import { getApprovalStates } from 'src/pages/Collections/Submission/utils';

import { SubmissionOutletContextValue } from '../../types';
import { useStepper } from '../Stepper/StepperContext';

import { approvalConfig } from './approval.config';

import styles from './approval.module.scss';

const Approval = () => {
    const { data, submissionId, collection, setPageTitle } =
        useOutletContext<SubmissionOutletContextValue>();

    const { collectionId = '' } = useParams();

    const submissionApprovalStates = useRef(
        data
            ? getApprovalStates(
                  data.provider.riskCode,
                  data.provider.countryCode,
              )
            : [],
    );
    const { isSubmissionSignedOff } = useStepper();

    useEffect(() => {
        setPageTitle('Approval');
    }, [setPageTitle]);

    const reSubRequired = useMemo(
        () =>
            data?.status.code === 'RESUBMISSION_REQUIRED' ||
            data?.status.code === 'RESUBMISSION_REQUIRED_HISTORIC_AMENDMENT',
        [data],
    );

    const canResubmit = useMemo(
        () =>
            data
                ? reSubRequired ||
                  ifStatusIdIsUpToQualityProcessingComplete(data?.status.id)
                : false,
        [data, reSubRequired],
    );

    const inApproval = () =>
        data
            ? [
                  'HESA_ANALYST_APPROVAL',
                  'HESA_APPROVAL',
                  'SC_APPROVAL',
              ].includes(data?.status.code)
            : false;

    const isApproved = useMemo(() => {
        if (reSubRequired) return false;

        if (!data?.status) return false;

        return (
            fileStatusById(data?.status.id).order >=
            fileStatusByKey('APPROVED').order
        );
    }, [data?.status, reSubRequired]);

    const canContinue = !reSubRequired && (isApproved || isSubmissionSignedOff);

    const stateAllowed = (step: { code: string }) => {
        if (!submissionApprovalStates.current) {
            return false;
        }
        return submissionApprovalStates.current.find(
            state => state.code === step.code,
        );
    };

    const renderProcessingSteps = (status: { code: string; id: number }) => {
        return approvalConfig.filter(stateAllowed).map(step => {
            const { displayState, label } = getStateForStep(
                step.displayStates,
                status.id,
            );
            return (
                <ListItem key={crypto.randomUUID()} className={styles.state}>
                    <ListItemIcon className={styles.icon}>
                        {getIconForDisplayState(displayState)}
                    </ListItemIcon>
                    <ListItemText primary={label} />
                </ListItem>
            );
        });
    };

    return (
        <RestrictAccess
            allowPermissions={['collections.approval-and-sign-off']}
            render={() => <AccessDenied mt={8} />}
        >
            {data && data.status ? (
                <div>
                    <Paper className={styles.wrapper}>
                        <h2 className={styles.heading}>Approval</h2>

                        {isApproved ? (
                            <p className={styles.approvalText}>
                                Submission with ID{' '}
                                <strong>{submissionId}</strong> has been
                                approved
                            </p>
                        ) : (
                            ''
                        )}

                        {inApproval() ? (
                            <React.Fragment>
                                <p className={styles.approvalText}>
                                    Your submission is going through the
                                    approval process that is relevant to your
                                    provider.
                                </p>

                                <p className={styles.approvalText}>
                                    When approval is completed you will need to
                                    return here to request sign-off from your
                                    provider&apos;s Accountable Office (England
                                    and Wales) or Head of Provider (Scotland and
                                    Northern Ireland) to complete the collection
                                    process.
                                </p>
                            </React.Fragment>
                        ) : (
                            ''
                        )}

                        {!reSubRequired ? (
                            <List className={styles.states}>
                                <ListItem className={styles.state}>
                                    <ListItemText
                                        primary="Required approval states:"
                                        className={styles.bold}
                                    />
                                </ListItem>
                                {renderProcessingSteps(data.status)}
                            </List>
                        ) : (
                            <React.Fragment>
                                <h3
                                    className={`${styles.heading} ${styles.red} ${styles.resubmissionRequiredHeading}`}
                                >
                                    Resubmission required
                                </h3>
                                <p className={styles.resubmissionRequiredText}>
                                    <strong>
                                        Your submission has not been approved
                                        and a resubmission is now required. This
                                        may be because
                                    </strong>
                                </p>
                                <p>
                                    - Your submission has not met the quality
                                    standards expected.
                                </p>
                                <p>
                                    - You may have requested to make a
                                    resubmission.
                                </p>
                                <p>
                                    - Historical amendment changes do not align
                                    with the expected changes.
                                </p>
                                <p>
                                    - There are outstanding queries with your
                                    data that need attention.
                                </p>
                                <p className={styles.resubmissionRequiredText}>
                                    <strong>Next steps:</strong>
                                </p>
                                <p>
                                    You are required to make a new submission to
                                    this collection.
                                </p>
                            </React.Fragment>
                        )}
                    </Paper>
                    <ContinueBackUploadNew
                        isContinuePossible={canContinue}
                        collectionId={collection?.id.toString() || collectionId}
                        instId={data.provider.instId}
                        collectionReference={
                            collection?.reference ? collection.reference : ''
                        }
                        isOvt={false}
                        nextStepName={
                            reSubRequired ? 'Submissions' : 'Sign-off'
                        }
                        previousStepName="Submit"
                        uploadPermissionsList={[
                            'collections.upload-submission-file',
                        ]}
                        isResubmissionAllowed={canResubmit}
                    />
                    <div className={styles.resubmissionRequiredText}>
                        {reSubRequired ? (
                            <span>
                                If you want to discuss this please contact{' '}
                                <HDPLink href="mailto:liaison@hesa.ac.uk">
                                    HESA Liaison.
                                </HDPLink>
                            </span>
                        ) : (
                            <span>
                                Please contact{' '}
                                <HDPLink href="mailto:liaison@hesa.ac.uk">
                                    HESA Liaison
                                </HDPLink>{' '}
                                if a resubmission is required.
                            </span>
                        )}
                    </div>
                </div>
            ) : (
                ''
            )}
        </RestrictAccess>
    );
};

export default Approval;
