import React from 'react';
import { MoreVert } from '@mui/icons-material';

import HDPIconButton, {
    HDPIconButtonProps,
} from '../../components/HDPIconButton/HDPIconButton';

interface ThreeDotIconButtonProps
    extends Omit<HDPIconButtonProps, 'children'> {}

const ThreeDotIconButton = ({
    ...iconButtonProps
}: ThreeDotIconButtonProps) => {
    return (
        <HDPIconButton {...iconButtonProps}>
            <MoreVert />
        </HDPIconButton>
    );
};

export default ThreeDotIconButton;
