import { Menu, MenuProps } from '@mui/material';

import styles from './HDPMenu.module.scss';

interface HDPMenuProps
    extends Omit<MenuProps, 'className' | 'classes' | 'variant'> {
    variant?: 'blue' | 'muiDefault';
}

export default function HDPMenu(props: HDPMenuProps) {
    const { children, variant = 'blue', ...propsWithoutChildren } = props;

    return (
        <Menu
            {...propsWithoutChildren}
            classes={{
                paper: variant === 'blue' ? styles.menu : undefined, // This is temporary until we have a unified menu component across the app.
                list: styles.menuList,
            }}
        >
            {children}
        </Menu>
    );
}
