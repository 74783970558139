import React from 'react';
import { useUserContext } from 'components';
import HDPTooltip from 'components/HDPTooltip/HDPTooltip';
import { LatestSubmission } from 'types/submission';
import { formatTestId } from 'utils/formatTestId/formatTestId';

import DownloadButton from '../../../patterns/DownloadButton/DownloadButton';

import { requestGracefulFailuresDownload } from './utils';

import styles from './gracefulFailuresDownload.module.scss';

interface GracefulFailuresDownloadProps {
    latestSubmission: LatestSubmission;
    isOvt?: boolean;
}

const GracefulFailuresDownload = ({
    latestSubmission,
    isOvt = false,
}: GracefulFailuresDownloadProps) => {
    const { isAdmin } = useUserContext();

    if (isAdmin && latestSubmission?.errorFileExists) {
        return (
            <div className={styles.iconWrapper}>
                <HDPTooltip
                    title="Download error log file"
                    arrow
                    placement={'top'}
                >
                    <div>
                        <DownloadButton
                            onClick={() =>
                                requestGracefulFailuresDownload(
                                    latestSubmission.uuid,
                                    isOvt,
                                )
                            }
                            data-test-id={formatTestId(
                                'download file',
                                'graceful failures report',
                            )}
                            aria-label="Download Graceful Failures Report"
                        />
                    </div>
                </HDPTooltip>
            </div>
        );
    }
};

export default GracefulFailuresDownload;
