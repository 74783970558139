import { useCallback } from 'react';
import {
    Box,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TableRow,
    Typography,
} from '@mui/material';
import HDPButton from 'components/Button/HDPButton';
import {
    RegulatorTolerance,
    SpecificationInterface,
    ValidationInterface,
} from 'queries/specifications/types';
import { getRegulatorTolerance } from 'queries/specifications/utils';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import useEditModal from '../EditModal/useEditModal';
import MoveSpecificationToDraft from '../MoveSpecificationToDraft/MoveSpecificationToDraft';

import styles from './regulatorConfigTable.module.scss';

interface RegulatorConfigTableProps {
    validation: ValidationInterface;
    spec: SpecificationInterface;
}
export default function RegulatorConfigTable({
    validation,
    spec,
}: RegulatorConfigTableProps) {
    const { id } = validation;
    const regulators = getRegulatorTolerance(validation);
    const { editTolerances } = useEditModal();

    const handleEditTolerances = useCallback(
        () => editTolerances(id),
        [id, editTolerances],
    );

    if (regulators.length === 0) {
        return (
            <Box
                p={3}
                display={'flex'}
                flexDirection={'column'}
                alignItems={'center'}
            >
                <Typography variant={'body2'} paragraph>
                    No regulators have been configured for this rule
                </Typography>
            </Box>
        );
    }

    return (
        <>
            <Table
                size={'small'}
                data-test-id={formatTestId('table', 'regulator config')}
            >
                <TableHead>
                    <TableRow className={styles.row}>
                        <TableCell>Regulator</TableCell>
                        <TableCell align={'right'}>Tolerance</TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {regulators.map(
                        (regulator: RegulatorTolerance, index: number) => (
                            <TableRow
                                hover
                                className={styles.row}
                                key={`${regulator.code}-${index}`}
                            >
                                <TableCell>{regulator.name}</TableCell>
                                <TableCell align={'right'}>
                                    {regulator.tolerance}
                                </TableCell>
                            </TableRow>
                        ),
                    )}
                </TableBody>
            </Table>
            <MoveSpecificationToDraft spec={spec} message={'Edit tolerances?'}>
                <div className={styles.editButton}>
                    <HDPButton
                        variant="link"
                        onClick={handleEditTolerances}
                        data-test-id={formatTestId('edit tolerances')}
                        size="small"
                    >
                        Edit tolerances
                    </HDPButton>
                </div>
            </MoveSpecificationToDraft>
        </>
    );
}
