import { Tooltip, TooltipProps } from '@mui/material';

import styles from './HDPTooltip.module.scss';

interface HDPTooltipProps extends Omit<TooltipProps, 'classes'> {
    variant?: 'blue' | 'muiDefault';
}

export default function HDPTooltip(props: HDPTooltipProps) {
    const { children, variant = 'blue', ...propsWithoutChildren } = props;
    return (
        <Tooltip
            {...propsWithoutChildren}
            classes={{
                tooltip: variant === 'blue' ? styles.tooltip : undefined, // This is temporary until we have a unified tooltip component across the app.
                arrow: variant === 'blue' ? styles.arrow : undefined, // This is temporary until we have a unified tooltip component across the app.
            }}
        >
            {children}
        </Tooltip>
    );
}
