import React, { useState } from 'react';
import { Box } from '@mui/material';
import { RestrictAccess } from 'components';
import {
    Collection,
    CollectionStateInterface,
} from 'services/api/collections/types';
import useUpdateCollection from 'src/queries/collections/useUpdateCollection';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import CancelIconButton from '../../../../patterns/CancelIconButton/CancelIconButton';
import SaveIconButton from '../../../../patterns/SaveIconButton/SaveIconButton';
import EditButton from '../Buttons/EditButton';

import CollectionStateDropDown from './CollectionStateDropdown';
import CollectionStatus from './CollectionStatus';

const modes = {
    DISPLAY: 1,
    EDITING: 2,
    SAVING: 3,
    ERROR: 4,
};

interface CollectionStateProps {
    collection: Collection;
    limit: number; // Used to update the collections table at the right place after the mutation
    offset: number; // Used to update the collections table at the right place after the mutation
}

const getCollectionState = (
    state: CollectionStateInterface,
    availableStates: CollectionStateInterface[],
) => {
    return {
        ...state,
        isEditable: state.id !== null && availableStates.length > 0,
    };
};

const CollectionState = ({
    collection,
    limit,
    offset,
}: CollectionStateProps) => {
    const { DISPLAY, EDITING, SAVING, ERROR } = modes;

    const [mode, setMode] = useState(DISPLAY);

    const defaultChoice = { id: '', name: 'Select state' };
    const { availableStates, reference } = collection;
    const { id: statusId, isEditable } = getCollectionState(
        collection.state,
        availableStates,
    );

    const [value, setValue] = useState(defaultChoice.id);

    const handleChange = (event: React.ChangeEvent<{ value: unknown }>) => {
        setValue(event.target.value as string);
    };

    const { mutate: updateCollection } = useUpdateCollection();

    const handleEdit = () => setMode(EDITING);
    const handleCancel = () => setMode(DISPLAY);
    const handleSave = () => {
        setMode(SAVING);
        updateCollection(
            {
                reference: collection.reference,
                stateId: Number(value),
                limit: limit,
                offset: offset,
            },
            {
                onSettled: () => {
                    setMode(DISPLAY);
                },
                onError: () => {
                    setMode(ERROR);
                },
            },
        );
    };

    const renderDropdown = ({ disabled = false, error = false } = {}) => (
        <CollectionStateDropDown
            testIdProps={{
                inputProps: {
                    'data-test-id': formatTestId('selectState', reference),
                },
            }}
            value={value}
            onChange={handleChange}
            disabled={disabled}
            error={error}
        >
            {[defaultChoice, ...availableStates].map(({ id, name }) => (
                <option key={id} value={id}>
                    {name}
                </option>
            ))}
        </CollectionStateDropDown>
    );

    const renderEditButton = () => (
        <RestrictAccess
            allowPermissions={['management.change-collection-state']}
        >
            <EditButton
                title="Change collection state"
                onClick={handleEdit}
                dataTestId={formatTestId('changeState', reference)}
            />
        </RestrictAccess>
    );

    const renderSaveButton = ({ loading = false, disabled = false } = {}) => (
        <SaveIconButton
            title="Save collection state"
            onClick={handleSave}
            isLoading={loading}
            disabled={loading || disabled}
            data-test-id={formatTestId('saveState', reference)}
        />
    );

    const renderCancelButton = ({ disabled = false } = {}) => (
        <CancelIconButton
            title="Cancel"
            onClick={handleCancel}
            disabled={disabled}
            data-test-id={formatTestId('cancelStateChange', reference)}
        />
    );

    switch (mode) {
        case EDITING:
            return (
                <Box
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    display={'flex'}
                >
                    {renderDropdown()}
                    <Box>
                        {renderSaveButton({
                            disabled: value === defaultChoice.id,
                        })}
                        {renderCancelButton()}
                    </Box>
                </Box>
            );

        case SAVING:
            return (
                <Box
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    display={'flex'}
                >
                    {renderDropdown({ disabled: true })}
                    <Box>
                        {renderSaveButton({ loading: true })}
                        {renderCancelButton({ disabled: true })}
                    </Box>
                </Box>
            );

        case ERROR:
            return (
                <Box
                    justifyContent={'space-between'}
                    alignItems={'center'}
                    display={'flex'}
                >
                    {renderDropdown({ error: true })}
                    <Box>
                        {renderSaveButton()}
                        {renderCancelButton()}
                    </Box>
                </Box>
            );

        default:
            return (
                <Box
                    alignItems={'center'}
                    display={'flex'}
                    justifyContent={'flex-start'}
                >
                    <CollectionStatus id={statusId} reference={reference} />
                    {isEditable && renderEditButton()}
                </Box>
            );
    }
};

export default CollectionState;
