import { useCallback, useEffect } from 'react';
import { CircularProgress } from '@mui/material';
import { getFailureSummaryTable } from 'services/api/submissions';
import { SubmissionFailureSummary } from 'types/submission';

import SummaryTable from './SummaryTable';

interface SummaryProps {
    submissionId: string;
    instId: string;
    summaryData?: SubmissionFailureSummary;
    setSummaryData: (data: SubmissionFailureSummary) => void;
}

const Summary = ({
    submissionId,
    instId,
    summaryData,
    setSummaryData,
}: SummaryProps) => {
    const getRegulatorFailures = useCallback(
        async (submissionId: string, instId: string) => {
            const results = await getFailureSummaryTable({
                submissionId,
                instId,
            });
            setSummaryData(results);
        },
        [setSummaryData],
    );
    useEffect(() => {
        const fetchData = async () => {
            await getRegulatorFailures(submissionId, instId);
        };
        fetchData();
    }, [instId, submissionId, getRegulatorFailures]);

    return (
        <div>
            {submissionId && summaryData ? (
                <div>
                    <SummaryTable summaryData={summaryData} />
                </div>
            ) : (
                <CircularProgress aria-label="Circular loading animation" />
            )}
        </div>
    );
};

export default Summary;
