import { useUserContext } from 'components/User/useUserContext';
import { FILE_STATUS } from 'constants/FileStatus';
import useSubmissions from 'queries/submissions/useSubmissions';
import { Collection } from 'src/types/collection';

import CollectionIcon from './CollectionIcon';
import { formatDate } from './utils';

import styles from './submissions.module.scss';

const SubmissionAccordionHeader = ({
    collection,
    isOvt = false,
}: {
    collection: Collection;
    isOvt?: boolean;
}) => {
    const { activeOrganisation } = useUserContext();
    const { data: submissions } = useSubmissions({
        collectionId: collection.id,
        instId: activeOrganisation.id,
        isOvt,
    });

    const from = formatDate(collection.referencePeriodStart);
    const to = formatDate(collection.referencePeriodEnd);

    const latestSubmission = submissions?.at(0);

    let submissionFileStatus = '';

    if (latestSubmission !== undefined) {
        submissionFileStatus = FILE_STATUS[latestSubmission.Status].label;
    }

    return (
        <>
            <span className={styles.accordionName}>{collection.name}</span>
            <span className={styles.accordionDate}>{`${from} - ${to}`}</span>
            {!isOvt && (
                <>
                    <span className={styles.accordionSubmissions}>
                        {collection.collectionSubmissionCount}
                    </span>
                    <span className={styles.accordionStatus}>
                        <span className={styles.icon}>
                            <CollectionIcon
                                collection={collection}
                                latestSubmission={latestSubmission}
                            />
                        </span>
                        <span>{submissionFileStatus}</span>
                    </span>
                </>
            )}
        </>
    );
};

export default SubmissionAccordionHeader;
