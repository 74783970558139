import { Skeleton, TableCell, TableRow } from '@mui/material';

const LoadingSkeleton = ({ rowCount }: { rowCount: number }) => {
    return Array(rowCount)
        .fill(undefined)
        .map((_, i) => {
            return (
                <TableRow key={`skeleton-row-${i}`}>
                    <TableCell colSpan={3}>
                        <Skeleton
                            width="55rem"
                            variant="rectangular"
                            aria-label="Loading bar"
                        />
                    </TableCell>
                </TableRow>
            );
        });
};

export default LoadingSkeleton;
