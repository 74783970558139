import { stringify } from 'query-string';
import { token } from 'services/storage';
import { API_URL, WS_URL } from 'src/constants/constants';

interface RequestArgs {
    query?: object;
    headers?: Record<string, string>;
    body?: object;
}

class UnauthorisedError extends Error {
    response: Response;
    constructor(response: Response) {
        super();
        this.name = 'UnauthorisedError';
        this.response = response;
    }
}

const handleResponse = (res: Response) => {
    if (!res.ok) {
        if (res.status === 401) {
            throw new UnauthorisedError(res);
        }
        throw new Error(`${res.status}: ${res.statusText}`);
    }

    return res;
};

const empty = (o: object) => Object.keys(o).length === 0;

const querystring = (o: object = {}) =>
    empty(o) ? '' : `?${stringify(o, { skipEmptyString: true })}`;

const getToken = () => token.get();

export const getRaw = (
    path: string,
    { query, headers = {} }: RequestArgs = {},
) =>
    fetch(`${API_URL}${path}${querystring(query)}`, {
        headers: { ...headers, Authorization: `Bearer ${getToken()}` },
    }).then(handleResponse);

export const get = (...params: [string, RequestArgs?]) =>
    getRaw(...params).then(res => res.json());

export const put = (path: string, { body }: RequestArgs = {}) =>
    fetch(`${API_URL}${path}`, {
        method: 'PUT',
        headers: {
            Authorization: `Bearer ${getToken()}`,
            'Content-Type': 'application/json',
        },
        body: JSON.stringify(body),
    })
        .then(handleResponse)
        .then(res => res.json());

export const post = async (path: string, { body, headers }: RequestArgs = {}) =>
    fetch(`${API_URL}${path}`, {
        method: 'POST',
        headers: {
            Authorization: `Bearer ${getToken()}`,
            'Content-Type': 'application/json',
            ...headers,
        },
        body: JSON.stringify(body),
    })
        .then(handleResponse)
        .then(res => {
            return res.status === 204 || res.status === 202 ? null : res.json();
        });

export const del = async (path: string, { query }: RequestArgs = {}) => {
    await fetch(`${API_URL}${path}${querystring(query)}`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${getToken()}` },
    }).then(handleResponse);
};

export const delWithBody = (path: string, { body }: RequestArgs = {}) => {
    return fetch(`${API_URL}${path}`, {
        method: 'DELETE',
        headers: { Authorization: `Bearer ${getToken()}` },
        body: JSON.stringify(body),
    }).then(handleResponse);
};

export const patch = (path: string, { body, headers }: RequestArgs = {}) =>
    fetch(`${API_URL}${path}`, {
        method: 'PATCH',
        headers: {
            Authorization: `Bearer ${getToken()}`,
            'Content-Type': 'application/json',
            ...headers,
        },
        body: JSON.stringify(body),
    })
        .then(handleResponse)
        .then(res => {
            return res.status === 204 ? null : res.json();
        });

export const getFilenameFromContentHeader = (header: string | null) => {
    let filename = '';
    if (header && header.indexOf('attachment') !== -1) {
        const regex = /filename[^;=\n]*=((['"]).*?\2|[^;\n]*)/;
        const matches = regex.exec(header);
        if (matches !== null && matches[1]) {
            filename = matches[1].replace(/['"]/g, '');
        }
    }

    // TODO: If header is `null` then this returns an empty string for the filename. Is that bad?

    return filename;
};

export const connectWebSocket = (instId: string) => {
    // Helper function for connecting to the websocket in websockets/handlers.js
    const token = getToken();
    if (!token) {
        throw new Error('No token provided');
    }
    const ws = new WebSocket(`${WS_URL}?instId=${instId}&token=${token}`);
    ws.onmessage = msg => {
        console.log('Received:', msg);
    };

    ws.onerror = error => {
        console.error('WebSocket encountered an error:', error);
    };
    return ws;
};
