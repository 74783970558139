import React, { ChangeEvent, MouseEvent } from 'react';
import {
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TablePaginationProps,
    TableRow,
    TableSortLabel,
} from '@mui/material';
import HDPLink from 'components/HDPLink/HDPLink';
import WithTestId from 'components/WithTestId/WithTestId';
import useRegulators from 'queries/regulator/useRegulators';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';
import { SubmissionValidationComparision } from 'types/submission';

import QualityRuleCompareRow from './QualityRuleCompareRow';

import styles from './qualityRulesCompare.module.scss';

const TablePaginationWithTestId =
    WithTestId<TablePaginationProps>(TablePagination);

type SortKey = 'POPULATION' | 'FAILURE' | 'TOLERANCE' | 'STATUS' | 'ID';

interface QualityRulesCompareProps {
    data?: SubmissionValidationComparision[];
    pagingData: {
        limit: number;
        offset: number;
        totalResultsCount: number;
    };
    sortHandler: (
        sortByColumn: SortKey,
        sortDirection: 'asc' | 'desc',
        page: number,
    ) => void;
    pageHandler: (page: number, limit: number) => void;
    activeSort: string;
    activeSortDirection: 'asc' | 'desc';
    noResultsFound: boolean;
}

const QualityRulesCompare = ({
    data,
    pagingData,
    sortHandler,
    pageHandler,
    activeSort,
    activeSortDirection,
    noResultsFound,
}: QualityRulesCompareProps) => {
    const { data: regulators } = useRegulators();

    const handleChangeRowsPerPage = (event: ChangeEvent<HTMLInputElement>) =>
        pageHandler(0, +event.target.value);

    const handleChangePage = (
        _: MouseEvent<HTMLButtonElement> | null,
        page: number,
    ) => pageHandler(page, pagingData.limit);

    const handleHeaderClickedChild = (
        event: MouseEvent<HTMLTableCellElement>,
    ) => {
        const newColumn = event.currentTarget.id as SortKey;
        let newSortDirection = activeSortDirection;
        if (newColumn === activeSort) {
            if (activeSortDirection === 'asc') {
                newSortDirection = 'desc';
            } else {
                newSortDirection = 'asc';
            }
        }
        sortHandler(newColumn, newSortDirection, 0);
    };

    return !data?.length || regulators === undefined ? (
        <div className={styles.loading}>
            {!noResultsFound ? (
                <CircularProgress aria-label="Circular loading animation" />
            ) : (
                'No results found for that criteria.'
            )}
        </div>
    ) : (
        <Paper className={styles.qualityRulesCompare}>
            <Table
                data-test-id={formatTestId('table', 'compare quality rules')}
            >
                <TableHead>
                    <TableRow>
                        <TableCell colSpan={2}>&nbsp;</TableCell>
                        <TableCell
                            colSpan={3}
                            className={`${styles.leftBorder} ${styles.heading}`}
                        >
                            Current
                        </TableCell>
                        <TableCell
                            colSpan={2}
                            className={`${styles.leftBorder} ${styles.heading}`}
                        >
                            Previous
                        </TableCell>
                        <TableCell
                            colSpan={1}
                            className={`${styles.leftBorder} ${styles.heading}`}
                        >
                            &nbsp;
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableHead>
                    <TableRow className={styles.shadedRow}>
                        <TableCell
                            id="ID"
                            onClick={handleHeaderClickedChild}
                            className={`${styles.interactive} ${styles.heading}`}
                        >
                            Rule
                            <TableSortLabel
                                active={activeSort === 'ID'}
                                direction={activeSortDirection}
                            />
                        </TableCell>
                        <TableCell align="left">Regulator</TableCell>
                        <TableCell
                            id="TOLERANCE"
                            align="left"
                            className={`${styles.interactive} ${styles.heading} ${styles.leftBorder} ${styles.toleranceHeader}`}
                            onClick={handleHeaderClickedChild}
                        >
                            Tolerance
                            <TableSortLabel
                                active={activeSort === 'TOLERANCE'}
                                direction={activeSortDirection}
                            />
                        </TableCell>
                        <TableCell
                            id="POPULATION"
                            align="left"
                            onClick={handleHeaderClickedChild}
                            className={`${styles.interactive} ${styles.heading} ${styles.leftBorder} ${styles.populationHeader}`}
                        >
                            Population
                            <TableSortLabel
                                active={activeSort === 'POPULATION'}
                                direction={activeSortDirection}
                            />
                        </TableCell>
                        <TableCell
                            id="FAILURE"
                            align="left"
                            onClick={handleHeaderClickedChild}
                            className={`${styles.interactive} ${styles.heading} ${styles.failureHeader}`}
                        >
                            Failure
                            <TableSortLabel
                                active={activeSort === 'FAILURE'}
                                direction={activeSortDirection}
                            />
                        </TableCell>
                        <TableCell
                            id="POPULATIONPREV"
                            align="left"
                            className={`${styles.interactive} ${styles.heading} ${styles.populationHeader}`}
                            onClick={handleHeaderClickedChild}
                        >
                            Population
                            <TableSortLabel
                                active={activeSort === 'POPULATIONPREV'}
                                direction={activeSortDirection}
                            />
                        </TableCell>
                        <TableCell
                            id="FAILUREPREV"
                            align="left"
                            onClick={handleHeaderClickedChild}
                            className={`${styles.interactive} ${styles.heading} ${styles.failureHeader}`}
                        >
                            Failure
                            <TableSortLabel
                                active={activeSort === 'FAILUREPREV'}
                                direction={activeSortDirection}
                            />
                        </TableCell>
                        <TableCell
                            id="FAILURECHANGE"
                            align="left"
                            className={`${styles.interactive} ${styles.heading} ${styles.failureChange}`}
                            onClick={handleHeaderClickedChild}
                        >
                            Failure Change
                            <TableSortLabel
                                active={activeSort === 'FAILURECHANGE'}
                                direction={activeSortDirection}
                            />
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map((row, index) => (
                        <React.Fragment
                            key={`${row.Code}-${row.RegulatorCode}`}
                        >
                            <TableRow
                                className={
                                    index % 2 !== 0
                                        ? styles.shadedRow
                                        : styles.noBorderRow
                                }
                                hover
                            >
                                <TableCell className={styles.linkCell}>
                                    <HDPLink
                                        size="xSmall"
                                        href={row.GuidanceUrl}
                                        target="_blank"
                                        rel="noopener noreferrer"
                                    >
                                        {row.Code}
                                    </HDPLink>
                                    <br />
                                    {row.Name}
                                </TableCell>
                                <QualityRuleCompareRow
                                    rule={row}
                                    regulators={regulators}
                                />
                            </TableRow>
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
            <TablePaginationWithTestId
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={pagingData?.totalResultsCount || 0}
                rowsPerPage={pagingData?.limit || 10}
                page={pagingData?.offset / pagingData?.limit || 0}
                backIconButtonProps={{
                    'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'next page',
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                testIdProps={{
                    backIconButtonProps: { 'data-test-id': 'previousPage' },
                    nextIconButtonProps: { 'data-test-id': 'nextPage' },
                }}
            />
        </Paper>
    );
};

export default QualityRulesCompare;
