import { useMemo } from 'react';
import { CircularProgress, FormControlLabel, Typography } from '@mui/material';
import { useQuery } from '@tanstack/react-query';
import HDPCheckbox from 'components/HDPCheckbox/HDPCheckbox';
import HDPLink from 'components/HDPLink/HDPLink';
import { AL_SUBMIT_FOR_APPROVAL } from 'constants/activityLogTypeIds';
import { DateTime } from 'luxon';
import { getActivityLogEntries } from 'services/api/collections/collections';
import { Collection } from 'types/collection';
import { Submission } from 'types/submission';
import { SubmissionApprovalState } from 'types/submission';
import { formatTestId } from 'utils/formatTestId/formatTestId';

import { collectionIsLocked } from '../../utils';

import styles from './submitStates.module.scss';

interface SubmitStatesProps {
    isLoading: boolean;
    hasAlreadySubmitted: boolean;
    isSubmitAllowed: boolean;
    isSubmissionProcessing: boolean;
    submission: Submission;
    commitNotAllowedReasons: string[] | undefined;
    canSubmit: boolean;
    termsChecked: boolean;
    setTermsChecked: (value: boolean) => void;
    hasPermissionToSubmit: boolean;
    approvalStates: SubmissionApprovalState[] | undefined;
    hasOpenIssues: boolean;
    collection?: Collection;
}

const SubmitStates = ({
    isLoading,
    hasAlreadySubmitted,
    isSubmitAllowed,
    isSubmissionProcessing,
    submission,
    commitNotAllowedReasons,
    canSubmit,
    termsChecked,
    setTermsChecked,
    hasPermissionToSubmit,
    approvalStates,
    hasOpenIssues,
    collection,
}: SubmitStatesProps) => {
    const { data: activityLog, isLoading: activityLogLoading } = useQuery({
        queryKey: ['activity-log-approval', submission.uuid],
        queryFn: () =>
            getActivityLogEntries({
                submissionUuid: submission.uuid,
                activityLogTypeId: AL_SUBMIT_FOR_APPROVAL,
            }),
        enabled: Boolean(submission?.uuid),
        refetchInterval: false,
    });

    const isCollectionClosed = Boolean(
        collection && collectionIsLocked(collection),
    );

    const approvalTimestamp = useMemo(() => {
        const timestamp =
            submission?.stateHistory?.findLast(
                entry => entry.StatusId === approvalStates?.at(0)?.id,
            )?.Actioned || submission.uploaded;
        return DateTime.fromISO(timestamp).toLocaleString(
            DateTime.DATETIME_MED,
        );
    }, [approvalStates, submission?.stateHistory, submission.uploaded]);

    if (!hasPermissionToSubmit)
        return (
            <Typography>
                You do not have a valid role to submit for approval
            </Typography>
        );

    if (!submission?.fileName)
        return (
            <Typography>
                You have yet to upload a file. Please upload one on the upload
                step.
            </Typography>
        );

    if (!submission.isLatest)
        return (
            <Typography>
                You can only submit the most recently uploaded submission.
            </Typography>
        );

    if (isSubmissionProcessing)
        return (
            <div>
                <Typography>
                    Your submission is currently being processed.
                </Typography>
                <Typography>
                    When your submission reaches the &quot;Quality processing
                    complete&quot; stage you will be able to submit for
                    approval.
                </Typography>
            </div>
        );

    if (hasAlreadySubmitted)
        return (
            <div>
                <div>
                    <span>
                        Your submission <strong>{submission.fileName}</strong>,
                        was submitted for approval on{' '}
                        <strong>{approvalTimestamp}</strong> by{' '}
                        <strong>{activityLog?.records?.at(0)?.username}</strong>
                        . When it has been approved by all required parties, you
                        will be able to start the process to sign off your
                        submission.
                    </span>
                </div>
            </div>
        );

    if (isLoading || activityLogLoading)
        return (
            <div>
                <div className={styles.loading}>
                    <CircularProgress aria-label="Circular loading animation" />
                    <p>Checking the status of the submission</p>
                </div>
            </div>
        );

    if (!isSubmitAllowed && isCollectionClosed) {
        return (
            <div>
                <Typography>
                    You can only submit for approval when a collection is open.
                </Typography>
            </div>
        );
    }

    if (!isSubmitAllowed && commitNotAllowedReasons)
        return (
            <div>
                {hasOpenIssues && (
                    <Typography className={styles.hasOpenIssuesText}>
                        Please note that you have outstanding issues in IMS or
                        rule failures in the HDP. <br /> Your submission may not
                        be approved unless these relate to Known Issues or you
                        have previously discussed your outstanding issues with
                        the Liaison team.
                    </Typography>
                )}
                <Typography>
                    You cannot yet submit for approval as one or more of the
                    following apply:
                </Typography>
                <ul className={styles.list}>
                    {commitNotAllowedReasons.map((reason, index) => (
                        <li key={index}>
                            {reason
                                .split(/(&e-mail:\S+)/g)
                                .map((parsedItem, parsedIndex) =>
                                    parsedItem.startsWith('&e-mail:') ? (
                                        <HDPLink
                                            href={`mailto:${parsedItem.replace(
                                                '&e-mail:',
                                                '',
                                            )}`}
                                            className={styles.emailLink}
                                            key={`commitReason${index}${parsedIndex}`}
                                        >
                                            {parsedItem.replace('&e-mail:', '')}
                                        </HDPLink>
                                    ) : (
                                        <span key={parsedItem}>
                                            {parsedItem}
                                        </span>
                                    ),
                                )}
                        </li>
                    ))}
                </ul>
            </div>
        );

    if (canSubmit)
        return (
            <div>
                {hasOpenIssues && (
                    <Typography className={styles.hasOpenIssuesText}>
                        Please note that you have outstanding issues in IMS or
                        rule failures in the HDP. <br /> Your submission may not
                        be approved unless these relate to Known Issues or you
                        have previously discussed your outstanding issues with
                        the Liaison team.
                    </Typography>
                )}
                <FormControlLabel
                    control={
                        <HDPCheckbox
                            checked={termsChecked}
                            data-test-id={
                                !termsChecked
                                    ? formatTestId('agreeToTerms')
                                    : null
                            }
                            onChange={() => setTermsChecked(!termsChecked)}
                        />
                    }
                    label={`I understand that by submitting file with ID ${submission?.uuid?.slice(
                        -8,
                    )}  for approval, the approval process will commence and I will no longer be able to submit data to this collection unless otherwise instructed.`}
                />
            </div>
        );
};

export default SubmitStates;
