import { collectionStates } from 'constants/collections';

import styles from './collectionStatus.module.scss';

interface CollectionStatusProps {
    id: number;
}

const CollectionStatus = ({ id }: CollectionStatusProps) => {
    switch (id) {
        case collectionStates.DRAFT:
            return <span className={styles.collectionState}>Draft</span>;

        case collectionStates.DELETED:
            return <span className={styles.collectionState}>Deleted</span>;

        case collectionStates.OPEN:
            return <span className={styles.collectionState}>Open</span>;

        case collectionStates.CLOSED:
            return <span className={styles.collectionState}>Closed</span>;

        case collectionStates.HISTORIC_AMENDMENT:
            return (
                <span className={styles.collectionState}>
                    Historic Amendment
                </span>
            );

        case collectionStates.ARCHIVED:
            return <span className={styles.collectionState}>Archived</span>;

        case collectionStates.VALIDATION:
            return <span className={styles.collectionState}>Validation</span>;

        default:
            return <span className={styles.collectionState}>Unassigned</span>;
    }
};

export default CollectionStatus;
