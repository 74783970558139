import React, { MouseEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import {
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TablePagination,
    TablePaginationProps,
    TableRow,
    TableSortLabel,
} from '@mui/material';
import WithTestId from 'components/WithTestId/WithTestId';
import useRegulators from 'queries/regulator/useRegulators';
import { ValidationsSortKey } from 'services/api/submissions/types';
import { renderStatus } from 'src/pages/Collections/Submission/utils';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';
import { Collection } from 'types/collection';
import { SubmissionValidation } from 'types/submission';

import QualityRuleRow from './QualityRuleRow';

import styles from './qualityRules.module.scss';

const TablePaginationWithTestId =
    WithTestId<TablePaginationProps>(TablePagination);

interface QualityRulesProps {
    data: (SubmissionValidation & {
        specialistRegulators?: SubmissionValidation[];
    })[];
    pagingData: { totalResultsCount: number; limit: number; offset: number };
    collection?: Collection;
    instId: string;
    submissionId: string;
    isLatestSubmission: boolean;
    sortHandler: (
        column: 'POPULATION' | 'FAILURE' | 'TOLERANCE' | 'STATUS' | 'ID',
        direction: 'asc' | 'desc',
        page: number,
    ) => void;
    pageHandler: (page: number, limit: number) => void;
    activeSort: string;
    activeSortDirection: 'asc' | 'desc';
    noResultsFound: boolean;
    disallowCreateIssues: boolean;
    selectedStatus: 0 | 1;
    noMatchingResults: boolean;
    searchedEmptyResults: boolean;
    isUnableToFetchValidations: boolean;
}

const QualityRules = ({
    data,
    pagingData,
    collection,
    instId,
    submissionId,
    isLatestSubmission,
    sortHandler,
    pageHandler,
    activeSort,
    activeSortDirection,
    noResultsFound,
    disallowCreateIssues,
    selectedStatus,
    noMatchingResults,
    searchedEmptyResults,
    isUnableToFetchValidations,
}: QualityRulesProps) => {
    const { data: regulators } = useRegulators();
    const { collectionId = '', reference = '' } = useParams();
    const [currentStatus, setCurrentStatus] = React.useState('outside');
    useEffect(() => {
        setCurrentStatus(selectedStatus === 1 ? 'outside' : 'inside');
    }, [selectedStatus]);
    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ): void => pageHandler(0, parseInt(event.target.value, 10));

    const handleChangePage = (
        _: React.MouseEvent<HTMLButtonElement> | null,
        page: number,
    ) => pageHandler(page, pagingData.limit);

    const handleHeaderClickedChild = (event: MouseEvent<HTMLSpanElement>) => {
        const newColumn = event.currentTarget.id as ValidationsSortKey;
        let newSortDirection = activeSortDirection;
        if (newColumn === activeSort) {
            if (activeSortDirection === 'asc') {
                newSortDirection = 'desc';
            } else {
                newSortDirection = 'asc';
            }
        }
        sortHandler(newColumn, newSortDirection, 0);
    };

    const generateText = () => {
        if (noResultsFound && noMatchingResults) {
            return `No results found because your quality report contains no rule failures which are ${currentStatus} tolerance.`;
        }
        if (noResultsFound && searchedEmptyResults) {
            return 'No results found for that criteria';
        }
        if (noResultsFound) {
            return `Your quality report contains no rule failures which are ${currentStatus} tolerance.`;
        }
    };

    if (isUnableToFetchValidations) {
        return (
            <div className={styles.loading}>
                Error occurred when retrieving quality rules report
            </div>
        );
    }

    return !data?.length || regulators === undefined ? (
        <div className={styles.loading}>
            {!noResultsFound ? (
                <CircularProgress aria-label="Loading quality rules report" />
            ) : (
                generateText()
            )}
        </div>
    ) : (
        <Paper className={styles.qualityRules}>
            <Table data-test-id={formatTestId('table', 'quality rules')}>
                <thead>
                    <TableRow className={styles.headerRow}>
                        <TableCell
                            className={`${styles.header} ${styles.interactive}`}
                            data-test-id={formatTestId('sortBy', 'rule')}
                            component="th"
                            scope="col"
                        >
                            <TableSortLabel
                                active={activeSort === 'ID'}
                                direction={activeSortDirection}
                                className={styles.headerLabel}
                                onClick={handleHeaderClickedChild}
                                id="ID"
                            >
                                Rule
                            </TableSortLabel>
                        </TableCell>
                        <TableCell
                            align="left"
                            className={`${styles.header} ${styles.interactive}`}
                            data-test-id={formatTestId('sortBy', 'status')}
                            component="th"
                            scope="col"
                        >
                            <TableSortLabel
                                id="STATUS"
                                onClick={handleHeaderClickedChild}
                                active={activeSort === 'STATUS'}
                                direction={activeSortDirection}
                                className={styles.headerLabel}
                            >
                                Status
                            </TableSortLabel>
                        </TableCell>
                        <TableCell
                            align="right"
                            className={styles.header}
                            component="th"
                            scope="col"
                        >
                            Regulator
                        </TableCell>
                        <TableCell
                            align="left"
                            className={`${styles.header} ${styles.interactive}`}
                            data-test-id={formatTestId('sortBy', 'tolerance')}
                            component="th"
                            scope="col"
                        >
                            <TableSortLabel
                                id="TOLERANCE"
                                active={activeSort === 'TOLERANCE'}
                                direction={activeSortDirection}
                                className={styles.headerLabel}
                                onClick={handleHeaderClickedChild}
                            >
                                Tolerance
                            </TableSortLabel>
                        </TableCell>
                        <TableCell
                            align="left"
                            className={`${styles.header} ${styles.interactive}`}
                            data-test-id={formatTestId('sortBy', 'population')}
                            component="th"
                            scope="col"
                        >
                            <TableSortLabel
                                id="POPULATION"
                                active={activeSort === 'POPULATION'}
                                direction={activeSortDirection}
                                className={styles.headerLabel}
                                onClick={handleHeaderClickedChild}
                            >
                                Population
                            </TableSortLabel>
                        </TableCell>
                        <TableCell
                            align="left"
                            className={`${styles.header} ${styles.interactive}`}
                            data-test-id={formatTestId('sortBy', 'failure')}
                            component="th"
                            scope="col"
                        >
                            <TableSortLabel
                                id="FAILURE"
                                active={activeSort === 'FAILURE'}
                                direction={activeSortDirection}
                                className={styles.headerLabel}
                                onClick={handleHeaderClickedChild}
                            >
                                Failure
                            </TableSortLabel>
                        </TableCell>
                        <TableCell
                            id="ISSUE_STATUS"
                            align="left"
                            className={styles.header}
                            component="th"
                            scope="col"
                        >
                            {`Issue status`}
                        </TableCell>
                        <TableCell
                            id="CREATE_ISSUE_MENU"
                            align="center"
                            component="td"
                        >
                            {``}
                        </TableCell>
                    </TableRow>
                </thead>
                <TableBody>
                    {data.map((row, index) => (
                        <React.Fragment
                            key={`${row.Code}-${row.RegulatorCode}`}
                        >
                            <TableRow
                                hover
                                className={styles.row}
                                data-test-id={formatTestId(
                                    'quality rule',
                                    `row ${index + 1}`,
                                )}
                            >
                                <TableCell>
                                    <span className={styles.title}>
                                        {row.Code}
                                    </span>
                                    <br />
                                    {row.Name}
                                </TableCell>
                                <TableCell align="left">
                                    {renderStatus(row.Status)}
                                </TableCell>
                                <QualityRuleRow
                                    rule={row}
                                    regulators={regulators}
                                    submissionId={submissionId}
                                    isLatestSubmission={isLatestSubmission}
                                    collection={collection}
                                    instId={instId}
                                    disallowCreateIssues={disallowCreateIssues}
                                    index={index + 1}
                                    collectionId={
                                        collection?.id.toString() ||
                                        collectionId
                                    }
                                    reference={
                                        collection?.reference || reference
                                    }
                                />
                            </TableRow>
                            {row.specialistRegulators &&
                                row.specialistRegulators.map(
                                    (reg: SubmissionValidation) => (
                                        <TableRow key={reg.RegulatorCode}>
                                            <TableCell></TableCell>
                                            <TableCell></TableCell>
                                            <QualityRuleRow
                                                rule={reg}
                                                regulators={regulators}
                                                submissionId={submissionId}
                                                isLatestSubmission={
                                                    isLatestSubmission
                                                }
                                                collection={collection}
                                                disallowCreateIssues={
                                                    disallowCreateIssues
                                                }
                                                index={index + 1}
                                                instId={instId}
                                                reference={
                                                    collection?.reference ||
                                                    reference
                                                }
                                                collectionId={
                                                    collection?.id.toString() ||
                                                    collectionId
                                                }
                                            />
                                        </TableRow>
                                    ),
                                )}
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
            <TablePaginationWithTestId
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={pagingData?.totalResultsCount || 0}
                rowsPerPage={pagingData?.limit || 10}
                page={pagingData?.offset / pagingData?.limit || 0}
                backIconButtonProps={{
                    'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'next page',
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                testIdProps={{
                    backIconButtonProps: {
                        'data-test-id': formatTestId(
                            'previous page',
                            'quality rules table',
                        ),
                    },
                    nextIconButtonProps: {
                        'data-test-id': formatTestId(
                            'next page',
                            'quality rules table',
                        ),
                    },
                    SelectProps: {
                        'data-test-id': formatTestId(
                            'page options',
                            'quality rules table',
                        ),
                    },
                }}
            />
        </Paper>
    );
};

export default QualityRules;
