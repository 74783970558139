import { ButtonHTMLAttributes, ReactNode } from 'react';
import React from 'react';

import styles from './hdpIconButton.module.scss';

export interface HDPIconButtonProps
    extends ButtonHTMLAttributes<HTMLButtonElement> {
    customStyles?: string;
    isDisabled?: boolean;
    children: ReactNode;
}

export default function HDPIconButton({
    isDisabled,
    children,
    customStyles,
    ...buttonProps
}: HDPIconButtonProps) {
    const buttonClasses = [customStyles ? customStyles : '', styles.button]
        .filter(Boolean)
        .join(' ');

    return (
        <button
            disabled={isDisabled}
            {...buttonProps}
            className={buttonClasses}
        >
            {children}
        </button>
    );
}
