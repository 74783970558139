import { Check, Close, HourglassEmpty } from '@mui/icons-material';
import classNames from 'classnames';

import styles from './step.module.scss';

type StepIconProps = {
    isActive: boolean;
    isComplete: boolean;
    isError: boolean;
};

const getIcon = (isActive: boolean, isComplete: boolean, isError: boolean) => {
    if (isError === true) {
        return <Close className={styles.icon} fontSize="large" />;
    }
    if (isComplete === true) {
        return <Check className={styles.icon} fontSize="large" />;
    }
    if (isActive === true) {
        return <HourglassEmpty className={styles.icon} fontSize="large" />;
    }

    return null;
};

const StepIcon = ({ isActive, isComplete, isError }: StepIconProps) => {
    const icon = getIcon(isActive, isComplete, isError);

    const classes = classNames(styles.iconContainer, {
        [styles.iconActive]: isActive && !isError,
        [styles.iconComplete]: isComplete && !isError,
        [styles.iconError]: isError,
    });

    return <div className={classes}>{icon}</div>;
};

export default StepIcon;
