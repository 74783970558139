import React, { ChangeEvent, FormEvent, useState } from 'react';
import { TextField } from '@mui/material';
import HDPButton from 'components/Button/HDPButton';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import styles from './filterForm.module.scss';

interface FilterFormProps {
    initialFilterTerm: string;
    onFilter: (filter: { filterTerm: string }) => void;
}

export const FilterForm = ({
    initialFilterTerm,
    onFilter,
}: FilterFormProps) => {
    const [filterTerm, setFilterTerm] = useState(initialFilterTerm);

    const handleSubmit = (e: FormEvent<HTMLFormElement>) => {
        e.preventDefault();
        onFilter({ filterTerm });
    };

    const handleFilterTermChange = ({
        target,
    }: ChangeEvent<HTMLInputElement | HTMLTextAreaElement>) => {
        setFilterTerm(target.value);
    };

    const clearFilter = () => {
        setFilterTerm('');
        onFilter({ filterTerm: '' });
    };

    return (
        <form
            id="filter-form"
            onSubmit={handleSubmit}
            className={styles.filterForm}
        >
            <label className={styles.scrOnly} htmlFor="filter-term">
                Filter by name
            </label>
            <TextField
                id="filter-term"
                size="medium"
                value={filterTerm}
                onChange={handleFilterTermChange}
                data-test-id={formatTestId('filterInput')}
                fullWidth
            />
            <HDPButton
                id="submitBtn"
                variant="primary"
                type="submit"
                data-test-id={formatTestId('search')}
            >
                Search
            </HDPButton>
            &nbsp;
            <HDPButton
                variant="secondary"
                onClick={clearFilter}
                data-test-id={formatTestId('clear', 'filterInput')}
            >
                Clear
            </HDPButton>
        </form>
    );
};
