import { useMemo } from 'react';
import MiniSearch, { SearchResult } from 'minisearch';

export interface FuzzyMatch<Data> extends SearchResult {
    result: Data;
}

export interface UseFuzzySearchOptions<
    Data extends object,
    Key extends keyof Data,
> {
    data: Data[];
    fields: Key[];
    showAllResultsWhenQueryIsEmpty?: boolean;
    query: string;
    fuzzy?: (term: string) => number | boolean;
    tokenize?: (term: string) => string[];
    boost?:
        | {
              [fieldName: string]: number;
          }
        | undefined;
}

export default function useFuzzySearch<
    Data extends object,
    Key extends keyof Data,
>({
    data,
    fields,
    query,
    showAllResultsWhenQueryIsEmpty = true,
    fuzzy,
    tokenize,
    boost,
}: UseFuzzySearchOptions<Data, Key>): FuzzyMatch<Data>[] {
    const sanitizedQuery = query.replace(/[^\w\s]/gi, '');

    // Add an id to each item in the data array - we memoize this so it doesn't update every time the search query changes
    const dataWithIds = useMemo(
        () => data.map((item, index) => ({ id: index, ...item })),
        [data],
    );

    // Expensive operation, so we memoize it
    const searchResults = useMemo(() => {
        const miniSearch = new MiniSearch({
            fields: fields as string[],
            searchOptions: {
                combineWith: 'AND',
                boost,
                prefix: true,
                fuzzy: term => {
                    if (fuzzy !== undefined) {
                        return fuzzy(term);
                    } else {
                        return 0.2;
                    }
                },
                tokenize: term => {
                    if (tokenize !== undefined) {
                        return tokenize(term);
                    } else {
                        return MiniSearch.getDefault('tokenize')(term);
                    }
                },
            },
        });

        miniSearch.addAll(dataWithIds);

        return miniSearch.search(sanitizedQuery).map(result => ({
            ...result,
            result: dataWithIds[result.id],
        }));
    }, [boost, dataWithIds, fields, fuzzy, sanitizedQuery, tokenize]);

    if (query.length === 0 && showAllResultsWhenQueryIsEmpty) {
        return dataWithIds.map(item => ({
            score: 0,
            id: item.id,
            result: item,
            match: {},
            queryTerms: [],
            terms: [],
        })) as FuzzyMatch<Data>[];
    }

    return searchResults as FuzzyMatch<Data>[];
}
