import { useParams } from 'react-router-dom';
import { SimCardDownloadOutlined } from '@mui/icons-material';
import HDPButton from 'components/Button/HDPButton';
import useCredibilityReportDownload from 'queries/credibility/useCredibilityReportDownload';
import { formatTestId } from 'utils/formatTestId/formatTestId';

import styles from './downloadResultsButton.module.scss';

export default function DownloadResultsButton() {
    const { submissionId = '', reportCode = '' } = useParams();

    const { data } = useCredibilityReportDownload({
        submissionId,
        reportCode,
    });

    return (
        <div className={styles.resultsButton}>
            <HDPButton
                data-test-id={formatTestId('download results')}
                variant="link"
                onClick={() => {
                    if (data?.href) {
                        const link = document.createElement('a');
                        link.href = data.href;
                        link.download = data.download;
                        document.body.appendChild(link);
                        link.click();
                        document.body.removeChild(link);
                    }
                }}
                icon={<SimCardDownloadOutlined />}
                isDisabled={!data?.href || !data?.download}
            >
                Download results
            </HDPButton>
        </div>
    );
}
