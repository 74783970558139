import { Search } from '@mui/icons-material';
import HDPIconButton, {
    HDPIconButtonProps,
} from 'components/HDPIconButton/HDPIconButton';

import styles from './searchButton.module.scss';

interface SearchButtonProps extends Omit<HDPIconButtonProps, 'children'> {}

export default function SearchButton(props: SearchButtonProps) {
    return (
        <HDPIconButton {...props} customStyles={styles.searchButton}>
            <Search />
        </HDPIconButton>
    );
}
