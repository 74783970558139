import React from 'react';
import {
    CircularProgress,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableHead,
    TablePagination,
    TablePaginationProps,
    TableRow,
    TableSortLabel,
} from '@mui/material';
import WithTestId from 'components/WithTestId/WithTestId';
import useRegulators from 'queries/regulator/useRegulators';
import { renderStatus } from 'src/pages/Collections/Submission/utils';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';
import { Collection } from 'types/collection';
import { SubmissionValidation } from 'types/submission';

import QualityRuleRow from './QualityRuleRow';

import styles from '../../../Submission/Steps/Reports/QualityRules/qualityRules.module.scss';

const TablePaginationWithTestId =
    WithTestId<TablePaginationProps>(TablePagination);

interface QualityRulesProps {
    data: (SubmissionValidation & {
        specialistRegulators?: SubmissionValidation[];
    })[];
    collection?: Collection;
    pagingData: {
        limit: number;
        offset: number;
        totalResultsCount: number;
    };
    collectionId: string;
    submissionId: string;

    sortHandler: (
        column: 'FAILURE' | 'POPULATION' | 'TOLERANCE' | 'STATUS' | 'ID',
        direction: 'asc' | 'desc',
        offset: number,
    ) => void;
    pageHandler: (page: number, limit: number) => void;
    activeSort: string;
    activeSortDirection: 'asc' | 'desc';
    noResultsFound: boolean;
    reference: string;
    instId: string;
}

const QualityRules = ({
    data,
    collection,
    pagingData,
    collectionId,
    submissionId,
    sortHandler,
    pageHandler,
    activeSort,
    activeSortDirection,
    noResultsFound,
    reference,
    instId,
}: QualityRulesProps) => {
    const { data: regulators } = useRegulators();

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>,
    ) => {
        pageHandler(0, parseInt(event.target.value, 10));
    };

    const handleChangePage = (
        _: React.MouseEvent<HTMLButtonElement> | null,
        page: number,
    ) => {
        pageHandler(page, pagingData.limit);
    };

    const handleHeaderClickedChild = (
        event: React.MouseEvent<HTMLTableCellElement>,
    ) => {
        const newColumn = event.currentTarget.id;
        let newSortDirection = activeSortDirection;
        if (newColumn === activeSort) {
            newSortDirection = activeSortDirection === 'asc' ? 'desc' : 'asc';
        }
        sortHandler(
            newColumn as
                | 'FAILURE'
                | 'POPULATION'
                | 'TOLERANCE'
                | 'STATUS'
                | 'ID',
            newSortDirection,
            0,
        );
    };

    return !data?.length || regulators === undefined ? (
        <div className={styles.loading}>
            {!noResultsFound ? (
                <CircularProgress aria-label="Circular loading animation" />
            ) : (
                'No results found for that criteria.'
            )}
        </div>
    ) : (
        <Paper className={styles.qualityRules}>
            <Table data-test-id={formatTestId('table', 'quality rules')}>
                <TableHead>
                    <TableRow className={styles.headerRow}>
                        <TableCell
                            className={`${styles.header} ${styles.interactive}`}
                        >
                            <TableSortLabel
                                id="ID"
                                active={activeSort === 'ID'}
                                direction={activeSortDirection}
                                className={styles.headerLabel}
                                data-test-id={formatTestId('sortBy', 'rule')}
                                onClick={handleHeaderClickedChild}
                            >
                                Rule
                            </TableSortLabel>
                        </TableCell>
                        <TableCell
                            align="center"
                            className={`${styles.header} ${styles.interactive} ${styles.regulatorCell}`}
                            data-test-id={formatTestId('sortBy', 'status')}
                        >
                            <TableSortLabel
                                id="STATUS"
                                active={activeSort === 'STATUS'}
                                direction={activeSortDirection}
                                className={styles.headerLabel}
                                onClick={handleHeaderClickedChild}
                            >
                                Status
                            </TableSortLabel>
                        </TableCell>
                        <TableCell className={styles.header} align="right">
                            Regulator
                        </TableCell>
                        <TableCell
                            align="left"
                            className={`${styles.header} ${styles.interactive} ${styles.toleranceCell}`}
                            data-test-id={formatTestId('sortBy', 'tolerance')}
                        >
                            <TableSortLabel
                                id="TOLERANCE"
                                active={activeSort === 'TOLERANCE'}
                                direction={activeSortDirection}
                                className={styles.headerLabel}
                                onClick={handleHeaderClickedChild}
                            >
                                Tolerance
                            </TableSortLabel>
                        </TableCell>
                        <TableCell
                            align="left"
                            className={`${styles.header} ${styles.interactive} ${styles.populationCell}`}
                            data-test-id={formatTestId('sortBy', 'population')}
                        >
                            <TableSortLabel
                                id="POPULATION"
                                active={activeSort === 'POPULATION'}
                                direction={activeSortDirection}
                                className={styles.headerLabel}
                                onClick={handleHeaderClickedChild}
                            >
                                Population
                            </TableSortLabel>
                        </TableCell>
                        <TableCell
                            align="center"
                            className={`${styles.header} ${styles.interactive} ${styles.failureCell}`}
                            data-test-id={formatTestId('sortBy', 'failure')}
                        >
                            <TableSortLabel
                                id="FAILURE"
                                active={activeSort === 'FAILURE'}
                                direction={activeSortDirection}
                                onClick={handleHeaderClickedChild}
                                className={styles.headerLabel}
                            >
                                Failure
                            </TableSortLabel>
                        </TableCell>
                    </TableRow>
                </TableHead>
                <TableBody>
                    {data.map(row => (
                        <React.Fragment
                            key={`${row.Code}-${row.RegulatorCode}`}
                        >
                            <TableRow hover className={styles.row}>
                                <TableCell>
                                    <b>{row.Code}</b>
                                    <br />
                                    {row.Name}
                                </TableCell>
                                <TableCell align="right">
                                    {renderStatus(row.Status)}
                                </TableCell>
                                <QualityRuleRow
                                    rule={row}
                                    regulators={regulators}
                                    submissionId={submissionId}
                                    collectionId={collectionId}
                                    reference={reference}
                                    collection={collection}
                                    instId={instId}
                                />
                            </TableRow>

                            {row.specialistRegulators &&
                                row.specialistRegulators.map(reg => (
                                    <TableRow key={reg.RegulatorCode}>
                                        <TableCell></TableCell>
                                        <TableCell></TableCell>
                                        <QualityRuleRow
                                            rule={reg}
                                            regulators={regulators}
                                            submissionId={submissionId}
                                            instId={instId}
                                            collection={collection}
                                            collectionId={collectionId}
                                            reference={reference}
                                        />
                                    </TableRow>
                                ))}
                        </React.Fragment>
                    ))}
                </TableBody>
            </Table>
            <TablePaginationWithTestId
                rowsPerPageOptions={[10, 25, 50, 100]}
                component="div"
                count={pagingData.totalResultsCount}
                rowsPerPage={pagingData.limit}
                page={pagingData.offset / pagingData?.limit}
                backIconButtonProps={{
                    'aria-label': 'previous page',
                }}
                nextIconButtonProps={{
                    'aria-label': 'next page',
                }}
                onPageChange={handleChangePage}
                onRowsPerPageChange={handleChangeRowsPerPage}
                testIdProps={{
                    backIconButtonProps: { 'data-test-id': 'previousPage' },
                    nextIconButtonProps: { 'data-test-id': 'nextPage' },
                }}
            />
        </Paper>
    );
};

export default QualityRules;
