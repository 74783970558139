import { useEffect, useRef } from 'react';
import { useOutletContext } from 'react-router-dom';
import {
    List,
    ListItem,
    ListItemIcon,
    ListItemText,
    Paper,
} from '@mui/material';
import Accordion from 'components/Accordion/Accordion';
import { useMessageAnnouncer } from 'components/MessageAnnouncer/MessageAnnouncerContext';
import { DateTime } from 'luxon';
import {
    ProcessingStep,
    SubmissionStatus,
} from 'pages/Collections/Submission/Steps/Processing/types';
import {
    getIconForDisplayState,
    getStateForStep,
} from 'pages/Collections/Submission/Steps/Processing/utils';
import { SubmissionOutletContextValue } from 'pages/Collections/Submission/types';
import { RestrictAccess } from 'src/components';
import AccessDenied from 'src/components/AccessDenied/AccessDenied';
import ContinueBackUploadNew from 'src/components/ContinueBackUploadNew/ContinueBackUploadNew';
import SchemaFailuresReport from 'src/components/SchemaFailuresReport/SchemaFailuresReport';
import { ACCORDION_IDS } from 'src/constants/constants';
import { ifStatusIdIsUpToQualityProcessingComplete } from 'src/constants/FileStatus';
import { processingConfig } from 'src/pages/Collections/OnlineValidation/Steps/Processing/processing.config';
import { useStepper } from 'src/pages/Collections/Submission/Steps/Stepper/StepperContext';
import DownloadButton from 'src/patterns/DownloadButton/DownloadButton';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import {
    getAriaLabelFromStepName,
    getDataTestIdFromStepName,
    requestOvtFileDownload,
    requestOvtSchemaErrorsFileDownload,
} from '../../utils';

import styles from './processing.module.scss';

// How to get to this component in the rendered app:
// Login as an ovt user -> navigate to Online Validation -> Click a collection -> either upload a file or click on an existing upload -> click on Processing step
// OR
// Login as hesa admin -> navigate to Monitoring -> click on a collection -> click on Submission Summary OVT -> click on an active link in the Current Submission State column (will not be available for "Not started" state) -> click on Processing step
const Processing = () => {
    const {
        data,
        instId,
        collectionId,
        submissionId,
        setPageTitle,
        collection,
    } = useOutletContext<SubmissionOutletContextValue>();

    useEffect(() => {
        setPageTitle('Processing');
    }, [setPageTitle]);

    const { addMessageToQueue } = useMessageAnnouncer();
    const { stateHistory } = useStepper();

    const furthestPassedErroredStep: { current: null | ProcessingStep } =
        useRef(null);

    useEffect(() => {
        if (furthestPassedErroredStep.current !== null) {
            let message;

            const inErrorState =
                furthestPassedErroredStep.current.state === 'error';
            if (
                furthestPassedErroredStep.current.name ===
                'Quality processing complete'
            ) {
                message = 'Quality processing complete';
            } else {
                message = inErrorState
                    ? `Processing step ${furthestPassedErroredStep.current.name}, has errors`
                    : `Processing step ${furthestPassedErroredStep.current.name}, has passed`;
            }
            addMessageToQueue(message);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [furthestPassedErroredStep, data]);

    if (!data || !instId || !submissionId || !collectionId) {
        return null;
    }

    const checkIfResubmissionAllowed = () =>
        data?.status.code === 'RESUBMISSION_REQUIRED' ||
        ifStatusIdIsUpToQualityProcessingComplete(data?.status.id);

    const isEnrichedFileReady: boolean = data?.stateHistory?.some(
        state => state.Code === 'ENRICHMENT_PASSED',
    );

    const isSchemaErrorsFileReady = data?.stateHistory?.some(
        state => state.Code === 'SCHEMA_CHECK_FAILED',
    );

    const isContinuePossible = stateHistory?.some(
        state => state.Code === 'QUALITY_PROCESSING_COMPLETE',
    );

    const handleClickSchemaErrorsDownload = () => {
        requestOvtSchemaErrorsFileDownload(
            data.uuid,
            instId,
            data.fileName,
            data.uploaded,
        );
    };

    const handleClickEnrichedDownload = () => {
        requestOvtFileDownload(
            data.uuid,
            instId,
            data.fileName,
            data.uploaded,
            true,
        );
    };

    const renderProcessingSteps = (status: SubmissionStatus) => {
        return processingConfig.map(step => {
            const { displayState, label } = getStateForStep(
                step.displayStates,
                status.id,
            );

            if (displayState === 'passed' || displayState === 'error') {
                furthestPassedErroredStep.current = {
                    ...step,
                    state: displayState,
                };
            }
            return (
                <ListItem key={crypto.randomUUID()} className={styles.state}>
                    <ListItemIcon className={styles.icon}>
                        {getIconForDisplayState(displayState)}
                    </ListItemIcon>
                    <ListItemText primary={label} className={styles.label} />
                    {step.name === 'Enrichment' && isEnrichedFileReady && (
                        <DownloadButton
                            data-test-id={getDataTestIdFromStepName(step.name)}
                            aria-label={getAriaLabelFromStepName(step.name)}
                            onClick={handleClickEnrichedDownload}
                        />
                    )}
                    {step.name === 'Schema check' &&
                        isSchemaErrorsFileReady && (
                            <DownloadButton
                                data-test-id={getDataTestIdFromStepName(
                                    step.name,
                                )}
                                aria-label={getAriaLabelFromStepName(step.name)}
                                onClick={handleClickSchemaErrorsDownload}
                            />
                        )}
                </ListItem>
            );
        });
    };

    return (
        <RestrictAccess
            allowPermissions={['online-validation.processing-ovt']}
            render={() => <AccessDenied mt={8} />}
        >
            {data.status ? (
                <div>
                    <Paper className={styles.wrapper}>
                        <h2 className={styles.heading}>{data.fileName}</h2>
                        <h3 className={styles.subHeading}>
                            Uploaded:{' '}
                            {DateTime.fromISO(data.uploaded).toLocaleString(
                                DateTime.DATE_FULL,
                            )}
                        </h3>
                        <List className={styles.states}>
                            {renderProcessingSteps(data.status)}
                        </List>
                    </Paper>
                    {data?.status.id === 8 || data?.status.id === 9 ? (
                        <Accordion
                            dataTestId={formatTestId(
                                'open accordion',
                                'schema validation errors',
                            )}
                            id={
                                ACCORDION_IDS.SUBMISSION
                                    .SCHEMA_VALIDATION_ERRORS
                            }
                            title="Schema validation errors"
                        >
                            <SchemaFailuresReport
                                instId={instId}
                                submissionId={submissionId}
                                isOvt={true}
                            />
                        </Accordion>
                    ) : (
                        ''
                    )}
                    <ContinueBackUploadNew
                        isContinuePossible={isContinuePossible}
                        collectionId={collectionId}
                        collectionReference={
                            collection?.reference ? collection.reference : ''
                        }
                        instId={instId}
                        isOvt={true}
                        nextStepName="Quality processing"
                        previousStepName="Upload"
                        uploadPermissionsList={[
                            'online-validation.online-validation',
                        ]}
                        isResubmissionAllowed={checkIfResubmissionAllowed()}
                    />
                </div>
            ) : (
                ''
            )}
        </RestrictAccess>
    );
};

export default Processing;
