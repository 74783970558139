import React from 'react';
import { Box, TablePagination, Typography } from '@mui/material';
import { usePageTitle } from 'components';
import { PageTitle } from 'components';
import HDPButton from 'components/Button/HDPButton';
import Loading from 'components/Loading/Loading';
import ErrorMessage from 'pages/Management/Collections/ErrorMessage';
import { TABLE_PARAMS_IDS } from 'src/constants/constants';
import useTableParams from 'src/hooks/useTableParams/useTableParams';
import useCollections from 'src/queries/collections/useCollections';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import CollectionsTable from './CollectionsTable';

const states = {
    LOADING: 'pending',
    LOADED: 'success',
    LOADING_FAILED: 'error',
};

export const Collections = () => {
    usePageTitle('Monitoring');

    const DEFAULT_TABLE_PARAMETERS = {
        offset: 0,
        limit: 10,
    };

    const tableId = TABLE_PARAMS_IDS.COLLECTIONS;

    const { values: tableParams, methods: tableParamsMethods } = useTableParams(
        tableId,
        DEFAULT_TABLE_PARAMETERS,
    );

    const { offset, limit } = tableParams;

    const { setLimit, setOffset } = tableParamsMethods;

    const { data, status } = useCollections({
        limit: limit,
        offset: offset * limit,
    });

    const pagingMetadata = data?.pagingMetadata || { total: 0 };

    const handleChangePage = (
        _event: React.MouseEvent<HTMLButtonElement, MouseEvent> | null,
        newPage: number,
    ) => {
        setOffset(newPage);
    };

    const handleChangeRowsPerPage = (
        event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    ) => {
        setLimit(Number(event.target.value));

        if (Number(event.target.value) > pagingMetadata.total) {
            setOffset(0);
        }
    };

    const handleTryAgain = () => {
        // Refresh the page so that the data is fetched again
        window.location.reload();
    };

    const renderState = () => {
        switch (status) {
            case states.LOADING:
                return <Loading />;

            case states.LOADED:
                return (
                    <>
                        {data?.collections && (
                            <CollectionsTable collections={data.collections} />
                        )}{' '}
                        <TablePagination
                            rowsPerPageOptions={[10, 25, 50, 100]}
                            component="div"
                            count={pagingMetadata.total}
                            rowsPerPage={limit}
                            page={offset}
                            backIconButtonProps={{
                                'aria-label': 'previous page',
                            }}
                            nextIconButtonProps={{
                                'aria-label': 'next page',
                            }}
                            onPageChange={handleChangePage}
                            onRowsPerPageChange={handleChangeRowsPerPage}
                        />
                    </>
                );

            case states.LOADING_FAILED:
                return (
                    <ErrorMessage title={'Loading Error'}>
                        <Typography paragraph align={'left'}>
                            The list of collections failed to load.
                        </Typography>
                        <HDPButton
                            onClick={handleTryAgain}
                            data-test-id={formatTestId('try again')}
                        >
                            Try again
                        </HDPButton>
                    </ErrorMessage>
                );

            default:
                return null;
        }
    };

    return (
        <>
            <PageTitle title="Monitoring" />
            <Box marginTop={3}>{renderState()}</Box>
        </>
    );
};

export default Collections;
