import { useEffect, useRef } from 'react';
import Input from 'components/Input/Input';
import Loading from 'components/Loading/Loading';
import { PageTitle } from 'components/PageTitle/PageTitle';
import { PageTitleProvider } from 'components/PageTitle/PageTitleProvider';
import { useUserContext } from 'components/User/useUserContext';
import { TABLE_PARAMS_IDS } from 'constants/constants';
import { useDebounce } from 'hooks/useDebounce/useDebounce';
import useTableParams from 'hooks/useTableParams/useTableParams';
import useDpsSubmissions from 'queries/submissions/useDpsSubmissions';
import { connectWebSocket } from 'services/api/utils';

import DPATable from './DPATable/DPATable';

import styles from './dataPseudonymisation.module.scss';

interface DpsStatusEvent {
    submissionUuid: string;
    action: 'dps-status-event';
    instId: string;
    pseudonymisedStatus: string;
}

const DataPseudonymisation = () => {
    const { activeOrganisation } = useUserContext();

    const DEFAULT_TABLE_PARAMETERS = {
        expandedInstIds: [] as string[],
        offset: 1,
        limit: 100,
        searchTerm: '',
    };

    const tableId = TABLE_PARAMS_IDS.DATA_PSEUDONYMISATION;

    const { values: tableParams, methods: tableParamsMethods } = useTableParams(
        tableId,
        DEFAULT_TABLE_PARAMETERS,
    );

    const debouncedSearchTerm = useDebounce(tableParams.searchTerm, 300);

    // Get the data from the API.
    const { data, isLoading, error, refetch } = useDpsSubmissions({
        pageSize: tableParams.limit,
        page: tableParams.offset,
        search: debouncedSearchTerm,
    });
    const webSocket = useRef<WebSocket | null>(null);

    const handleWsMsg = (_ev: MessageEvent<DpsStatusEvent>) => {
        refetch();
    };

    useEffect(() => {
        if (webSocket.current === null) {
            webSocket.current = connectWebSocket(activeOrganisation!.id);
            webSocket.current.onmessage = handleWsMsg;
        }
        return () => {
            if (webSocket.current?.readyState === WebSocket.OPEN) {
                webSocket.current.close();
            }
        };
    }, []);

    return (
        <div className={styles.dataPseudonymisation}>
            <PageTitleProvider>
                <PageTitle title="Data Pseudonymisation" />
                <div className={styles.search}>
                    <Input
                        value={tableParams.searchTerm}
                        setValue={tableParamsMethods.setSearchTerm}
                        label="Search for provider name / ID / Submission"
                    />
                </div>
                {data &&
                    data.metadata.resultsCount > 0 &&
                    isLoading === false && (
                        <DPATable
                            dataFromApi={data}
                            tableParams={tableParams}
                            tableParamsMethods={tableParamsMethods}
                        />
                    )}
                {isLoading === true && <Loading />}
                {error && <div>There was a problem loading the page.</div>}
                {data && data.metadata.resultsCount === 0 && (
                    <div>No results found.</div>
                )}
            </PageTitleProvider>
        </div>
    );
};

export default DataPseudonymisation;
