import { ChevronRight } from '@mui/icons-material';

import styles from './dpaTable.module.scss';

const ProviderButton = ({
    name,
    handleClick,
    isExpandable,
    isExpanded,
}: {
    name: string;
    handleClick: () => void;
    isExpandable: boolean;
    isExpanded: boolean;
}) => {
    if (!isExpandable) {
        return <span className={styles.singleProvider}>{name}</span>;
    }

    return (
        <button
            className={styles.expandableRow}
            onClick={handleClick}
            aria-expanded={isExpanded}
            aria-label={`Expand submissions for provider ${name}`}
        >
            <div
                className={`${styles.expandButtonWrapper}  ${
                    isExpanded ? styles.expandedRow : ''
                }`}
            >
                <div className={styles.iconAndLabel}>
                    <ChevronRight
                        className={`${styles.expandChevron} ${
                            isExpanded ? styles.expandChevronExpanded : ''
                        }`}
                    />
                    <span className={styles.providerName}>{name}</span>
                </div>
            </div>
        </button>
    );
};

export default ProviderButton;
