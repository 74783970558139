import { TableCell, TableRow } from '@mui/material';
import HDPLink from 'components/HDPLink/HDPLink';
import { DateTime } from 'luxon';
import { Collection } from 'services/api/collections/types';
import { formatTestId } from 'utils/formatTestId/formatTestId';

import CollectionStatus from './CollectionStatus';

import styles from './collections.module.scss';

const formatDate = (date: string) =>
    DateTime.fromISO(date).toFormat('dd/LL/yyyy');

interface CollectionsTableRowProps {
    collection: Collection;
}

const CollectionsTableRow = ({ collection }: CollectionsTableRowProps) => {
    const {
        id: collectionId,
        name,
        referencePeriodStart,
        referencePeriodEnd,
        reference,
    } = collection;

    const { id: collectionStatusId } = collection.state;

    const start = formatDate(referencePeriodStart);
    const end = formatDate(referencePeriodEnd);

    return (
        <TableRow hover className={styles.collectionItem}>
            <TableCell className={styles.collectionReference}>
                {reference}
            </TableCell>
            <TableCell className={styles.collectionName}>
                {collectionId ? (
                    <HDPLink
                        className={styles.link}
                        reactRouterProps={{
                            to: { pathname: `/monitoring/${reference}` },
                            state: {
                                id: collectionId,
                                reference,
                                name: collection.name,
                            },
                        }}
                        dataTestId={formatTestId('link to', reference)}
                    >
                        {name}
                    </HDPLink>
                ) : (
                    name
                )}
            </TableCell>
            <TableCell className={styles.collectionReference}>
                {start} - {end}
            </TableCell>
            <TableCell>
                <CollectionStatus id={collectionStatusId} />
            </TableCell>
        </TableRow>
    );
};

export default CollectionsTableRow;
