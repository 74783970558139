import {
    MouseEvent,
    SyntheticEvent,
    useEffect,
    useMemo,
    useState,
} from 'react';
import {
    AutocompleteChangeReason,
    useAutocomplete,
    UseAutocompleteProps,
    UseAutocompleteReturnValue,
} from '@mui/material';
import useFuzzySearch from 'hooks/useFuzzySearch/useFuzzySearch';
import { debounce } from 'lodash';
import MiniSearch from 'minisearch';

import CustomInput from './CustomInput/CustomInput';
import OptionsList from './OptionsList/OptionsList';

import styles from './autocompleteDropdown.module.scss';

export interface AutocompleteOption {
    value: string;
    label: string;
}

interface AutocompleteDropdownProps {
    options: AutocompleteOption[];
    value: string[];
    id: string;
    onChange: (
        event: SyntheticEvent,
        value: string[],
        reason: AutocompleteChangeReason,
    ) => void;
    placeholder?: string;
    disabled?: boolean;
    label: string;
    inputValue?: string;
    onInputChange?: (value: string) => void;
}

export const INST_ID_REGEX = new RegExp('^[0-9\\s]+$');

const instIdTokenizer = (term: string) => {
    const isSearchingForSingleInstId = INST_ID_REGEX.test(term);
    const match = term.match(INST_ID_REGEX);

    if (isSearchingForSingleInstId && match !== null) {
        const instId = match[0].replaceAll(' ', '');
        const restOfTerm = term.slice(match[0].length);

        const newTerm = `${instId} ${restOfTerm}`;

        return MiniSearch.getDefault('tokenize')(newTerm);
    } else {
        return MiniSearch.getDefault('tokenize')(term);
    }
};

const fuzzySearch = (term: string) => {
    const isSearchingForSingleInstId = INST_ID_REGEX.test(term);

    if (isSearchingForSingleInstId) {
        // We don't want to fuzzy search for a single instId - we want to search for the exact instId
        return false;
    } else {
        return 0.2;
    }
};

const MS_BETWEEN_KEYSTROKES_FOR_DEBOUNCE = 200;

export default function AutocompleteDropdown({
    options: optionsProp,
    value = [],
    id,
    onChange,
    placeholder,
    disabled,
    label,
    inputValue: inputValueProp,
    onInputChange,
}: AutocompleteDropdownProps) {
    const [uncontrolledInputValue, setUncontrolledInputValue] = useState('');
    const [debouncedQuery, setDebouncedQuery] = useState('');
    const [isOpen, setIsOpen] = useState(false);

    const isControlled =
        inputValueProp !== undefined && onInputChange !== undefined;

    const inputValue = isControlled ? inputValueProp : uncontrolledInputValue;

    const debouncedSetQuery = useMemo(
        () =>
            debounce(
                (query: string) => setDebouncedQuery(query),
                MS_BETWEEN_KEYSTROKES_FOR_DEBOUNCE,
            ),
        [],
    );

    // Cleanup debounce on unmount
    useEffect(() => {
        return () => {
            debouncedSetQuery.cancel();
        };
    }, [debouncedSetQuery]);

    // Update debounced query whenever input changes
    useEffect(() => {
        debouncedSetQuery(inputValue);
    }, [inputValue, debouncedSetQuery]);

    // We need to allow for the input value to be controlled outside of the component.
    // This is to allow for the 'Clear all filters' button to work, this button is found in `src/pages/Monitoring/SubmissionSummary/Filters/Filters.tsx`
    const setInputValue = (newInputValue: string) => {
        if (isControlled) {
            onInputChange(newInputValue);
        } else {
            setUncontrolledInputValue(newInputValue);
        }
    };

    const handleChange = (
        event: SyntheticEvent,
        newValue: string[],
        reason: AutocompleteChangeReason,
    ) => {
        onChange(event, newValue, reason);
    };

    const filteredOptions = useFuzzySearch({
        data: optionsProp,
        fields: ['label', 'value'],
        query: debouncedQuery, // Use debounced query instead of direct input value
        fuzzy: fuzzySearch,
        tokenize: instIdTokenizer,
        boost: {
            ['value']: 2,
        },
    });

    const {
        getRootProps,
        getInputProps,
        getListboxProps,
        getOptionProps,
        setAnchorEl,
        focused,
        expanded,
        getClearProps,
    } = useAutocomplete({
        id,
        options: optionsProp.map(option => option.value),
        value,
        multiple: true,
        inputValue,
        onInputChange: (_, newInputValue, reason) => {
            if (reason !== 'reset') {
                setInputValue(newInputValue);
            }
        },
        open: isOpen,
        onOpen: () => setIsOpen(true),
        onClose: () => setIsOpen(false),
        onChange: handleChange as UseAutocompleteProps<
            string,
            true,
            false,
            false
        >['onChange'],
        disableCloseOnSelect: filteredOptions.length > 1,
        filterOptions: () => {
            return filteredOptions.map(option => option.result.value);
        },
        disabled,
    });

    const handleClearClick = () => {
        const clearProps = getClearProps();
        if (clearProps.onClick) {
            clearProps.onClick({} as MouseEvent<HTMLButtonElement>);
        }
    };

    const typedGetOptionProps = getOptionProps as UseAutocompleteReturnValue<
        string,
        true,
        false,
        false
    >['getOptionProps'];

    return (
        <div className={styles.container}>
            <div {...getRootProps()}>
                <CustomInput
                    getInputProps={getInputProps}
                    inputValue={inputValue}
                    focused={focused}
                    expanded={expanded}
                    setAnchorEl={setAnchorEl}
                    setIsOpen={setIsOpen}
                    isOpen={isOpen}
                    value={value}
                    placeholder={placeholder ?? ''}
                    handleClearClick={handleClearClick}
                    label={label}
                />
            </div>
            {isOpen && (
                <OptionsList
                    options={filteredOptions}
                    id={id}
                    getOptionProps={renderedOption => ({
                        ...typedGetOptionProps(renderedOption),
                        key: renderedOption.option,
                    })}
                    selectedOptions={value}
                    getListboxProps={getListboxProps}
                    isEmptySearch={inputValue.length === 0}
                />
            )}
        </div>
    );
}
