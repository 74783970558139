import {
    Dispatch,
    SetStateAction,
    useCallback,
    useEffect,
    useState,
} from 'react';
import { useDropzone } from 'react-dropzone';
import { BsFillFileEarmarkCheckFill } from 'react-icons/bs';
import { FaFileUpload } from 'react-icons/fa';
import HDPButton from 'components/Button/HDPButton';
import { useMessageAnnouncer } from 'components/MessageAnnouncer/MessageAnnouncerContext';
import { validateXML } from 'src/utils/xmlValidator';

import styles from './upload.module.scss';

interface UploadFormProps {
    setFile: Dispatch<SetStateAction<File | undefined>>;
    setFileName: Dispatch<SetStateAction<string>>;
    fileName: string;
    isValidating: boolean;
    setIsValidating: Dispatch<SetStateAction<boolean>>;
    hasValidationError: boolean;
    setHasValidationError: Dispatch<SetStateAction<boolean>>;
}

const UploadForm = ({
    setFile,
    setFileName,
    fileName,
    isValidating,
    setIsValidating,
    hasValidationError,
    setHasValidationError,
}: UploadFormProps) => {
    const [validationErrMessage, setValidationErrMessage] = useState('');
    const { addMessageToQueue, clearMessages } = useMessageAnnouncer();

    useEffect(() => {
        return () => {
            // Clear messages after the component unmounts
            clearMessages();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const onDrop = useCallback(
        (acceptedFiles: File[]) => {
            setHasValidationError(false);
            setValidationErrMessage('');
            setIsValidating(false);
            acceptedFiles.forEach((file: File) => {
                const reader = new FileReader();
                setIsValidating(true);
                reader.onload = async e => {
                    if (e.target === null) return;
                    const content = e.target.result;
                    addMessageToQueue('Validating file, please wait.');
                    try {
                        const validationResult = await validateXML(content);
                        if (validationResult !== true) {
                            setValidationErrMessage(
                                `${validationResult.err.code}: ${validationResult.err.msg} Line: ${validationResult.err.line}, Column: ${validationResult.err.col}`,
                            );
                            throw new Error(
                                `${validationResult.err.code}: ${validationResult.err.msg} Line: ${validationResult.err.line}, Column: ${validationResult.err.col}`,
                            );
                        }
                        setIsValidating(false);
                    } catch (error) {
                        setHasValidationError(true);
                        setIsValidating(false);
                        addMessageToQueue('File validation failed.');
                    }
                    setIsValidating(false);
                };

                reader.onerror = () => {
                    setIsValidating(false);
                    addMessageToQueue('File validation failed.');
                };

                reader.readAsText(file);

                setFile(file);
                setFileName(file.name);
                return { fileName: file.name };
            });
        },
        [
            setHasValidationError,
            setIsValidating,
            setFile,
            setFileName,
            addMessageToQueue,
        ],
    );

    const dropzone = useDropzone({
        onDrop,
        accept: '.xml',
        multiple: false,
    });

    return (
        <>
            <div className={styles.uploadForm} {...dropzone?.getRootProps()}>
                <input
                    data-test-id="inputFile"
                    {...dropzone?.getInputProps()}
                />
                <div
                    className={`
                        ${styles.uploadActions} +
                        ${hasValidationError && styles.validationError}`}
                >
                    {fileName ? (
                        <>
                            <BsFillFileEarmarkCheckFill
                                className={styles.icon}
                            />
                            <span>{fileName}</span>
                        </>
                    ) : (
                        <FaFileUpload className={styles.icon} />
                    )}
                    {isValidating ? (
                        <span>Validating file, please wait...</span>
                    ) : (
                        <>
                            <span>
                                Drag & drop your file here. {isValidating}
                            </span>
                            {hasValidationError && (
                                <>
                                    <span className={styles.error}>
                                        File validation failed. <br />
                                        {validationErrMessage}
                                    </span>
                                    <span></span>
                                </>
                            )}
                            <div className={styles.or}>
                                <span className={styles.line}>&nbsp;</span>
                                OR
                                <span className={styles.line}>&nbsp;</span>
                            </div>

                            <HDPButton
                                variant="primary"
                                data-test-id="browseFile"
                            >
                                Browse File
                            </HDPButton>
                        </>
                    )}
                </div>
            </div>
        </>
    );
};

export default UploadForm;
