import React from 'react';
import KeyboardArrowDownRoundedIcon from '@mui/icons-material/KeyboardArrowDownRounded';
import KeyboardArrowUpRoundedIcon from '@mui/icons-material/KeyboardArrowUpRounded';
import { formatTestId } from 'utils/formatTestId/formatTestId';

import styles from './hdpTableHeader.module.scss';

export type Header<T> = {
    label: string;
    id: keyof T;
    isSortable?: boolean;
    alignment?: 'left' | 'centre';
};

export interface HDPTableHeaderProps<T extends object> {
    headers: Header<T>[];
    onClickSort?: (id: keyof T) => void;
    sorting?: {
        key: keyof T;
        direction: 'asc' | 'desc';
    };
    children: React.ReactNode;
    testId?: string;
    colour?: 'light' | 'regular';
}

function HDPTableHeader<T extends object>({
    headers,
    onClickSort,
    sorting,
    children,
    testId,
    colour = 'regular',
}: HDPTableHeaderProps<T>) {
    return (
        <table
            className={styles.table}
            data-test-id={testId ? formatTestId('table', testId) : null}
        >
            <thead className={`${styles.header} ${styles[colour]}`}>
                <tr>
                    {headers.map(({ label, id, isSortable }) => (
                        <th
                            key={id as string}
                            onClick={() => {
                                isSortable && onClickSort !== undefined
                                    ? onClickSort(id)
                                    : null;
                            }}
                            data-test-id={
                                isSortable && testId
                                    ? formatTestId(`sort ${testId}`, label)
                                    : null
                            }
                            className={isSortable ? styles.sortableHeader : ''}
                        >
                            <div
                                className={
                                    isSortable ? styles.headingAndArrows : ''
                                }
                            >
                                <p className={styles.headerText}>{label}</p>
                                {isSortable && (
                                    <div className={styles.sortArrows}>
                                        <KeyboardArrowUpRoundedIcon
                                            className={
                                                sorting !== undefined &&
                                                sorting.key === id &&
                                                sorting.direction === 'desc'
                                                    ? styles.active
                                                    : ''
                                            }
                                        />

                                        <KeyboardArrowDownRoundedIcon
                                            className={
                                                sorting !== undefined &&
                                                sorting.key === id &&
                                                sorting.direction === 'asc'
                                                    ? styles.active
                                                    : ''
                                            }
                                        />
                                    </div>
                                )}
                            </div>
                        </th>
                    ))}
                </tr>
            </thead>
            {children}
        </table>
    );
}

export default HDPTableHeader;
