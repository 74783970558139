import { generatePath } from 'react-router-dom';
import { Chip, TableCell } from '@mui/material';
import { useUserContext } from 'components';
import HDPLink from 'components/HDPLink/HDPLink';
import { OVTPATHS } from 'constants/constants';
import { getRegulatorShortName } from 'queries/regulator/utils';
import { Collection } from 'types/collection';
import { Regulator } from 'types/regulator';
import { SubmissionValidation } from 'types/submission';

import { calculatePercentage } from '../../utils';

import styles from './qualityRuleRow.module.scss';
interface QualityRuleRowProps {
    rule: SubmissionValidation;
    regulators: Regulator[];
    submissionId: string;
    instId: string;
    collection?: Collection;
    collectionId: string;
    reference: string;
}

const QualityRuleRow = ({
    rule,
    regulators,
    submissionId,
    instId,
    collection,
    collectionId,
    reference,
}: QualityRuleRowProps) => {
    const { isAdmin, isSpecialCategory } = useUserContext();

    return (
        <>
            <TableCell align="right">
                {getRegulatorShortName(rule.RegulatorCode, regulators)}
            </TableCell>
            <TableCell align="center">
                {rule.ConjoinedTolerances}
                {rule.IsProviderToleranceOverride && (
                    <Chip
                        size="small"
                        label="Override"
                        className={styles.overrideChip}
                    />
                )}
            </TableCell>
            <TableCell align="center">{rule.PopulationCount}</TableCell>
            <TableCell align="center" className={styles.error}>
                <HDPLink
                    size="xSmall"
                    customStyles={styles.error}
                    reactRouterProps={{
                        to: generatePath(
                            isAdmin || isSpecialCategory
                                ? OVTPATHS.ADMIN_QUALITY_RULE
                                : OVTPATHS.QUALITY_RULE,
                            {
                                collectionId:
                                    collectionId || String(collection?.id),
                                reference: collection?.reference || reference,
                                submissionId,
                                rule: rule.Code,
                                regulatorCode: rule.RegulatorCode,
                                instId,
                            },
                        ),
                    }}
                >
                    {rule.FailureCount} (
                    {calculatePercentage(
                        rule.FailureCount,
                        rule.PopulationCount,
                    )}
                    %)
                </HDPLink>
            </TableCell>
        </>
    );
};

export default QualityRuleRow;
