import { AnchorHTMLAttributes, ReactNode } from 'react';
import { Link, LinkProps, NavLink } from 'react-router-dom';

import styles from './hdpLink.module.scss';

interface HDPLinkProps extends AnchorHTMLAttributes<HTMLAnchorElement> {
    children: ReactNode;
    'data-test-id'?: string;
    isDisabled?: boolean;
    icon?: ReactNode;
    iconPlacement?: 'left' | 'right' | 'both';
    size?: 'regular' | 'small' | 'xSmall';
    dataTestId?: string;
    reactRouterProps?: LinkProps;
    customStyles?: string;
    variant?: 'default' | 'nav';
}

export default function HDPLink({
    children,
    isDisabled,
    icon,
    iconPlacement = 'left',
    size = 'small',
    dataTestId,
    reactRouterProps,
    customStyles,
    variant = 'default',
    ...props
}: HDPLinkProps) {
    const linkClasses = [
        customStyles ? customStyles : '',
        styles.link,
        styles[size],
        isDisabled ? styles.disabled : '',
    ]
        .filter(Boolean)
        .join(' ');

    // For routes within HDP, use the react-router-dom Link component
    if (reactRouterProps) {
        if (variant === 'nav') {
            return (
                <NavLink
                    className={linkClasses}
                    data-test-id={dataTestId}
                    {...reactRouterProps}
                >
                    {children}
                </NavLink>
            );
        } else {
            return (
                <Link
                    className={linkClasses}
                    data-test-id={dataTestId}
                    {...reactRouterProps}
                >
                    {icon && ['left', 'both'].includes(iconPlacement) && (
                        <div className={styles.icon}>{icon}</div>
                    )}
                    {children}
                    {icon && ['right', 'both'].includes(iconPlacement) && (
                        <div className={styles.icon}>{icon}</div>
                    )}
                </Link>
            );
        }
    }

    return (
        <a className={linkClasses} {...props} data-test-id={dataTestId}>
            {icon && ['left', 'both'].includes(iconPlacement) && (
                <div className={styles.icon}>{icon}</div>
            )}
            {children}
            {icon && ['right', 'both'].includes(iconPlacement) && (
                <div className={styles.icon}>{icon}</div>
            )}
        </a>
    );
}
