import { useCallback, useEffect, useRef, useState } from 'react';
import {
    generatePath,
    Outlet,
    UIMatch,
    useMatches,
    useNavigate,
    useParams,
} from 'react-router-dom';
import { CircularProgress } from '@mui/material';
import { useUserContext } from 'components';
import BannerBox from 'components/BannerBox/BannerBox';
import HDPLink from 'components/HDPLink/HDPLink';
import { useMessageAnnouncer } from 'components/MessageAnnouncer/MessageAnnouncerContext';
import useProviderCollections from 'queries/provider/useProviderCollections';
import { PageTitle, PageTitleProvider } from 'src/components/index';
import { PATHS } from 'src/constants/constants';
import useSubmission from 'src/queries/submissions/useSubmission';
import useSubmissions from 'src/queries/submissions/useSubmissions';
import { connectWebSocket } from 'src/services/api/utils';
import { Collection } from 'src/types/collection';
import { RawSubmission } from 'src/types/submission';
import { User } from 'src/types/user';
import { HdpMessage } from 'src/types/websocket';

import Stepper from './Steps/Stepper';
import { StepName } from './Steps/Stepper/types';
import { collectionAllowsSubmissions, inferStepLabelFromRoute } from './utils';

const SUBMISSION_STEPS: StepName[] = [
    'Upload',
    'Processing',
    'Quality Assurance',
    'Submit',
    'Approval',
    'Sign Off',
];

const OVT_SUBMISSION_STEPS: StepName[] = [
    'Upload',
    'Processing',
    'Quality Assurance',
];

interface SubmissionProps {
    isOvt?: boolean;
}

const Submission = ({ isOvt = false }: SubmissionProps) => {
    const params = useParams<
        'submissionId' | 'collectionId' | 'step' | 'provider' | 'reference'
    >();
    const matches = useMatches() as UIMatch<unknown, { crumb: StepName }>[];

    const steps = isOvt ? OVT_SUBMISSION_STEPS : SUBMISSION_STEPS;

    const { submissionId = '', collectionId = '', reference = 'null' } = params;

    const {
        data: submission,
        isLoading,
        refetch: refetchSubmission,
    } = useSubmission({
        submissionId,
        enabled: Boolean(submissionId),
        isOvt,
    });

    const socketRef = useRef<WebSocket | null>(null);
    const {
        activeOrganisation,
        isHesa,
        isStatutoryCustomer,
        isAdmin,
        isAnalyst,
    } = useUserContext() as User;
    const { clearMessages } = useMessageAnnouncer();

    // State
    const navigate = useNavigate();
    const [pageTitle, setPageTitle] = useState(
        inferStepLabelFromRoute(matches, steps) as string,
    );
    const [latestMessage, setLatestMessage] = useState<HdpMessage | null>(null);
    const [tsvDeliveryLocation, setTsvDeliveryLocation] = useState(
        submission?.tsvDeliveryLocation || '',
    );

    const [processingComplete, setProcessingComplete] = useState(false);

    let instId: string = '';

    if (isHesa) {
        instId = submission?.provider.instId || '';
    } else {
        instId = activeOrganisation.id;
    }

    const isStatutoryCustomerAnalyst = isStatutoryCustomer && isAnalyst;

    const { refetch: getSubmissions } = useSubmissions({
        collectionId,
        instId,
        enabled:
            Boolean(instId) &&
            Boolean(collectionId) &&
            !isStatutoryCustomerAnalyst &&
            !isOvt,
    });

    useEffect(() => {
        return () => {
            clearMessages(); // Clear messages when the submission page is unmounted
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const handleRedirect = useCallback(
        (reprocessedSubmission: RawSubmission) => {
            if (reprocessedSubmission.Uuid) {
                const basePath =
                    isHesa || isAdmin || isStatutoryCustomerAnalyst
                        ? PATHS.ADMIN_SUBMISSION
                        : PATHS.SUBMISSION;

                const pathToReprocessedSubmission = generatePath(basePath, {
                    collectionId,
                    instId,
                    reference,
                    submissionId: reprocessedSubmission.Uuid,
                });

                navigate(pathToReprocessedSubmission);
                location.reload();
            }
        },
        [
            collectionId,
            instId,
            isAdmin,
            isHesa,
            isStatutoryCustomerAnalyst,
            navigate,
            reference,
        ],
    );

    const checkForReprocessedSubmissionAndRedirect = useCallback(
        async (inboundMessage: HdpMessage) => {
            const { data: providerSubmissions } = await getSubmissions();

            const currentSubmission = providerSubmissions?.findLast(
                ({ Uuid }) => Uuid === submission?.uuid,
            );

            // The reprocessed submission should have a 'CopiedFrom' value that matches the current submission's SubmissionId
            const reprocessedSubmission = providerSubmissions?.findLast(
                ({ Uuid, CopiedFrom }) =>
                    Uuid === inboundMessage.submissionId &&
                    CopiedFrom === currentSubmission?.SubmissionId,
            );

            if (reprocessedSubmission && !isStatutoryCustomerAnalyst) {
                handleRedirect(reprocessedSubmission);
            }
        },
        [
            getSubmissions,
            handleRedirect,
            isStatutoryCustomerAnalyst,
            submission?.uuid,
        ],
    );

    const handleMessage = useCallback(
        async (msg: MessageEvent) => {
            const inboundMessage: HdpMessage = JSON.parse(msg.data);

            const shouldProcessMessage =
                inboundMessage.instId === instId &&
                !!inboundMessage.submissionId;

            // Return early if the message is not for the current institution or is an irrelevant message (e.g. a message without a submissionId)
            if (!shouldProcessMessage) return;

            const isMessageForThisSubmission =
                inboundMessage.submissionId === submission?.uuid;

            if (isMessageForThisSubmission) {
                if (inboundMessage.action === 'file-event') {
                    refetchSubmission();
                    setLatestMessage(inboundMessage);
                }

                if (inboundMessage.action === 'tsv-ready') {
                    setTsvDeliveryLocation(
                        inboundMessage.tsvDeliveryLocation || '',
                    );
                }
            } else {
                // Handle bulk reprocessed submissions - we want to redirect the user there if they are viewing the old submission
                checkForReprocessedSubmissionAndRedirect(inboundMessage);
            }
        },
        [
            checkForReprocessedSubmissionAndRedirect,
            instId,
            refetchSubmission,
            submission,
        ],
    );

    useEffect(() => {
        const socketExists = !!socketRef.current;

        const createConnection = () => {
            socketRef.current = connectWebSocket(instId);
            socketRef.current.onmessage = handleMessage;
        };

        if (!socketExists && instId && submissionId) {
            createConnection();
        }

        if (
            socketExists &&
            socketRef.current?.readyState === WebSocket.CLOSED
        ) {
            createConnection();
        }

        return () => {
            if (socketRef.current?.readyState === WebSocket.OPEN) {
                socketRef.current?.close();
                socketRef.current = null;
            }
        };
    }, [instId, handleMessage, submissionId]);

    const { data: collections } = useProviderCollections({
        instId,
        state: isOvt
            ? ['open', 'closed']
            : ['open', 'closed', 'historic_amendment'],
    });

    // TODO: Statutory Customer users do NOT have access to the useProviderCollections API so 'collection' will always be undefined for them. We can rely on useParams in other places to get the collectionId / reference where required.
    const collection = collections?.find(
        (c: Collection) => c.id === parseInt(collectionId || '', 10),
    );

    return (
        <section>
            {isOvt && (
                <BannerBox
                    heading="Online validation tool"
                    upperText="This is the online validation tool (OVT). This tool is designed for providers to validate their files. Once your file is ready to be analysed you will need to upload it to the relevant live collection. Please note that only the latest uploaded file will be available to view."
                    lowerText={
                        <span>
                            If you need support please contact{' '}
                            <HDPLink href="mailto:liaison@hesa.ac.uk">
                                HESA Liaison.
                            </HDPLink>
                        </span>
                    }
                />
            )}
            {!isOvt && collection?.disallowCreateIssues && (
                <BannerBox
                    heading="The system is now creating issues automatically"
                    upperText="All outstanding issues have been created in the Issue Management System (IMS), these are no longer required to be raised manually."
                />
            )}
            {isLoading || !submission ? (
                <CircularProgress aria-label="Circular loading animation" />
            ) : (
                <PageTitleProvider>
                    <PageTitle title={pageTitle} />
                    <Stepper
                        submissionData={submission}
                        submissionId={submissionId}
                        collectionId={parseInt(collectionId || '', 10)}
                        steps={steps}
                        key={`stepper-${submissionId}`}
                        latestMessage={latestMessage}
                        ovt={isOvt}
                    >
                        <Outlet
                            context={{
                                collectionId,
                                submissionId: submission?.uuid || '',
                                data: submission,
                                instId,
                                latestMessage,
                                collection,
                                processingComplete,
                                setProcessingComplete,
                                tsvDeliveryLocation,
                                setPageTitle,
                                getSubmission: refetchSubmission,
                                isAnalyst: isStatutoryCustomerAnalyst,
                                canUploadNewSubmission: Boolean(
                                    collection &&
                                        collectionAllowsSubmissions(collection),
                                ),
                                isOvt,
                            }}
                            key={`outlet-${submissionId}`}
                        />
                    </Stepper>
                </PageTitleProvider>
            )}
        </section>
    );
};

Submission.displayName = 'Submission';

export default Submission;
