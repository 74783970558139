import { useCallback, useState } from 'react';
import React from 'react';
import { DialogContentText, Typography } from '@mui/material';
import { RestrictAccess } from 'components';
import ConfirmationPopup from 'components/ConfirmationPopup/ConfirmationPopup';
import HDPMenu from 'components/HDPMenu/HDPMenu';
import useProviderStatuses from 'queries/submissions/useProviderStatuses';
import { Collection } from 'types/collection';
import { Provider } from 'types/provider';
import { LatestSubmission } from 'types/submission';
import { formatTestId } from 'utils/formatTestId/formatTestId';

import ThreeDotIconButton from '../../../../patterns/ThreeDotIconButton/ThreeDotIconButton';
import { ActionStates } from '../utils';

import AwaitingActionMenuItem from './AwaitingActionMenuItem';
import { useSubmissionSummaryActions } from './utils';

const approveAndDeclinePermissions = [
    'monitoring.hesa-approval',
    'monitoring.sc-approval',
];
const signOffPermissions = ['monitoring.sign-off-submission'];
const requestResubmissionPermissions = ['monitoring.request-resubmission'];
const nilReturnPermissions = ['monitoring.nil-return'];

const menuItems = {
    approve: {
        permissions: approveAndDeclinePermissions,
        actionStatesRequired: ['readyForHesaApproval', 'readyForScApproval'],
        label: 'Approve',
    },
    decline: {
        permissions: approveAndDeclinePermissions,
        actionStatesRequired: ['readyForHesaApproval', 'readyForScApproval'],
        label: 'Decline',
    },
    signOff: {
        permissions: signOffPermissions,
        actionStatesRequired: ['readyForSignOff'],
        label: 'Sign-off submission',
    },
    nilReturn: {
        permissions: nilReturnPermissions,
        actionStatesRequired: ['canCreateNilReturn'],
        label: 'Create nil return',
    },
    requestResubmission: {
        permissions: requestResubmissionPermissions,
        actionStatesRequired: ['canRequestResubmission'],
        label: 'Decline',
    },
};

type ActionKey =
    | 'approve'
    | 'decline'
    | 'nilReturn'
    | 'signOff'
    | 'requestResubmission';

interface AwaitingActionMenuProps {
    latestSubmission: LatestSubmission;
    provider: Provider;
    collection: Collection;
    actionStates?: ActionStates;
}

export default function AwaitingActionMenu({
    provider,
    actionStates,
    latestSubmission,
    collection,
}: AwaitingActionMenuProps) {
    const [actionType, setActionType] = useState<ActionKey | null>(null);
    const [isModalOpen, setIsModalOpen] = useState(false);
    const [menuAnchor, setMenuAnchor] = useState<HTMLButtonElement | null>(
        null,
    );

    const { isRefetching } = useProviderStatuses({
        collectionReference: collection.reference,
    });

    const closeModal = useCallback(() => {
        setIsModalOpen(false);
        setActionType(null);
        setMenuAnchor(null);
    }, [setIsModalOpen, setActionType]);

    const submissionSummaryActions = useSubmissionSummaryActions({
        latestSubmission,
        provider,
        collection,
    });

    const handleAction = useCallback(
        (action: ActionKey) => {
            setActionType(action);
            setIsModalOpen(true);
        },
        [setActionType, setIsModalOpen],
    );

    const actionFunctions = {
        approve: submissionSummaryActions.approve,
        decline: submissionSummaryActions.requestResubmission,
        nilReturn: submissionSummaryActions.createNilReturn,
        signOff: submissionSummaryActions.signOff,
        requestResubmission: submissionSummaryActions.requestResubmission,
    };

    const actionMessages = {
        approve: 'Are you sure you want to approve this submission?',
        decline: 'Are you sure you want to decline this submission?',
        nilReturn:
            "Are you sure you want to create a 'nil return' for this provider?",
        signOff: 'Are you sure you want to sign off this submission?',
        requestResubmission:
            'Attention: This will remove approval and sign off statuses from this submission. The associated provider will be required to upload a new file to HDP. Do you wish to continue?',
    };

    const handleConfirm = () => {
        actionFunctions[actionType as ActionKey].mutate(undefined, {
            onSuccess: () => closeModal(),
        });
    };

    const isMutating = !!actionFunctions[actionType as ActionKey]?.isPending;

    return (
        <RestrictAccess
            allowPermissions={[
                ...approveAndDeclinePermissions,
                ...signOffPermissions,
                ...nilReturnPermissions,
                ...requestResubmissionPermissions,
            ]}
        >
            <ConfirmationPopup
                isOpen={isModalOpen}
                title="Confirmation required!"
                onCancel={() => {
                    setIsModalOpen(false);
                    setMenuAnchor(null);
                }}
                onConfirm={() => handleConfirm()}
                isLoading={isMutating || isRefetching}
            >
                <DialogContentText>
                    {actionMessages[actionType as ActionKey]}
                </DialogContentText>
                {provider?.name && (
                    <Typography noWrap>
                        <strong>Provider:</strong> {provider.name}
                    </Typography>
                )}
                {latestSubmission?.fileHash && (
                    <Typography noWrap>
                        <strong>Transaction ID:</strong>{' '}
                        {latestSubmission.fileHash}
                    </Typography>
                )}
                {collection?.name && (
                    <Typography noWrap>
                        <strong>Collection:</strong> {collection.name}
                    </Typography>
                )}
            </ConfirmationPopup>
            <>
                <ThreeDotIconButton
                    aria-label="More actions"
                    aria-controls={menuAnchor ? 'more-actions-menu' : undefined}
                    aria-expanded={menuAnchor ? 'true' : undefined}
                    aria-haspopup="true"
                    onClick={e => setMenuAnchor(e.currentTarget)}
                    data-test-id={formatTestId(
                        'more actions',
                        provider.institutionId,
                    )}
                />
            </>

            <HDPMenu
                id="more-actions-menu"
                anchorEl={menuAnchor}
                open={!!menuAnchor}
                onClose={() => setMenuAnchor(null)}
            >
                {Object.entries(menuItems).map(([id, itemProps]) => {
                    const shouldShowOption =
                        actionStates &&
                        itemProps.actionStatesRequired.some(
                            state =>
                                !!actionStates[state as keyof ActionStates],
                        );

                    if (!shouldShowOption) return null;

                    return (
                        <AwaitingActionMenuItem
                            key={`awaiting-action-item-${id}-${provider.institutionId}`}
                            onClick={() => handleAction(id as ActionKey)}
                            dataTestId={formatTestId(
                                id,
                                provider.institutionId,
                            )}
                            {...itemProps}
                        />
                    );
                })}
            </HDPMenu>
        </RestrictAccess>
    );
}
