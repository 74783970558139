import { useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { ArrowBack } from '@mui/icons-material';
import { Box, Grid, Hidden, Paper, Typography } from '@mui/material';
import { usePageTitle } from 'components';
import HDPButton from 'components/Button/HDPButton';
import Loading from 'components/Loading/Loading';
import { specStates } from 'constants/specifications';
import useDeleteSpecification from 'queries/specifications/useDeleteSpecification';
import useGetSpecificationById from 'queries/specifications/useGetSpecificationById';
import { PATHS } from 'src/constants/constants';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';

import HDPIconButton from '../../../../../components/HDPIconButton/HDPIconButton';
import ApplyConfigurationForm from '../ApplyConfigurationForm';
import SpecTabPanelContextProvider from '../context/SpecTabPanelContextProvider';

import SpecAuditInfo from './SpecAuditInfo';
import SpecInfo from './SpecInfo';
import SpecRules from './SpecRules';

const Spec = () => {
    const location = useLocation();
    // Get the ID of the specification from the URL
    const id = parseInt(
        location.pathname.slice(
            location.pathname.lastIndexOf('/') + 1,
            location.pathname.length,
        ),
    );
    const { data: spec, status } = useGetSpecificationById({ id });
    const { mutate: deleteSpecification } = useDeleteSpecification();
    const [isDeleting, setIsDeleting] = useState(false);

    usePageTitle(`Specification ${spec?.version} | Specifications`);

    const navigate = useNavigate();
    const isDeleteable = spec?.isDeleteable;

    const handleDelete = () => {
        setIsDeleting(true);
        deleteSpecification(
            { id: spec.id },
            {
                onSettled: () => {
                    setIsDeleting(false);
                    navigate('/management/specifications');
                },
            },
        );
    };

    const handleTryAgain = () => {
        window.location.reload();
    };

    return (
        <Box mt={5} key={spec}>
            <Grid container spacing={3}>
                <Grid item xs={1}>
                    <Box mt={3}>
                        <HDPIconButton
                            onClick={() =>
                                navigate(PATHS.MANAGEMENT_SPECIFICATIONS)
                            }
                            data-test-id={formatTestId(
                                'linkTo',
                                'previousPage',
                            )}
                            aria-label={'Back to specifications'}
                        >
                            <ArrowBack />
                        </HDPIconButton>
                    </Box>
                </Grid>

                <Grid item xs={11} sm={11} md={7}>
                    {status === 'pending' && <Loading />}
                    {status === 'error' && (
                        <Paper>
                            <Box
                                p={4}
                                display={'flex'}
                                flexDirection={'column'}
                                alignItems={'center'}
                            >
                                <Typography variant={'body2'} paragraph>
                                    There was a problem loading the
                                    specification page.
                                </Typography>
                                <HDPButton onClick={handleTryAgain}>
                                    Try again
                                </HDPButton>
                            </Box>
                        </Paper>
                    )}
                    {status === 'success' && (
                        <>
                            <SpecInfo spec={spec} />
                            {spec.state.id === specStates.CREATED ? (
                                <ApplyConfigurationForm specId={spec.id} />
                            ) : (
                                <SpecTabPanelContextProvider>
                                    <SpecRules spec={spec} />
                                </SpecTabPanelContextProvider>
                            )}
                        </>
                    )}
                </Grid>
                <Hidden mdUp>
                    <Grid item xs={1}></Grid>
                </Hidden>
                <Grid item xs={11} sm={11} md={4}>
                    {status === 'success' && (
                        <>
                            <SpecAuditInfo spec={spec} />
                            <Box mt={3}>
                                {isDeleteable && (
                                    <HDPButton
                                        variant="ghost"
                                        isDisabled={isDeleting}
                                        onClick={handleDelete}
                                        data-test-id="deleteSpecification"
                                    >
                                        {isDeleting
                                            ? 'Deleting'
                                            : 'Delete specification'}
                                    </HDPButton>
                                )}
                            </Box>
                        </>
                    )}
                </Grid>
            </Grid>
        </Box>
    );
};

export default Spec;
