import { useEffect, useMemo, useRef, useState } from 'react';
import { useOutletContext, useParams } from 'react-router-dom';
import { ArrowForwardIos } from '@mui/icons-material';
import { Paper } from '@mui/material';
import HDPButton from 'components/Button/HDPButton';
import useUploadSubmission from 'queries/files/useUploadSubmission';
import AccessDenied from 'src/components/AccessDenied/AccessDenied';
import { RestrictAccess } from 'src/components/index';
import BackButton from 'src/patterns/BackButton/BackButton';

import { SubmissionOutletContextValue } from '../../types';
import { collectionIsLocked } from '../../utils';
import { useStepper } from '../Stepper/StepperContext';

import UploadedMessage from './UploadedMessage';
import UploadError from './UploadError';
import UploadForm from './UploadForm';
import UploadingMessage from './UploadingMessage';

import styles from './upload.module.scss';

const Upload = ({ isOvt = false }) => {
    const xhrRef = useRef<XMLHttpRequest>(new XMLHttpRequest());
    const [file, setFile] = useState<File>();
    const [fileName, setFileName] = useState('');
    const { navigateToStep, steps } = useStepper();
    const { submissionId } = useParams<{ submissionId?: string }>();
    const [isValidating, setIsValidating] = useState(false);
    const [hasValidationError, setHasValidationError] = useState(false);
    const [uploadProgress, setUploadProgress] = useState(0);
    const [isUploading, setIsUploading] = useState(false);
    const [isFinishedUploading, setIsFinishedUploading] = useState(false);

    const {
        instId,
        data,
        collectionId,
        setPageTitle,
        getSubmission,
        latestMessage,
        collection,
    } = useOutletContext<SubmissionOutletContextValue>();

    const { uploadFile } = useUploadSubmission();

    useEffect(() => {
        setPageTitle('Upload');
    }, [setPageTitle]);

    const isUploaded =
        steps?.some(step => step.label === 'Upload' && step.isComplete) ||
        isFinishedUploading;

    const hasError = steps?.some(
        step => step.label === 'Upload' && step.isError,
    );

    useEffect(() => {
        xhrRef.current.upload.onprogress = event => {
            setUploadProgress(
                Math.round((event.loaded / event.total) * 100) || 0,
            );
        };
    }, []);

    const handleUpload = () => {
        if (isUploaded) {
            navigateToStep('Processing');
            return;
        } else if (!file || !submissionId || !collectionId || !instId) {
            return;
        } else {
            setIsUploading(true);
            uploadFile(
                {
                    submissionUuid: submissionId,
                    file,
                    fileName,
                    collectionId,
                    instId,
                    isOvt,
                    xhr: xhrRef.current,
                },
                {
                    onSettled: () => {
                        getSubmission();
                        setIsFinishedUploading(true);
                        setIsUploading(false);
                    },
                },
            );
        }
    };

    useEffect(() => {
        if (
            latestMessage?.status === 'UPLOADED' &&
            latestMessage?.submissionId === submissionId
        ) {
            // The submission has successfully been uploaded, so we should navigate to the Processing step.
            setIsFinishedUploading(true);
            navigateToStep('Processing');
        }
    }, [latestMessage, navigateToStep, submissionId]);

    const uploadButtonDisabled =
        (!isUploaded && !fileName) ||
        isValidating ||
        hasValidationError ||
        isUploading ||
        (!isOvt && collection && collectionIsLocked(collection) && !isUploaded);

    const UploadContent = useMemo(() => {
        if (hasError) {
            return <UploadError />;
        }

        if (isUploaded) {
            return (
                <UploadedMessage
                    instId={instId || ''}
                    data={data || {}}
                    isOvt={isOvt}
                />
            );
        }

        if (isUploading) {
            return (
                <UploadingMessage
                    submissionUuid={data?.uuid || submissionId || ''}
                    instId={instId || ''}
                    collectionId={collectionId || ''}
                    fileName={data?.fileName || fileName}
                    uploadProgress={uploadProgress}
                    isOvt={isOvt}
                />
            );
        }

        return (
            <UploadForm
                setFile={setFile}
                setFileName={setFileName}
                fileName={fileName}
                isValidating={isValidating}
                setIsValidating={setIsValidating}
                hasValidationError={hasValidationError}
                setHasValidationError={setHasValidationError}
            />
        );
    }, [
        hasError,
        isUploaded,
        isUploading,
        fileName,
        isValidating,
        hasValidationError,
        instId,
        data,
        isOvt,
        submissionId,
        collectionId,
        uploadProgress,
    ]);

    return (
        <RestrictAccess
            allowPermissions={
                !isOvt
                    ? ['collections.ingestion']
                    : ['online-validation.ingestion-ovt']
            }
            render={() => <AccessDenied mt={8} />}
        >
            <Paper className={styles.upload}>{UploadContent}</Paper>
            <div className={styles.buttons}>
                <BackButton onClick={() => navigateToStep('Submissions')} />

                {isUploaded || isUploading ? (
                    <HDPButton
                        isDisabled={uploadButtonDisabled}
                        variant="primary"
                        onClick={handleUpload}
                        data-test-id={'continue'}
                        icon={<ArrowForwardIos />}
                        iconPlacement="right"
                    >
                        Continue
                    </HDPButton>
                ) : (
                    <HDPButton
                        isDisabled={uploadButtonDisabled}
                        variant="primary"
                        onClick={handleUpload}
                        data-test-id={'upload'}
                        icon={<ArrowForwardIos />}
                        iconPlacement="right"
                    >
                        Upload file
                    </HDPButton>
                )}
            </div>
        </RestrictAccess>
    );
};

export default Upload;
