import { generatePath } from 'react-router-dom';
import HDPLink from 'components/HDPLink/HDPLink';
import { AL_NIL_RETURN } from 'src/constants/activityLogTypeIds';
import { PATHS } from 'src/constants/constants';
import { formatTestId } from 'src/utils/formatTestId/formatTestId';
import { ProviderActivityLogEntry } from 'types/provider';

import { LogEntry } from '../../types';

import styles from './activityLogLink.module.scss';

interface ActivityLogLinkProps {
    logEntry: LogEntry | ProviderActivityLogEntry;
}

const ActivityLogLink = ({ logEntry }: ActivityLogLinkProps) => {
    if (logEntry.type.id === AL_NIL_RETURN) return <>{logEntry.filename}</>;

    return (
        <div className={styles.link}>
            <HDPLink
                data-test-id={formatTestId('link to', logEntry?.filename)}
                reactRouterProps={{
                    to: generatePath(PATHS.REPORTS, {
                        collectionId: logEntry.collectionId.toString(),
                        submissionId: logEntry.submissionUuid,
                    }),
                }}
            >
                {logEntry.filename}
            </HDPLink>
        </div>
    );
};

export default ActivityLogLink;
