import { useMutation } from '@tanstack/react-query';
import { submissions } from 'services/api';

export default function useRunDpsTask() {
    const { mutate } = useMutation({
        mutationFn: async (uuid: string) => {
            return await submissions.runDpsTask({ uuid });
        },
    });

    return { runDpsTask: mutate };
}
