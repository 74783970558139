import { StrictMode } from 'react';
import { createRoot } from 'react-dom/client';
import { IntlProvider } from 'react-intl';
import { RouterProvider } from 'react-router-dom';
import { StyledEngineProvider } from '@mui/material/styles';
import { LocalizationProvider } from '@mui/x-date-pickers';
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { QueryClientProvider } from '@tanstack/react-query';
import { queryClient } from 'queries/query-client';

import router from './routes/index';

import './styles/main.scss';
import './index.scss';

const container = document.getElementById('root');
if (!container) {
    throw new Error('Root element not found');
}
const root = createRoot(container);

root.render(
    <StrictMode>
        <StyledEngineProvider injectFirst>
            <LocalizationProvider dateAdapter={AdapterLuxon}>
                <IntlProvider locale={'en'}>
                    <QueryClientProvider client={queryClient}>
                        <RouterProvider router={router} />
                    </QueryClientProvider>
                </IntlProvider>
            </LocalizationProvider>
        </StyledEngineProvider>
    </StrictMode>,
);
