import { useQuery } from '@tanstack/react-query';
import { GetDpsSubmissionsArgs } from 'services/api/submissions/types';

import { getUseDpsSubmissionsOptions } from './options';

interface UseDpsSubmissionsArgs extends GetDpsSubmissionsArgs {
    enabled?: boolean;
}

export default function useDpsSubmissions({ ...args }: UseDpsSubmissionsArgs) {
    const options = getUseDpsSubmissionsOptions(args);

    return useQuery({
        ...options,
    });
}
