import HDPButton from 'components/Button/HDPButton';

import styles from './previousSubmissions.module.scss';

const ErrorMessage = ({ refetch }: { refetch: () => void }) => {
    return (
        <section className={styles.errorContainer}>
            <h2>Previous Submissions</h2>
            <p>Failed to fetch previous submissions</p>
            <HDPButton onClick={refetch}>Retry</HDPButton>
        </section>
    );
};

export default ErrorMessage;
