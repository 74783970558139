import { SimCardDownloadOutlined } from '@mui/icons-material';
import HDPIconButton, {
    HDPIconButtonProps,
} from 'components/HDPIconButton/HDPIconButton';

import styles from './DownloadButton.module.scss';

interface DownloadButtonProps
    extends Omit<HDPIconButtonProps, 'customStyles' | 'children'> {}

export default function DownloadButton(props: DownloadButtonProps) {
    return (
        <HDPIconButton {...props} customStyles={styles.downloadButton}>
            <SimCardDownloadOutlined />
        </HDPIconButton>
    );
}
