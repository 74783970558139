import { CircularProgress, FormControlLabel } from '@mui/material';
import HDPCheckbox from 'components/HDPCheckbox/HDPCheckbox';
import { DateTime } from 'luxon';
import { Submission } from 'types/submission';
import { formatTestId } from 'utils/formatTestId/formatTestId';

import styles from './signOffForm.module.scss';

interface SignOffFormProps {
    isCollectionClosed: boolean;
    isApproved: boolean;
    isSignOffRequested: boolean;
    isSignedOff: boolean;
    isLoading: boolean;
    isActivityLogLoading: boolean;
    submission: Submission;
    user?: string;
    canSignOff: boolean;
    termsChecked: boolean;
    setTermsChecked: (value: boolean) => void;
}

export default function SignOffForm({
    isCollectionClosed,
    isApproved,
    isSignOffRequested,
    isSignedOff,
    isActivityLogLoading,
    isLoading,
    submission,
    user,
    canSignOff,
    termsChecked,
    setTermsChecked,
}: SignOffFormProps) {
    if (isSignedOff) {
        return (
            <p data-test-id={formatTestId('submissionInfo')}>
                Your submission <strong>{submission.fileName}</strong>, uploaded
                on{' '}
                <strong>
                    {DateTime.fromISO(submission.uploaded).toFormat(
                        'dd LLLL y',
                    )}
                </strong>{' '}
                by <strong>{user}</strong> with ID{' '}
                <strong>{submission.uuid.slice(-8)}</strong> has been signed
                off.
            </p>
        );
    }

    if (isCollectionClosed) {
        return (
            <p>
                You can only request sign off for a submission when the
                collection is open.
            </p>
        );
    }

    if (isSignOffRequested) {
        return (
            <>
                <p data-test-id={formatTestId('submissionInfo')}>
                    Your submission <strong>{submission.fileName}</strong>,
                    uploaded on{' '}
                    <strong>
                        {DateTime.fromISO(submission.uploaded).toFormat(
                            'dd LLLL y',
                        )}
                    </strong>{' '}
                    by <strong>{user}</strong> with Submission ID{' '}
                    <strong>{submission.uuid.slice(-8)}</strong> and File ID{' '}
                    <strong>{submission.fileHash}</strong> needs to be signed
                    off by your provider&apos;s Accountable Officer (England and
                    Wales) or Head of Provider (Scotland and Northern Ireland).
                </p>
                <p>
                    You can download the sign-off form by clicking the button
                    below. A copy of this form has been emailed to your
                    provider&apos;s Accountable Officer or Head of Provider.
                </p>
            </>
        );
    }

    if (isApproved) {
        if (isLoading || isActivityLogLoading) {
            return (
                <div className={styles.loading}>
                    <CircularProgress aria-label="Circular loading animation" />
                </div>
            );
        }

        return (
            <>
                <p data-test-id={formatTestId('submissionInfo')}>
                    Your submission <strong>{submission.fileName}</strong>,
                    uploaded on{' '}
                    <strong>
                        {DateTime.fromISO(submission.uploaded).toFormat(
                            'dd LLLL y',
                        )}
                    </strong>{' '}
                    by <strong>{user}</strong> with Submission ID{' '}
                    <strong>{submission.uuid.slice(-8)}</strong> and File ID{' '}
                    <strong>{submission.fileHash}</strong> needs to be signed
                    off by your provider&apos;s Accountable Officer (England and
                    Wales) or Head of Provider (Scotland and Northern Ireland).
                </p>
                <FormControlLabel
                    control={
                        <HDPCheckbox
                            checked={termsChecked}
                            disabled={!canSignOff}
                            data-test-id={
                                canSignOff
                                    ? formatTestId('agree to terms')
                                    : null
                            }
                            onChange={() => {
                                setTermsChecked(!termsChecked);
                            }}
                        />
                    }
                    label="I understand that this will start the sign-off procedure and will send via email, documentation and a notification to my provider's Accountable Officer (England and Wales) or Head of Provider (Scotland and Northern Ireland) that sign-off has been requested."
                />
            </>
        );
    }

    // If none of the above conditions are met, return null - this should never happen
    return null;
}
