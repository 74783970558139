import {
    Box,
    Paper,
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
} from '@mui/material';
import { Collection } from 'services/api/collections/types';
import { formatTestId } from 'utils/formatTestId/formatTestId';

import CollectionsTableRow from './CollectionTableRow';

import styles from './collectionsTable.module.scss';

interface CollectionsTableProps {
    collections: Collection[];
}

const CollectionsTable = ({ collections }: CollectionsTableProps) => {
    return (
        <Box mb={4}>
            <TableContainer component={Paper}>
                <Table data-test-id={formatTestId('table', 'collections')}>
                    <TableHead>
                        <TableRow className={styles.header}>
                            <TableCell className={styles.headerCell}>
                                Reference
                            </TableCell>
                            <TableCell className={styles.headerCell}>
                                Name
                            </TableCell>
                            <TableCell className={styles.headerCell}>
                                Reference Period
                            </TableCell>
                            <TableCell className={styles.headerCell}>
                                State
                            </TableCell>
                        </TableRow>
                    </TableHead>
                    <TableBody>
                        {collections.map((collection, _index) => (
                            <CollectionsTableRow
                                key={collection.reference}
                                collection={collection}
                            />
                        ))}
                    </TableBody>
                </Table>
            </TableContainer>
        </Box>
    );
};

export default CollectionsTable;
