import { Box, Paper, Typography } from '@mui/material';
import HDPButton from 'components/Button/HDPButton';

interface LoadingFailedProps {
    onTryAgain: () => void;
    message: string;
}

const LoadingFailed = ({ onTryAgain, message }: LoadingFailedProps) => (
    <Paper>
        <Box
            p={4}
            display={'flex'}
            flexDirection={'column'}
            alignItems={'center'}
        >
            <Typography variant={'body2'} paragraph>
                {message}
            </Typography>
            <HDPButton onClick={onTryAgain}>Try again</HDPButton>
        </Box>
    </Paper>
);

export default LoadingFailed;
