import { MouseEvent } from 'react';
import { generatePath, useNavigate } from 'react-router-dom';
import { RestrictAccess } from 'components';
import HDPButton from 'components/Button/HDPButton';
import { OVTPATHS, PATHS } from 'constants/constants';
import { Collection } from 'src/types/collection';
import { formatTestId } from 'utils/formatTestId/formatTestId';
import { v4 as uuid } from 'uuid';

import ThreeDotIconButton from '../../../patterns/ThreeDotIconButton/ThreeDotIconButton';

import {
    collectionAllowsSubmissions,
    collectionContainsSubmissions,
    collectionIsLocked,
} from './utils';

import styles from './submissions.module.scss';

interface SubmissionsAccordionActionsProps {
    collection: Collection;
    openMenu: (
        e: MouseEvent<HTMLButtonElement>,
        collection: Collection,
    ) => void;
    isOvt?: boolean;
}

const SubmissionAccordionActions = ({
    collection,
    openMenu,
    isOvt = false,
}: SubmissionsAccordionActionsProps) => {
    const navigate = useNavigate();

    const uploadPermissions = isOvt
        ? ['online-validation.online-validation']
        : ['collections.upload-submission-file'];

    const newSubmissionRoute = generatePath(
        isOvt ? OVTPATHS.UPLOAD : PATHS.UPLOAD,
        {
            collectionId: collection.id.toString(),
            submissionId: uuid().toUpperCase(),
        },
    );

    return (
        <div className={styles.accordionSummary}>
            <div className={styles.actions}>
                <RestrictAccess allowPermissions={uploadPermissions}>
                    <HDPButton
                        data-test-id={formatTestId(
                            'uploadFile',
                            collection?.reference,
                        )}
                        isDisabled={
                            !isOvt &&
                            (!collectionAllowsSubmissions(collection) ||
                                collectionIsLocked(collection))
                        }
                        variant="primary"
                        onClick={() => navigate(newSubmissionRoute)}
                    >
                        Upload File
                    </HDPButton>
                </RestrictAccess>
                <RestrictAccess
                    allowPermissions={[
                        'collections.collection-activity-log-provider-view',
                    ]}
                >
                    <>
                        <ThreeDotIconButton
                            customStyles={styles.menuButton}
                            id={`log-menu-button-${collection.id}`}
                            aria-haspopup="true"
                            aria-label="More actions"
                            data-test-id={formatTestId(
                                'moreActions',
                                collection?.reference,
                            )}
                            onClick={e => openMenu(e, collection)}
                            isDisabled={
                                !collectionContainsSubmissions(collection) &&
                                collectionIsLocked(collection)
                            }
                        />
                    </>
                </RestrictAccess>
            </div>
        </div>
    );
};

export default SubmissionAccordionActions;
